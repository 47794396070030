/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { Typography, Box, Tabs, Tab, Card, useTheme } from '@mui/material';

import { InternalLink } from 'components/InternalLink';
import { SupportedProjects } from 'components/Profile/SupportedProjects';
import { NftItemPayload } from 'hooks/nft/types';
import { SearchResultCred } from 'typings/search-result';

import { routes } from 'pages/routes';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

interface ProfileRightSideProps {
  nfts: NftItemPayload[];
  supportedProjects: SearchResultCred[];
  tab: number;
  loading: boolean;
}

export const ProfileRightSide: FC<ProfileRightSideProps> = ({ nfts, supportedProjects, tab }) => {
  const [tabValue, setTabValue] = useState(tab);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box flex={2} px={{ xs: 0, md: 3 }}>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleChange}
          >
            <Tab label={<span style={{ color: '#41aea8' }}>Supported Projects</span>} />
            <Tab label={<span style={{ color: '#41aea8' }}>My NFTs</span>} sx={{ marginRight: '8px' }} />
          </Tabs>
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          backgroundColor: '#e9f8fb',
          border: '2px solid #3D8291',
          marginTop: '20px',
        }}
      >
        <TabPanel value={tabValue} index={0}>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ marginBottom: '20px' }}
            >
              <Typography variant="s1" color="#148493">
                My Supported Projects
              </Typography>
            </Box>
            <SupportedProjects results={supportedProjects} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {nfts ? 
          <Box>
            {nfts.map((nft, index) => {
              return (
                <Card key={`${index}${nft.tokenId}`} sx={{ marginBottom: '10px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                      }}
                    >
                      <img src={nft.metadata.image} alt={nft.metadata.name} className='h-40 w-30' />
                    </Box>
                    <Box>
                      <Typography variant="s3" color="#148493">
                        {nft.metadata.name}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              );
            })}
            {Boolean(!nfts.length) && (
              <Typography variant="s3" color="#148493">
                You don&apos;t own any NFT yet{' '}
                <InternalLink to={routes.marketplace}>
                  Go to nft Marketplace
                </InternalLink>
              </Typography>
            )}
          </Box>
          :
          <Box 
            display="flex"
            justifyContent="center"
            textAlign="center"
            py={2.5}>
            <BeatLoader color={theme.palette.ocean.main} />
          </Box>}
        </TabPanel>
      </Box>
    </Box>
  );
};
