import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BeatLoader } from 'react-spinners';
import { InternalLink } from 'components/InternalLink';
import { routes } from './routes';
import { useContentContext } from 'providers/ContentProvider';
import imgNftMarketplaceHeaderBG from 'assets/nft-matketplace-header-bg.png'
import imgNftMarketplaceHeaderPlaceholder from 'assets/nft-matketplace-header-placeholder.png'
import imgNftMarketplaceHeaderTextPlaceholder from 'assets/nft-matketplace-header-text-placeholder.png'


export const Marketplace: FC = () => {
  const { nftCollections, pageNo, changePageNo, totalData } = useContentContext();
  const [isSelectedIndex, setIsSelectedIndex] = useState(-1);

  return (
    <div>
      <Helmet>
        <title>CRED | NFT Marketplace</title>
      </Helmet>
      <div className='relative z-0'>
        <img className='w-full h-[350px] sm:h-[500px] object-cover' src={imgNftMarketplaceHeaderBG} alt='NftMarketplaceHeaderBG' />
        <div className='absolute top-0 z-10'>
          <img className='h-[350px] sm:h-[500px]' src={imgNftMarketplaceHeaderPlaceholder} alt="NftMarketplaceHeaderPlaceholder" />
          <div className='absolute z-20 top-6'>
            <img className='h-[350px] sm:h-[500px] py-10' src={imgNftMarketplaceHeaderTextPlaceholder} alt="NftMarketplaceHeaderTextPlaceholder" />
          </div>
        </div>
        <div className='absolute z-30 px-16 space-y-2 font-bold text-white top-20 sm:top-40'>
          <p className='text-4xl shadow-2xl md:text-6xl lg:text-7xl font-overpass'>NFT <br /> MARKETPLACE</p>
          <p className='py-4 text-2xl sm:py-8 md:text-3xl xl:text-4xl font-overpass'>Spend CREDs
            <span className='text-[#23FFF1]'> CREDs</span> and buy <br /> your unique NFTs here </p>
        </div>
      </div>
      {nftCollections.length > 0
        ?
        <div>
          <div className='flex justify-center'>
            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 my-12 sm:min-w-[67rem] 2xl:min-w-[99rem]">
              {nftCollections.map((nftCollection, index) => {
                return (
                  <div key={index} className='flex justify-center py-8'>
                    <InternalLink to={routes.nftCollection(nftCollection.slug)}>
                      <div
                        className='
                        sm:scale-100
                        sm:ease-in-out
                        sm:duration-300
                        sm:hover:scale-105
                        hover:p-0.5
                      '
                        onMouseEnter={() => { setIsSelectedIndex(index) }}
                        onMouseLeave={() => { setIsSelectedIndex(-1) }}
                        style={{
                          background: isSelectedIndex === index ? 'linear-gradient(-45deg,transparent 45px,#FFB800 0)bottom left' : '',

                        }}
                      >
                        <div className='
                        bg-white
                        relative
                        w-[27rem]
                        h-[430px]
                        hover:drop-shadow-[0_0_30px_#FFB800]             	             
                        '
                          style={{ background: 'linear-gradient(-45deg,transparent 45px,#ffffff 0)bottom left' }}
                        >
                          <img className='object-cover w-full h-52' src={nftCollection.metadata.image} alt="NftMarketplaceList" />
                          <div className='py-3 pl-6 pr-3 space-y-1'>
                            <p className='text-2xl font-bold font-overpass text-[#00C6B9]'>{nftCollection.title}</p>
                            <p className="pt-3 overflow-hidden text-black text-ellipsis font-overpass">{nftCollection.description.length > 300 ?
                              nftCollection.description.slice(0, 275).concat('...') : nftCollection.description}</p>
                          </div>
                        </div>
                      </div>
                    </InternalLink>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="pb-4 flex justify-center">
            <button disabled={pageNo !== 1 ? false : true} onClick={() => changePageNo(pageNo - 1)} className="flex items-center justify-center h-10 px-4 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              Previous
            </button>
            <button disabled={totalData > (pageNo * 8) ? false : true} onClick={() =>
              changePageNo(pageNo + 1)} className="flex items-center justify-center h-10 px-4 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg ms-3 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              Next
            </button>
          </div>
        </div>
        : <div className='p-6' style={{ textAlign: 'center' }}>
          <BeatLoader color="rgb(19, 132, 148)" />
        </div>
      }
    </div>
  );
};
