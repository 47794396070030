import {
  FormControl, TextField, Typography, styled, Card,
  StepButton, Stepper, CardMedia, CardContent
} from '@mui/material';

import { ProjectCard } from 'components/ProjectCard';
import { Main } from 'components/layouts/main';


export const CenteredTypography = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledMain = styled(Main)`
  display: grid;
  grid-template-rows: auto auto 1fr;
`;

export const StyledStepper = styled(Stepper)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
`;

export const StyledStepButton = styled(StepButton)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  & svg {
    fill: ${({ theme }) => theme.palette.grey[500]};
  }

  & svg.Mui-active,
  svg.Mui-completed {
    fill: ${({ theme }) => theme.palette.ocean.main};
  }
`;

export const Section = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 32px;

  svg {
    width: 64px;
    height: 64px;
  }

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)} !important;
  }
`;

export const ConfirmationFrame = styled('div')`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.values.sm}px;
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)} !important;
  }
`;

export const ProjectCardSingle = styled(ProjectCard)`
  max-width: ${({ theme }) => theme.breakpoints.values.sm}px;
  height: initial;
`;

export const TealFormControl = styled(FormControl)`
  & * {
    border-color: ${({ theme }) => theme.palette.darkGrey.main} !important;
    color: ${({ theme }) => theme.palette.darkGrey.main} !important;
  }
`;

export const TealField = styled(TextField)`
  & * {
    border-color: ${({ theme }) => theme.palette.darkGrey.main} !important;
    color: ${({ theme }) => theme.palette.darkGrey.main} !important;
  }
`;

export const NftCard = styled(Card)`
  &:hover {
    border: 1px solid blue;
    cursor: pointer;
  }
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const StyledCardMedia = styled(CardMedia)`
  aspect-ratio: 184 / 97;
`;

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledCardDescription = styled(Typography)`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

export const Image = styled('img')`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
`;

export const ContentFrame = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing(2, 0)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: ${({ theme }) => theme.spacing(0, 4)};
  }
`;

export const Sections = styled('div')`
  & > *:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const PurchaseFrame = styled('div')`
  position: sticky;
  top: ${({ theme }) => theme.spacing(4)};
  height: fit-content;
  border: 4px solid #3d8291;
  border-radius: 30px;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const SdgLabelGroup = styled('section')`
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const SearchInputSection = styled('section')`
  display: grid;
  place-items: center;
  width: 100%;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(4)} !important;
  }
`;


export const ResultSection = styled('section')`
  padding: ${({ theme }) => theme.spacing(4, 0)};

  display: grid;
  place-items: center;

  gap: ${({ theme }) => theme.spacing(4, 0)};
`;

export const ProjectListFrame = styled('section')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: ${({ theme }) => theme.spacing(2, 3)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${({ theme }) => theme.spacing(4, 6)};
  }
`;
