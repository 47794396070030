import { CURRUNCY_INFO, CURRUNCY_TYPE } from "../constants";

export function localize(num: number, minDigits = 0, maxDigits = 3) {
  if (typeof num !== 'number') {
    return String(num);
  }

  return num.toLocaleString(undefined, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
}

export function formatCRED(amount: string | number | null, decimals = 2) {
  return amount === null || Number.isNaN(amount)
    ? '---'
    : `${localize(Number(amount), decimals, decimals)} CRED`;
}

export function formatUSD(amount: string | number | null, decimals = 2) {
  return amount === null || Number.isNaN(amount)
    ? '---'
    : `US $${localize(Number(amount), decimals, decimals)}`;
}

export function formatCurrency(amount: string | number | null, currency: CURRUNCY_TYPE, decimals = 2) {
  return amount === null || Number.isNaN(amount)
    ? '---'
    : `$${localize(Number(amount), decimals, decimals)} ${CURRUNCY_INFO[currency].displaySymbol}`;
}
