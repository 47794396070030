import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import app from './App'

import { moduleTypes } from './type'

export const store = createStore(
    persistReducer(
        {
            key: moduleTypes.APP,
            storage,
            whitelist: [moduleTypes.APP]
        }, combineReducers({
            [moduleTypes.APP]: app,
        })
    ),
);

persistStore(store)
