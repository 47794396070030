import { FC, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  Link,
  Box,
  Typography,
} from '@mui/material';
import { localize } from 'utils/i18n';
import utils from 'web3-utils';
import { useResponsive } from 'hooks/useResponsive';
import { useContentContext } from 'providers/ContentProvider';
import { Project } from 'typings/project';
import { SearchResultCred } from 'typings/search-result';
import { SdgGrid } from './SdgGrid';

const { toBN, numberToHex } = utils;

const OpenSeaImage = styled('img')`
  width: 25px;
  padding: 0;
  margin: 0;
`;

const StyledSdgGrid = styled(SdgGrid)`
  grid-template-columns: auto;
  grid-auto-flow: column;
  justify-content: left;

  & img {
    height: 50px;
    width: auto;
    object-fit: contain;
  }
`;

const DataRow = styled(TableRow)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    td {
      border-bottom: none;
    }
  }
`;

const SearchResultRow: FC<SearchResultCred> = ({
  projectID,
  kilos,
  openseaUrl,
}) => {
  const { isFulfilled: isDesktop } = useResponsive();
  const { projects, sdgGoals } = useContentContext();

  const project = useMemo(() => {
    const foundProject = projects.find((project) => {
      const hexProjectId = numberToHex(toBN(projectID));
      return project.cred?.projectId === hexProjectId;
    })
    return foundProject;
  }, [projectID, projects]);

  const selectedSdgGoals = useMemo(() => {
    if (!project) return [];
    const _selectedSdgGoals = sdgGoals.filter(sdgGoal => project.content.sdg.includes(sdgGoal.id))
    return _selectedSdgGoals
  }, [project, sdgGoals]);

  function credReward(kilos: number, project?: Project) {
    const rewardPerTonne = Number(project?.cred?.rewardPerTonne);
    const reward = rewardPerTonne ? (rewardPerTonne * kilos) / 1000 : 0;
    return localize(reward, 0, 3);
  }

  return (
    <DataRow>
      <TableCell>
        <Link href={project?.link} target="_blank" rel="noopener">
          {project ? `${project.id}: ${project.title}` : '(Unknown project)'}
        </Link>
        {!isDesktop && <StyledSdgGrid sdgGoals={selectedSdgGoals} />}
      </TableCell>
      {isDesktop && (
        <TableCell>
          <div style={{ display: 'flex' }}>
            <StyledSdgGrid sdgGoals={selectedSdgGoals.length < 6 ? selectedSdgGoals : selectedSdgGoals.slice(0, 6)} />
            {selectedSdgGoals.length > 6 && <Typography color="ocean.main" variant="s3" mt="12px" ml="10px">
              and {selectedSdgGoals.length - 6} others
            </Typography>}
          </div>
        </TableCell>
      )}
      <TableCell align="right">
        {localize(Number(kilos) / 1000, 1, 3)}
      </TableCell>
      <TableCell align="right">{credReward(Number(kilos), project)}</TableCell>
      {openseaUrl !== '' &&
        <TableCell>
          <Link href={openseaUrl} target="_blank" rel="noopener">
            <OpenSeaImage
              src="https://opensea.io/static/images/logos/opensea.svg"
              title="View on OpenSea"
            />
          </Link>
        </TableCell>
      }
    </DataRow>
  );
};

interface SearchResultTableProps {
  results: SearchResultCred[];
}

export const SearchResultTable: FC<SearchResultTableProps> = ({ results }) => {
  const { isFulfilled: isDesktop } = useResponsive();

  if (results.length === 0) {
    return null;
  }

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            {isDesktop && <TableCell>Supported&nbsp;SDGs</TableCell>}
            <TableCell align="right">Tonnes&nbsp;Offset</TableCell>
            <TableCell align="right">CRED&nbsp;Earned</TableCell>
            {results[0]?.openseaUrl !== '' &&
              <TableCell>External&nbsp;links</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result) => (
            <SearchResultRow key={result.serialNumber} {...result} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
