import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import { ethers } from 'ethers';

export const routes = {
  home: '/',
  search: '/search',
  howItWorks: '/how-it-works',

  docs: '/docs',
  docsPage: (docType = ':docType') => `/docs/${docType}`,

  projects: '/projects',
  projectDetail: (projectId = ':projectId') => `/projects/${projectId}`,

  projectPurchase: '/project-purchase/*',
  transactionSuccessful: '/success',

  marketplace: '/marketplace',
  nftCollection: (slug = ':slug') => `/collection/${slug}`,

  nftDetail: (slug = ':slug', id = ':id', contract?: string) =>
    `/nft/${slug}/${id}${contract ? '?contract=' + contract : ''}`,

  listNft: '/marketplace/listings/new',
  profile: (slug = ':slug') => `/profile/${slug}`,

  openseaAssetUrl: (contractAddress: string, id: string, chainId: number) => {
    contractAddress = ethers.BigNumber.from(contractAddress).toHexString();
    id = BigInt(id).toString(10);

    if (
      chainId !== SupportedChainIdsV4.Polygon &&
      chainId !== SupportedChainIdsV4.PolygonMumbai &&
      chainId !== SupportedChainIdsV4.Celo
    ) {
      throw new Error(`Unsupported chain ID: ${chainId}`);
    }

    const domainAndPath = {
      [SupportedChainIdsV4.Polygon]: 'opensea.io/assets/matic',
      [SupportedChainIdsV4.PolygonMumbai]: 'testnets.opensea.io/assets/mumbai',
      [SupportedChainIdsV4.Celo]: 'opensea.io/assets/celo'
    }[chainId];

    if (chainId === 42220) {
      return '';
    }

    return `https://${domainAndPath}/${contractAddress}/${id}`;
  },
} as const;
