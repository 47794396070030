export enum CustomSupportedChainId {
  CeloAlfajores = 44787,
  CeloBaklava = 62320,
}

export enum PurchaseStep {
  CONNECT = 'connect',
  APPROVE_FUNDS = 'approveFunds',
  PURCHASE = 'purchase',
}
