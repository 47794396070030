import { createContext, FC, useMemo, PropsWithChildren } from 'react';
import { ERC1155OrderStructSerialized, NftSwapV4 } from '@traderxyz/nft-swap-sdk';
import { PostOrderResponsePayload, SearchOrdersParams } from '@traderxyz/nft-swap-sdk/dist/sdk/v4/orderbook';

import { useSafeContext } from 'hooks/useSafeContext';
import { OrderCollection, OrderBySeller, Order } from 'typings/order';

import { useEthereumContext } from './EthereumProvider';
import { NETWORKS } from '../config/coreData';
import { NftCollection } from 'typings/nfts';

interface OrderContextValue {
  nftSwap: NftSwapV4 | undefined;
  parseOrderPayload: (payload: PostOrderResponsePayload[]) => OrderBySeller[];
  getAllOrders: (contract: string, tokenId: string) => Promise<Order[]>;
  getOrderCollection: (nftCollection: NftCollection, tokenId?: string) => Promise<OrderCollection | undefined>;
}
const OrderContext = createContext<OrderContextValue | null | undefined>(null);
OrderContext.displayName = 'OrderContext';

export const useOrderContext = () => useSafeContext(OrderContext);

export const OrderProvider: FC<PropsWithChildren> = ({ children }) => {
  const { provider, chainId, credAddress } = useEthereumContext();

  const nftSwap = useMemo(() => {
    if (!(chainId && provider)) return
    let signer = window.ethereum ? provider.getSigner() : undefined
    if (!signer) {
      signer = provider.getSigner(NETWORKS[42220].REGISTRY_ADDRESS)
    }

    return new NftSwapV4(provider, signer, chainId)
  }, [provider, chainId]);

  const orderValue = useMemo<OrderContextValue>(() => {
    const _getOrderInfo = async (order: PostOrderResponsePayload): Promise<PostOrderResponsePayload | undefined> => {
      const orderInfo = await nftSwap!.exchangeProxy.getERC1155OrderInfo(
        order.order as ERC1155OrderStructSerialized,
      );

      if (Number(orderInfo.remainingAmount) === 0) return
      return order;
    }

    const parseOrderPayload = (orderPayloadList: PostOrderResponsePayload[]): OrderBySeller[] => {
      const orderBySellerList: OrderBySeller[] = [];
      for (const order of orderPayloadList) {
        // set order in array
        let existingSellerIndex = -1;
        const isSellerExist = orderBySellerList.find(
          (existingOrder, index) => {
            existingSellerIndex = index;
            return existingOrder.seller === order.order.maker;
          },
        );

        if (isSellerExist === undefined) {
          orderBySellerList.push({
            seller: order.order.maker,
            orders: [order],
          });
        } else {
          orderBySellerList[existingSellerIndex]?.orders.push(order);
        }
      }

      return orderBySellerList;
    }

    const getAllOrders = async (contract: string, tokenId?: string): Promise<PostOrderResponsePayload[]> => {
      const getOrdersParams: Partial<SearchOrdersParams> = {
        erc20Token: credAddress,
        nftToken: contract,
      }
      if (tokenId) getOrdersParams.nftTokenId = tokenId
      const response = await nftSwap!.getOrders(getOrdersParams);

      const populatedOrders = await Promise.all(response.orders.map(_getOrderInfo))
      const notEmptyOrders = populatedOrders.filter((order) => Boolean(order)) as PostOrderResponsePayload[];
      return notEmptyOrders;
    };

    const getOrderCollection = async (nftCollection: NftCollection, tokenId?: string): Promise<OrderCollection> => {
      const orders = await getAllOrders(nftCollection.contractAddress, tokenId);

      return {
        title: nftCollection.title ?? 'Unknown collection',
        slug: nftCollection.slug,
        description: nftCollection.description,
        images: nftCollection.images ?? [],
        contractType: nftCollection.contractType,
        contractAddress: nftCollection.contractAddress,
        nfts: nftCollection.nfts,
        orders,
      };
    }

    return {
      nftSwap,
      parseOrderPayload,
      getAllOrders,
      getOrderCollection,
    }
  }, [nftSwap, credAddress]);

  return (
    <OrderContext.Provider value={orderValue}>{children}</OrderContext.Provider>
  );
};

