export function useTruncate() {
  function truncateWalletAddress(address?: string): string {
    if (!address) return '';
    const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
    const match = truncateRegex.exec(address);
    if (!match) return address;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${match[1]}…${match[2]}`;
  }

  return { truncateWalletAddress };
}
