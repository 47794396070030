import { useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

export const useResponsive = (
  query: (theme: Theme) => string = (theme) => theme.breakpoints.up('md'),
) => {
  const isFulfilled = useMediaQuery<Theme>(query);

  return useMemo(
    () => ({
      isFulfilled,
      isUnfulfilled: !isFulfilled,
    }),
    [isFulfilled],
  );
};
