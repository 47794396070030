import * as Sentry from '@sentry/react';
import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ContentProvider } from 'providers/ContentProvider';
import { EthereumProvider } from 'providers/EthereumProvider';
import { OrderProvider } from 'providers/OrderProvider';
import { TListingProvider } from 'providers/TListingProvider';
import { ThemeProviders } from 'providers/ThemeProviders';
import { AppContent } from './AppContent';
import { store } from './store';

const queryClient = new QueryClient();

const App: FC = () => {
  return (
    <ThemeProviders>
      <QueryClientProvider client={queryClient}>
        <EthereumProvider>
          <ContentProvider>
            <OrderProvider>
              <TListingProvider>
                <Provider store={store}>
                  <AppContent />
                </Provider>
              </TListingProvider>
            </OrderProvider>
          </ContentProvider>
        </EthereumProvider>
      </QueryClientProvider>
    </ThemeProviders>
  );
};

export default Sentry.withProfiler(App);
