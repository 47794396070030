import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Drawer, List, ListItem, ListItemText, styled } from '@mui/material';
import examplesFilePath from 'assets/docs/examples.md';
import functionsFilePath from 'assets/docs/functions.md';
import readmeFilePath from 'assets/docs/readme.md';
import designFilePath from 'assets/docs/token-and-contract-design.md';
import useCasesFilePath from 'assets/docs/use-cases.md';
import { InternalLink } from 'components/InternalLink';
import { Markdown } from 'components/Markdown';
import { Main } from 'components/layouts/main';
import { routes } from './routes';

const DocGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing(0)};
  .MuiDrawer-root {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 150px 1fr;
    gap: ${({ theme }) => theme.spacing(0, 6)};

    .MuiDrawer-root {
      display: initial;
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    position: initial;
    overflow-y: initial;
  }
`;

const StyledList = styled(List)`
  position: sticky;
  top: ${({ theme }) => theme.spacing(1)};
  padding: 0;

  /* & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.ocean.dark};
  } */
`;

enum DocType {
  README = 'readme',
  USE_CASES = 'use-cases',
  DESIGN = 'design',
  EXAMPLES = 'examples',
  FUNCTIONS = 'functions',
}

const docTypeFilePathMap: Record<DocType, string> = {
  [DocType.README]: readmeFilePath,
  [DocType.USE_CASES]: useCasesFilePath,
  [DocType.DESIGN]: designFilePath,
  [DocType.EXAMPLES]: examplesFilePath,
  [DocType.FUNCTIONS]: functionsFilePath,
};

const docItems: Array<{ label: string; docType: DocType }> = [
  {
    label: 'Smart Contract',
    docType: DocType.README,
  },
  {
    label: 'Use Cases',
    docType: DocType.USE_CASES,
  },
  {
    label: 'Design',
    docType: DocType.DESIGN,
  },
  {
    label: 'Examples',
    docType: DocType.EXAMPLES,
  },
  {
    label: 'Functions',
    docType: DocType.FUNCTIONS,
  },
];

export const Docs: FC = () => {
  const docTypeParams = useParams<'docType'>();
  const resolvedDocType = useMemo(() => {
    const type = docTypeParams.docType as DocType;
    if (docTypeFilePathMap[type]) {
      return type;
    }

    return DocType.README;
  }, [docTypeParams.docType]);

  const [markdown, setMarkdown] = useState<string>('');

  useEffect(() => {
    const loadMarkdown = async () => {
      const response = await fetch(docTypeFilePathMap[resolvedDocType]);
      setMarkdown(await response.text());
    };

    void loadMarkdown();
  }, [resolvedDocType]);

  return (
    <Main>
      <DocGrid>
        <StyledDrawer variant="permanent">
          <StyledList>
            {docItems.map(({ label, docType }) => (
              <InternalLink
                key={docType}
                to={routes.docsPage(docType)}
                color={resolvedDocType === docType ? 'teal.main' : 'ocean.main'}
              >
                <ListItem button>
                  <ListItemText primary={label} />
                </ListItem>
              </InternalLink>
            ))}
          </StyledList>
        </StyledDrawer>
        <Markdown>{markdown}</Markdown>
      </DocGrid>
    </Main>
  );
};
