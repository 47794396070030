import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import { MARKETPLACE_CONTRACTS } from 'config/coreData';

export type NetworkDeploymentType = 'mainnet' | 'testnet';
export type SupportedBlockChainNetwork = 'POLYGON' | 'CELO' | 'POLYGON_MUMBAI';
export type SupportedBlockChainCoin = 'MATIC' | 'CELO';

export type EnvironmentChainMap = Partial<
  Record<SupportedBlockChainNetwork, number>
>;

export const SupportedMainnetChainId: EnvironmentChainMap = {
  POLYGON: SupportedChainIdsV4.Polygon,
  CELO: SupportedChainIdsV4.Celo,
};

export const SupportedTestnetChainId: EnvironmentChainMap = {
  POLYGON: SupportedChainIdsV4.Polygon,
  CELO: SupportedChainIdsV4.Celo,
  POLYGON_MUMBAI: SupportedChainIdsV4.PolygonMumbai,
};

export interface IAddChainParams {
  chainName: string;
  chainId: number;
  nativeCurrency: {
    name: string;
    decimals: number;
    symbol: SupportedBlockChainCoin;
  };
  rpcUrls: string[];
}

export type AddChainParamsChainMap = Partial<
  Record<SupportedBlockChainNetwork, IAddChainParams>
>;

export const SupportedMainnetAddChainParams: AddChainParamsChainMap = {
  POLYGON: {
    chainName: 'Polygon',
    chainId: SupportedChainIdsV4.Polygon,
    nativeCurrency: {
      name: 'MATIC',
      decimals: 18,
      symbol: 'MATIC',
    },
    rpcUrls: ['https://polygon-rpc.com/'],
  },
  CELO: {
    chainName: 'Celo',
    chainId: SupportedChainIdsV4.Celo,
    nativeCurrency: {
      name: 'CELO',
      decimals: 18,
      symbol: 'CELO',
    },
    rpcUrls: ['https://forno.celo.org/'],
  },
};

export const SupportedTestnetAddChainParams: AddChainParamsChainMap = {
  POLYGON: {
    chainName: 'Polygon',
    chainId: SupportedChainIdsV4.Polygon,
    nativeCurrency: {
      name: 'MATIC',
      decimals: 18,
      symbol: 'MATIC',
    },
    rpcUrls: ['https://polygon-rpc.com/'],
  },
  CELO: {
    chainName: 'Celo',
    chainId: SupportedChainIdsV4.Celo,
    nativeCurrency: {
      name: 'CELO',
      decimals: 18,
      symbol: 'CELO',
    },
    rpcUrls: ['https://forno.celo.org/'],
  },
  POLYGON_MUMBAI: {
    chainName: 'Polygon Mumbai (Testnet)',
    chainId: SupportedChainIdsV4.PolygonMumbai,
    nativeCurrency: {
      name: 'MATIC',
      decimals: 18,
      symbol: 'MATIC',
    },
    rpcUrls: ['https://rpc-mumbai.matic.today/'],
  },
};

export const switchMarketplaceAddress = (chainId: number) => {
  switch (chainId) {
    case 80001:
      return MARKETPLACE_CONTRACTS[80001];
    case 42220:
      return MARKETPLACE_CONTRACTS[42220];
    default:
      return MARKETPLACE_CONTRACTS[137];
  }
};
