import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import CredJson from 'assets/contracts/Cred.json';
import CredRegistryFacet from 'assets/contracts/CredRegistryFacet.json';
import CredRewardFacet from 'assets/contracts/CredRewardFacet.json';
import CredZeroExFacet from 'assets/contracts/CredZeroExFacet.json';
import IERC20Json from 'assets/contracts/IERC20.json';
import IERC721Json from 'assets/contracts/IERC721.json';
import IERC1155Json from 'assets/contracts/IERC1155.json';
import CeloLogo from 'assets/logos/celo-logo.png';
import PolygonLogo from 'assets/logos/polygon-logo.png';
import SDG1_icon from 'assets/sdg/goal_1.svg';
import SDG2_icon from 'assets/sdg/goal_2.svg';
import SDG3_icon from 'assets/sdg/goal_3.svg';
import SDG4_icon from 'assets/sdg/goal_4.svg';
import SDG5_icon from 'assets/sdg/goal_5.svg';
import SDG6_icon from 'assets/sdg/goal_6.svg';
import SDG7_icon from 'assets/sdg/goal_7.svg';
import SDG8_icon from 'assets/sdg/goal_8.svg';
import SDG9_icon from 'assets/sdg/goal_9.svg';
import SDG10_icon from 'assets/sdg/goal_10.svg';
import SDG11_icon from 'assets/sdg/goal_11.svg';
import SDG12_icon from 'assets/sdg/goal_12.svg';
import SDG13_icon from 'assets/sdg/goal_13.svg';
import SDG14_icon from 'assets/sdg/goal_14.svg';
import SDG15_icon from 'assets/sdg/goal_15.svg';
import SDG16_icon from 'assets/sdg/goal_16.svg';
import SDG17_icon from 'assets/sdg/goal_17.svg';
import { StatusItem } from 'components/StatusDialog';
import { StepT } from 'typings/projects';
import { SustainableDevelopmentGoal } from 'typings/sdg';
import { AbiItem } from 'web3-utils';
import { PurchaseStep } from './enum';

export const CRED_REGISTRY_ABI = [
  ...CredRegistryFacet.abi,
  ...CredRewardFacet.abi,
  ...CredZeroExFacet.abi,
] as AbiItem[];

export const CRED_ABI = CredJson.abi as AbiItem[];

export const IERC20 = IERC20Json;
export const IERC721 = IERC721Json;
export const IERC1155 = IERC1155Json;

export const UN_SDGS: SustainableDevelopmentGoal[] = [
  {
    id: 1,
    assets: {
      icon: SDG1_icon,
      name: 'no-poverty',
    },
  },
  {
    id: 2,
    assets: {
      icon: SDG2_icon,
      name: 'zero-hunger',
    },
  },
  {
    id: 3,
    assets: {
      icon: SDG3_icon,
      name: 'good-health-and-well-being',
    },
  },
  {
    id: 4,
    assets: {
      icon: SDG4_icon,
      name: 'quality-education',
    },
  },
  {
    id: 5,
    assets: {
      icon: SDG5_icon,
      name: 'gender-equality',
    },
  },
  {
    id: 6,
    assets: {
      icon: SDG6_icon,
      name: 'clean-water-and-sanitation',
    },
  },

  {
    id: 7,
    assets: {
      icon: SDG7_icon,
      name: 'affordable-and-clean-energy',
    },
  },
  {
    id: 8,
    assets: {
      icon: SDG8_icon,
      name: 'decent-work-and-economic-growth',
    },
  },
  {
    id: 9,
    assets: {
      icon: SDG9_icon,
      name: 'industry-innovation-and-infrastructure',
    },
  },
  {
    id: 10,
    assets: {
      icon: SDG10_icon,
      name: 'reduced-inequality',
    },
  },
  {
    id: 11,
    assets: {
      icon: SDG11_icon,
      name: 'sustainable-cities-and-communities',
    },
  },
  {
    id: 12,
    assets: {
      icon: SDG12_icon,
      name: 'responsible-consumption-and-production',
    },
  },
  {
    id: 13,
    assets: {
      icon: SDG13_icon,
      name: 'climate-action',
    },
  },
  {
    id: 14,
    assets: {
      icon: SDG14_icon,
      name: 'life-below-water',
    },
  },
  {
    id: 15,
    assets: {
      icon: SDG15_icon,
      name: 'life-on-land',
    },
  },
  {
    id: 16,
    assets: {
      icon: SDG16_icon,
      name: 'peace-and-justice-strong-institutions',
    },
  },
  {
    id: 17,
    assets: {
      icon: SDG17_icon,
      name: 'partnerships-to-achieve-the-goal',
    },
  },
];

export const CHAIN_IDS = {
  POLYGON: SupportedChainIdsV4.Polygon,
  CELO: SupportedChainIdsV4.Celo,
  POLYGON_MUMBAI: SupportedChainIdsV4.PolygonMumbai,
};

export const CHAIN_LOGOS = {
  [CHAIN_IDS.POLYGON]: PolygonLogo,
  [CHAIN_IDS.CELO]: CeloLogo,
  [CHAIN_IDS.POLYGON_MUMBAI]: PolygonLogo,
};

export const CHAIN_LABELS = {
  [CHAIN_IDS.POLYGON]: 'Polygon',
  [CHAIN_IDS.CELO]: 'Celo',
  [CHAIN_IDS.POLYGON_MUMBAI]: 'Polygon Mumbai',
};

export const PURCHASE_STEPS: StepT[] = [
  {
    label: 'Connect wallet',
    key: PurchaseStep.CONNECT,
  },
  {
    label: 'Approve funds transfer',
    key: PurchaseStep.APPROVE_FUNDS,
  },
  {
    label: 'Purchase offset',
    key: PurchaseStep.PURCHASE,
  },
];

export const STATUS_ITEMS: StatusItem[] = [
  {
    name: 'Connect to wallet',
    status: 'pending',
  },
  {
    name: 'Approve & Sign NFT listing',
    status: 'pending',
  },
];

export const PURCHASE_NFT_STATUS_ITEMS: StatusItem[] = [
  {
    name: 'Connect to wallet',
    status: 'pending',
  },
  {
    name: 'Approve & Sign NFT Purchase',
    status: 'pending',
  },
];

export const MINIMUM_OFFSET_AMOUNT = 0.1; // in tonnes
export const SERVICE_FEE_OFFSET_KGS = 0; // prev 150 kg (estimated emissions from purchase tx itself)
export const TONNES_TO_KG = 1000;

export const NUMBER_OF_NFT_TO_SHOW = 7;

export const INITIAL_OFFSET_TONNES = 10;
export const MINIMUM_OFFSET_TONNES = 0.1;
