import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryParams<T extends Record<string, any>>(): T {
  const location = useLocation();
  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search).entries()),
    [location.search],
  );
  return queryParams as T;
}
