enum CURRUNCY_TYPE {
    POLYGON_MAINNET_USDC = 'polygon-usdc',
    CELO_MAINNET_USDC = 'celo-usdc',
    CELO_MAINNET_CUSD = 'celo-cusd',
    CELO_MAINNET_CEUR = 'celo-ceur',
}

enum CURRUNCY {
    USDC = 'usdc',
    CUSD = 'cusd',
    CEUR = 'ceur'
}

enum CHAIN_TYPE {
    POLYGON_MAINNET = 137,
    CELO_MAINNET = 42220,
    POLYGON_MUMBAI = 80001
}

const CURRUNCY_INFO = {
    [CURRUNCY_TYPE.POLYGON_MAINNET_USDC]: {
        symbol: 'usdc',
        displaySymbol: 'USDC',
        chain: CHAIN_TYPE.POLYGON_MAINNET
    },
    [CURRUNCY_TYPE.CELO_MAINNET_USDC]: {
        symbol: 'usdc',
        displaySymbol: 'USDC',
        chain: CHAIN_TYPE.CELO_MAINNET
    },
    [CURRUNCY_TYPE.CELO_MAINNET_CUSD]: {
        symbol: 'cusd',
        displaySymbol: 'CUSD',
        chain: CHAIN_TYPE.CELO_MAINNET
    },
    [CURRUNCY_TYPE.CELO_MAINNET_CEUR]: {
        symbol: 'ceur',
        displaySymbol: 'CEUR',
        chain: CHAIN_TYPE.CELO_MAINNET
    },
}

export {
    CURRUNCY,
    CURRUNCY_TYPE,
    CHAIN_TYPE,
    CURRUNCY_INFO
}