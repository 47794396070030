import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material';
import HIWComponenttailwind from 'components/HowItWork/indextailwind';
import HIWComponent from 'components/HowItWork/';
import landing from 'assets/how/hiw-landing.png';
import background from 'assets/how/background-hiw.png';
import imgOurProjectHeaderPlaceholder from 'assets/our-project-header-placeholder.png'
import imgHiwHeaderTextPlaceholder from 'assets/how/hiw-header-text-placeholder.png'

const Section = styled('section')`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const HowItWorks: FC = () => {
  return (
      <main>
        <Helmet>
          <title>CRED | How It Works</title>
        </Helmet>
        <Section>
          <div className='relative z-0'>
          <img className='w-full h-[350px] sm:h-[500px] object-cover' src={landing} alt='bgImage' />
          <div className='absolute top-0 z-10'>
            <img className='h-[350px] sm:h-[500px]' src={imgOurProjectHeaderPlaceholder} alt="blackShadow" />
              <div className='absolute z-20 top-4'>
               <img className='h-[350px] sm:h-[500px]' src={imgHiwHeaderTextPlaceholder} alt="outProjectText" />
              </div>
            </div>
            <div className='absolute z-30 px-16 space-y-2 font-bold text-white top-20 sm:top-40'>
              <p className='text-4xl shadow-2xl md:text-6xl lg:text-7xl font-overpass'>How It Works</p>
              <p className='py-4 text-2xl sm:py-8 md:text-3xl xl:text-4xl font-overpass'>Let's learn about how <br />
                <span className='text-[#23FFF1]'> CRED</span> works </p>
            </div>
          </div>
          <div className='relative'>
            <div className='flex justify-center'>
              <HIWComponenttailwind />
            </div>
          </div>
          {/* <div className='relative'>
            <div className='flex justify-center'>
              <HIWComponent />
            </div>
          </div> */}
        </Section>
      </main>
  );
};
