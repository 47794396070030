/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { Button, Step, Typography } from '@mui/material';
import { NftSwapV4, SignedERC1155OrderStructSerialized } from '@traderxyz/nft-swap-sdk';
import { TransactionReceipt } from 'web3-core';
import { useSnackbar } from 'notistack';
import { ethers } from 'ethers';

import { LiFiWidgetPopout } from 'components/LIFI/LIFIWidgetPopout';
import { MetaMaskLogo } from 'components/icons/MetaMaskLogo';
import { Main } from 'components/layouts/main';
import OrderSummary from 'components/purchase-offsets/OrderSummary';
import {
  CenteredTypography, ConfirmationFrame, ProjectCardSingle,
  Section, StyledMain, StyledStepButton, StyledStepper
} from 'components/Styled';

import { MINIMUM_OFFSET_AMOUNT, PURCHASE_STEPS, SERVICE_FEE_OFFSET_KGS, TONNES_TO_KG } from 'utils/constants';
import { localize } from 'utils/i18n';

import { useQueryParams } from 'hooks/useQueryParams';
import { useResponsive } from 'hooks/useResponsive';

import { useContentContext } from 'providers/ContentProvider';
import { fetchOrderForKilos, getGasPrice, OrderExchangeRate } from 'providers/CredBackendProvider';
import { useEthereumContext } from 'providers/EthereumProvider';

import { PricePerTonne, Project } from 'typings/project';
import { routes } from './routes';
import { PurchaseStep } from 'utils/enum';
import { CURRUNCY, CURRUNCY_INFO, CURRUNCY_TYPE } from '../constants';
import { connect } from 'react-redux';
import { RootState } from '../store/type';

const ProjectPurchase = ({ selectedCurruncy }: { selectedCurruncy: CURRUNCY_TYPE }) => {
  const { isUnfulfilled: isMobile } = useResponsive();
  const { isConnected, provider, isReady } = useEthereumContext();
  const [quantity, setQuantity] = useState<number>()
  const [projectId, setProjectId] = useState<string>()
  const { projectId: queryProjectId, quantity: queryQuantity } =
    useQueryParams<Partial<{ projectId: string; quantity: number }>>();
  const { getProject } = useContentContext();
  const {
    connectWithMetaMask,
    accountAddress,
    getAlchemyCredRegistryContract,
    getERC20Contract,
    credRegistryAddress,
    chainId,
  } = useEthereumContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [balance, setBalance] = useState<number | null>(null);
  const [sufficientBalance, setSufficientBalance] = useState<boolean | null>(
    null,
  );
  const [isProcessing, setProcessing] = useState(false);
  const [isFundingApproved, setFundingApproved] = useState(false);
  const [reason, setReason] = useState<string>('Retail offset purchase.');
  //  LiFi Modal State
  const [openLiFiWidget, setOpenLiFiWidget] = useState<boolean>(false);
  //  State to handle balance fetch status for Get USDC Button
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(true);

  const currentStepIndex = useMemo(
    () => (isConnected ? (isFundingApproved ? 2 : 1) : 0),
    [isConnected, isFundingApproved],
  );
  const currentStep = useMemo(
    () => PURCHASE_STEPS[currentStepIndex]!,
    [currentStepIndex],
  );

  //  LiFi Modal State Toggler
  const toggleLiFiWidgetShow = () => {
    setOpenLiFiWidget(!openLiFiWidget);
  };

  useEffect(() => {
    if (queryQuantity !== undefined && queryQuantity !== quantity)
      setQuantity(queryQuantity)
    if (queryProjectId !== undefined && queryProjectId !== projectId)
      setProjectId(queryProjectId)
  }, [queryQuantity, queryProjectId, projectId, quantity])

  const purchaseQuantity = useMemo(() => {
    if (quantity) {
      return quantity > MINIMUM_OFFSET_AMOUNT
        ? quantity
        : MINIMUM_OFFSET_AMOUNT;
    }

    return MINIMUM_OFFSET_AMOUNT;
  }, [quantity]);

  const numKilos = useMemo(
    () => Math.round(purchaseQuantity * TONNES_TO_KG),
    [purchaseQuantity],
  );

  const [selectedProject, setSelectedProject] = useState<Project | null>();
  const [pricePerTonne, setPricePerTonne] = useState<PricePerTonne>();
  const [signedOrder, setSignedOrder] =
    useState<SignedERC1155OrderStructSerialized>();
  const [exchangeRate, setExchangeRate] = useState<OrderExchangeRate>();
  const [credReward, setCredReward] = useState<number>();

  useEffect(() => {
    const loadProject = async () => {
      if (!projectId) return
      const project = await getProject(projectId);
      setSelectedProject(() => project);
      setPricePerTonne((ppt) => ppt ?? project?.pricePerTonne);
    }

    if (!isReady) return
    void loadProject()
  }, [getProject, projectId, setPricePerTonne, setSelectedProject, isReady]);

  const getOrderForKilos = useCallback(async (project: Project | null | undefined, numKilos: number) => {
    if (!project?.cred) {
      return null;
    }

    const { vintageId } = project.cred;
    console.log({ vintageId });

    const registry = getAlchemyCredRegistryContract();
    const carbId: string = await registry.methods
      .vintageCarbID(vintageId)
      .call();
    console.log({ carbId });

    if (!carbId) {
      return null; // should not happen
    }

    const order = await fetchOrderForKilos(carbId, Math.ceil(numKilos), chainId, selectedCurruncy);
    console.log('signed order:', order);

    return order;
  }, [getAlchemyCredRegistryContract, chainId, selectedCurruncy]);

  useEffect(() => {
    if (isConnected) {
      getOrderForKilos(selectedProject, numKilos)
        .then((result) => {
          if (result) {
            setCredReward(Number(result.reward.cred));
            setExchangeRate(result.exchangeRate);
            setPricePerTonne({
              usdc: Number(result.prices.tonneInUsd),
              ceur: Number(result.prices.tonneInCeuro),
              cusd: Number(result.prices.tonneInCusd)
            });
            setSignedOrder(result.signedOrder);
          }
        })
        .catch((err) => {
          console.log('Error in getOrderForKilos:', err);
        });
    } else {
      setSignedOrder(undefined);
    }
  }, [getOrderForKilos, isConnected, numKilos, selectedProject]);

  const completed = useMemo(
    () => ({
      [PurchaseStep.CONNECT]: isConnected,
      [PurchaseStep.APPROVE_FUNDS]: isFundingApproved,
      [PurchaseStep.PURCHASE]: false,
    }),
    [isConnected, isFundingApproved],
  );

  const handleConnect = useCallback(async () => {
    await connectWithMetaMask();
  }, [connectWithMetaMask]);

  const serviceFee = useMemo(() => {
    if (!selectedProject || !pricePerTonne![CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY]) {
      return NaN;
    }

    return (SERVICE_FEE_OFFSET_KGS * pricePerTonne![CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY]) / TONNES_TO_KG;
  }, [pricePerTonne, selectedProject, selectedCurruncy]);

  const subtotal = useMemo(() => {
    if (!selectedProject || !pricePerTonne![CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY]) {
      return NaN;
    }

    return (numKilos * pricePerTonne![CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY]) / TONNES_TO_KG;
  }, [pricePerTonne, numKilos, selectedProject, selectedCurruncy]);

  const isPurchaseEnabled = useMemo(
    () =>
      isConnected &&
      Boolean(accountAddress) &&
      Boolean(selectedProject) &&
      purchaseQuantity >= MINIMUM_OFFSET_AMOUNT &&
      signedOrder &&
      sufficientBalance,
    [
      accountAddress,
      isConnected,
      purchaseQuantity,
      selectedProject,
      signedOrder,
      sufficientBalance,
    ],
  );

  const getNftSwap = useCallback(async () => {
    if (!provider || !isConnected) return

    return new NftSwapV4(provider, provider.getSigner());
  }, [provider, isConnected]);

  const handleError = useCallback(
    (e: unknown) => {
      console.error(e);
      enqueueSnackbar(`Request failed. ${(e as Error)?.message}`, {
        variant: 'error',
      });
    },
    [enqueueSnackbar],
  );

  const getTakerSideStatus = useCallback(async () => {
    setFetchingBalance(true);
    if (accountAddress && signedOrder) {
      const nftSwap = await getNftSwap();
      if (nftSwap) {
        return nftSwap.checkOrderCanBeFilledTakerSide(
          signedOrder,
          accountAddress,
        );
      }
    }

    return null;
  }, [accountAddress, getNftSwap, signedOrder])

  useEffect(() => {
    getTakerSideStatus()
      .then((status) => {
        if (status) {
          console.log('taker side status:', status);
          console.log("exchange rate", exchangeRate);
          const decimals =
            exchangeRate?.currency === CURRUNCY.USDC ? 6 : 18;
          console.log("status", status.balance, ethers.utils.formatUnits(status.balance, decimals), decimals)

          setBalance(
            Number(ethers.utils.formatUnits(status.balance, decimals)),
          );
          setSufficientBalance(status.hasBalance);
          setFetchingBalance(false);
        } else {
          setBalance(null);
          setSufficientBalance(null);
        }
      })
      .catch(handleError);
  }, [
    accountAddress,
    exchangeRate,
    getNftSwap,
    handleError,
    isFundingApproved, // intentional: update balance after funding is approved
    signedOrder,
    setBalance,
    getTakerSideStatus
  ]);

  const handleApproveFunds = useCallback(async () => {
    // Validation
    if (
      !selectedProject ||
      !accountAddress ||
      !isPurchaseEnabled ||
      !signedOrder
    ) {
      return;
    }

    setProcessing(true);

    try {
      const gasPrice = await getGasPrice('fast');
      console.log('gasPrice:', gasPrice);

      const maxPriorityFeePerGas = gasPrice[0];
      const maxFeePerGas = gasPrice[1];

      const erc20Instance = getERC20Contract(signedOrder.erc20Token);
      if (chainId === 42220) {
        await erc20Instance.methods
          .approve(
            credRegistryAddress,
            signedOrder.erc20TokenAmount,
          )
          .send({
            from: accountAddress
          });
      } else {
        await erc20Instance.methods
          .approve(
            credRegistryAddress,
            signedOrder.erc20TokenAmount,
          )
          .send({
            from: accountAddress,
            maxPriorityFeePerGas,
            maxFeePerGas
          });
      }

      console.log('Funding approved.');
      setFundingApproved(true);
    } catch (e: unknown) {
      handleError(e);
    } finally {
      setProcessing(false);
    }
  }, [
    accountAddress,
    getERC20Contract,
    handleError,
    isPurchaseEnabled,
    selectedProject,
    signedOrder,
    credRegistryAddress,
    chainId
  ]);

  const handlePurchase = useCallback(async () => {
    // Validation
    if (!signedOrder || !isFundingApproved) {
      return;
    }

    setProcessing(true);

    try {
      const gasPrice = await getGasPrice('fast');
      console.log('gasPrice:', gasPrice);

      const maxPriorityFeePerGas = gasPrice[0];
      const maxFeePerGas = gasPrice[1];

      console.log('Reason:', reason);

      let offsetReceipt: TransactionReceipt;

      if (chainId === 42220) {
        offsetReceipt =
          await getAlchemyCredRegistryContract()
            .methods.buyOffset(signedOrder, signedOrder.signature, reason)
            .send({
              from: accountAddress
            });
      } else {
        offsetReceipt =
          await getAlchemyCredRegistryContract()
            .methods.buyOffset(signedOrder, signedOrder.signature, reason)
            .send({
              from: accountAddress,
              maxPriorityFeePerGas,
              maxFeePerGas
            });
      }

      console.log('Receipt for buyOffset:', offsetReceipt);

      const searchParams = (() => {
        const params = new URLSearchParams();
        const omitKeys = ['logs', 'logsBloom', 'events'];

        Object.entries(offsetReceipt).forEach(([key, value]) => {
          if (!omitKeys.includes(key)) {
            params.append(key, value);
          }
        });

        params.sort();
        return params.toString();
      })();

      navigate([routes.transactionSuccessful, searchParams].join('?'));
    } catch (e: unknown) {
      handleError(e);
    } finally {
      setProcessing(false);
    }
  }, [
    reason,
    accountAddress,
    getAlchemyCredRegistryContract,
    handleError,
    isFundingApproved,
    navigate,
    signedOrder,
    chainId
  ]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  if (isMobile) {
    return (
      <Main>
        <Helmet>
          <title>CRED | Unavailable</title>
        </Helmet>
        <CenteredTypography align="center" color="ocean.main" variant="h3">
          This page is only available on desktop
        </CenteredTypography>
      </Main>
    );
  }

  if (selectedProject === null) {
    return (
      <Main>
        <Helmet>
          <title>Project Ark | Project Not Found</title>
        </Helmet>
        <CenteredTypography align="center" color="ocean.main" variant="h3">
          Project not found.
        </CenteredTypography>
      </Main>
    );
  }

  if (selectedProject && !selectedProject.cred) {
    return (
      <Main>
        <Helmet>
          <title>Project Ark | Project Not Supported</title>
        </Helmet>
        <CenteredTypography align="center" color="ocean.main" variant="h3">
          The selected project is not supported in CRED.
        </CenteredTypography>
      </Main>
    );
  }

  return (
    <StyledMain>
      <Helmet>
        <title>CRED | Purchase Offsets</title>
      </Helmet>
      <Typography variant="h1" align="center">
        Purchase Offsets
      </Typography>
      <StyledStepper nonLinear activeStep={currentStepIndex}>
        {PURCHASE_STEPS.map(({ label, key }) => (
          <Step key={key} completed={completed[key]}>
            <StyledStepButton disabled><p style={{color: 'white'}}>{label}</p></StyledStepButton>
          </Step>
        ))}
      </StyledStepper>
      <Section>
        {!isConnected && (
          <Fragment>
            <Typography variant="h3">MetaMask</Typography>
            <MetaMaskLogo width={64} />
            <Typography color="neutralGray.dark" align="center" variant="s3">
              Make sure the account you want to use is <br /> active in your
              MetaMask extension.
            </Typography>
            <Button color="ocean" variant="contained" onClick={handleConnect}>
              Connect
            </Button>
          </Fragment>
        )}
        {isConnected && (
          <ConfirmationFrame>
            <Typography align="center" variant="h3" color="darkGrey.main">
              Purchase Confirmation
            </Typography>
            <Typography align="center" variant="s3" color="darkGrey.main">
              You are about to purchase an offset of
              <strong>
                {' '}
                {localize(numKilos / TONNES_TO_KG, 0, 3)} tonnes{' '}
              </strong>
              to support:
            </Typography>
            {selectedProject && (
              <ProjectCardSingle
                {...selectedProject}
                pricePerTonne={pricePerTonne ?? selectedProject?.pricePerTonne}
              />
            )}
            <div>
              <Typography align="center" variant="s2" color="darkGrey.main">
                from wallet address:
              </Typography>
              <Typography
                align="center"
                variant="monospace"
                color="darkGrey.main"
              >
                {accountAddress}
              </Typography>
            </div>
            {currentStep.key === PurchaseStep.APPROVE_FUNDS && (
              <div style={{ textAlign: 'center' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="s2" color="darkGrey.main">
                    Reason for Offset:
                  </Typography>
                  <input
                    style={{ width: '50%', height: '30px' }}
                    placeholder={reason}
                    onChange={handleInputChange}
                  />
                </div>
                <Typography variant="caption" color="darkGrey.main">
                  You can write the reason for making this offset purchase and
                  it will be depicted in your offset NFT receipt
                </Typography>
              </div>
            )}
            {currentStep.key === PurchaseStep.PURCHASE && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="s2" color="darkGrey.main">
                  Reason for Offset:
                </Typography>
                <Typography variant="s2" color="darkGrey.main">
                  {reason}
                </Typography>
              </div>
            )}
            <OrderSummary
              subtotal={subtotal}
              serviceFee={serviceFee}
              credReward={credReward ?? 0}
              balance={balance}
              sufficientBalance={sufficientBalance}
            />
            {isPurchaseEnabled ? (
              <Button
                fullWidth
                disabled={isProcessing}
                sx={{
                  maxWidth: (theme) => theme.breakpoints.values.sm,
                  mt: 2,
                }}
                color="ocean"
                variant="contained"
                onClick={
                  currentStep.key === PurchaseStep.APPROVE_FUNDS
                    ? handleApproveFunds
                    : currentStep.key === PurchaseStep.PURCHASE
                      ? handlePurchase
                      : undefined
                }
              >
                {currentStep.label}
              </Button>
            ) : (
              //  Show LIFI Popout Button Handler
              <Button
                fullWidth
                disabled={fetchingBalance}
                sx={{
                  maxWidth: (theme) => theme.breakpoints.values.sm,
                  mt: 2,
                }}
                color="ocean"
                variant="contained"
                onClick={() => {
                  toggleLiFiWidgetShow();
                }}
              >
                Get {CURRUNCY_INFO[selectedCurruncy].displaySymbol}
              </Button>
            )}
            {isProcessing && (
              <div>
                <Typography
                  variant="body1"
                  color="rgb(19, 132, 148)"
                  align="center"
                >
                  We are processing your request, please wait
                  <BeatLoader color="rgb(19, 132, 148)" />
                </Typography>
              </div>
            )}
          </ConfirmationFrame>
        )}
        <LiFiWidgetPopout
          open={openLiFiWidget}
          onClose={() => {
            //  Close CRED Widget
            toggleLiFiWidgetShow();
            setBalance(null);
            //  Update the Balance in Wallet
            getTakerSideStatus()
              .then((status) => {
                if (status) {
                  const decimals =
                    exchangeRate?.currency === CURRUNCY.USDC
                      ? 6
                      : 18;
                  setBalance(
                    Number(ethers.utils.formatUnits(status.balance, decimals)),
                  );
                  setSufficientBalance(status.hasBalance);
                  setFetchingBalance(false);
                } else {
                  setBalance(null);
                  setSufficientBalance(null);
                }
              })
              .catch(handleError);
          }}
        />
      </Section>
    </StyledMain>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedCurruncy: state.app.selectedCurruncy,
  }
}

export default connect(mapStateToProps)(ProjectPurchase);
