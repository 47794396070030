import { FC, useState, useCallback, useRef, useMemo } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { useToggle } from 'react-use';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  CardMedia,
  IconButton,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import { NewDirectListing, TransactionError } from '@thirdweb-dev/sdk';
import {
  NftSwapV4,
  SwappableAssetV4,
  SwappableNftV4,
} from '@traderxyz/nft-swap-sdk';
import { StatusDialog, StatusItem } from 'components/StatusDialog';
import { NftCard, TealField, TealFormControl } from 'components/Styled';
import { ethers } from 'ethers';
import useCredNft from 'hooks/nft';
import { NftItemPayload } from 'hooks/nft/types';
import { useSnackbar } from 'notistack';
import { useEthereumContext } from 'providers/EthereumProvider';
import { useTListingContext } from 'providers/TListingProvider';
import { ContractType } from 'typings/nfts';
import { useImmer } from 'use-immer';
import { NUMBER_OF_NFT_TO_SHOW, STATUS_ITEMS } from 'utils/constants';
import { withMinimumExecutionTime } from 'utils/withMinimumExecutionTime';
import utils from 'web3-utils';

const { isAddress, toWei } = utils;

export const ListNft: FC = () => {
  const [contractType, setContractType] = useState<number>(ContractType.ERC721);
  const [myNfts, setMyNfts] = useState<NftItemPayload[]>([]);
  const [nftCounter, setNftCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const { provider, chainId, accountAddress } = useEthereumContext();
  const {
    connectWithMetaMask,
    getERC721Contract,
    getERC1155Contract,
    credAddress,
  } = useEthereumContext();
  const { marketplace } = useTListingContext();
  const [disableQuantity, setDisableQuantity] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const contractAddressRef = useRef<HTMLInputElement>(null!);
  const tokenIdRef = useRef<HTMLInputElement>(null!);
  const priceRef = useRef<HTMLInputElement>(null!);

  const { enqueueSnackbar } = useSnackbar();
  const [statusDialogOpen, toggleStatusDialog] = useToggle(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [statusItems, updateStatusItems] = useImmer<StatusItem[]>(STATUS_ITEMS);

  const { getNftListByOwner } = useCredNft();

  const theme = useTheme();

  const setStatusError = useCallback(
    (errorText: string) => {
      setErrorText(errorText);
      updateStatusItems((items) => {
        const pendingIndex = items.findIndex(
          (item) => item.status === 'pending',
        );

        if (pendingIndex !== -1) {
          items[pendingIndex]!.status = 'error';
        }
      });
    },
    [updateStatusItems],
  );

  const updateStatus = useCallback(
    (index: number, status: StatusItem['status']) => {
      if (index > 1) {
        return;
      }

      updateStatusItems((items) => {
        items[index]!.status = status;
      });
    },
    [updateStatusItems],
  );

  const resetStatusItems = useCallback(() => {
    updateStatusItems((statusItems) =>
      statusItems.map((item) => ({ ...item, status: 'pending' })),
    );
  }, [updateStatusItems]);

  const closeStatusDialog = useCallback(() => {
    setErrorText(null);
    toggleStatusDialog(false);
    resetStatusItems();
  }, [toggleStatusDialog, resetStatusItems]);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const contractType = Number(event.target.value);
      setContractType(contractType);
      if (contractType === ContractType.ERC721) {
        setQuantity(1);
        setDisableQuantity(true);
      } else {
        setDisableQuantity(false);
      }
    },
    [setContractType],
  );

  function isValidTokenId(input: string) {
    return /^([0-9]+|0x[0-9A-Fa-f]+)$/.test(input);
  }

  function isValidPrice(input: string, quantity: number) {
    if (quantity === 0) {
      // we allow removing a listing by giving quantity 0, but price must also be 0 in that case
      return Number.parseFloat(input) === 0;
    }

    // otherwise, price must be positive
    return Number.parseFloat(input) > 0;
  }

  const listThirdweb = useCallback(async () => {
    const contractAddress = contractAddressRef.current.value;

    if (!provider) {
      const errorText =
        'Provider not found. Please check your network connection and try again.';
      setStatusError(errorText);
      enqueueSnackbar(errorText, {
        variant: 'error',
      });
      return;
    }

    if (!chainId) {
      const errorText =
        'Chain ID not found. Please check your network connection and try again.';
      setStatusError(errorText);
      enqueueSnackbar(errorText, {
        variant: 'error',
      });
      return;
    }

    if (!isAddress(contractAddress)) {
      const errorText =
        'Invalid contract address format. Please check and try again.';
      alert(errorText);
      return;
    }

    if (!isValidTokenId(tokenIdRef.current.value)) {
      const errorText =
        'Invalid token ID format. Please enter a positive integer.';
      alert(errorText);
      return;
    }

    if (!isValidPrice(priceRef.current.value, quantity)) {
      alert(
        `Invalid price. ${quantity === 0
          ? 'Must be 0 when removing listing.'
          : 'Must be positive.'
        }`,
      );
      return;
    }

    //const eachPriceInWei = toWei(priceRef.current.value);
    toggleStatusDialog(true);

    console.log('Creating new listing');
    try {
      const isMetaMaskConnected = await withMinimumExecutionTime(
        connectWithMetaMask(),
        500,
      ).catch((error) => {
        const errorText = `Error connecting to MetaMask: ${error.message}`;
        setStatusError(errorText);
        enqueueSnackbar(errorText, {
          variant: 'error',
        });
        return false;
      });

      if (!isMetaMaskConnected) {
        const errorText = 'Unable to connect to MetaMask.';
        setStatusError(errorText);
        enqueueSnackbar(errorText, {
          variant: 'error',
        });
        return;
      }

      updateStatus(0, 'success');
      const marketplacex = await marketplace;
      if (!marketplacex) {
        const errorText = 'Marketplace not found.';
        setStatusError(errorText);
        enqueueSnackbar(errorText, {
          variant: 'error',
        });
        return;
      }

      const listing: NewDirectListing = {
        // address of the NFT contract the asset you want to list is on
        assetContractAddress: contractAddress,
        // token ID of the asset you want to list
        tokenId: tokenIdRef.current.value,
        // when should the listing open up for offers
        startTimestamp: new Date(),
        // how long the listing will be open for
        listingDurationInSeconds: 864000000,
        // how many of the asset you want to list
        quantity: quantity,
        // address of the currency contract that will be used to pay for the listing
        currencyContractAddress: credAddress,
        // how much the asset will be sold for
        buyoutPricePerToken: priceRef.current.value,
      };

      const newListing = await marketplacex?.direct.createListing(listing);

      if (!newListing || !newListing.id) {
        const errorText = 'Listing creation failed. Please try again later.';
        setStatusError(errorText);
        enqueueSnackbar(errorText, {
          variant: 'error',
        });
        return;
      }

      const numOrders = Number(localStorage.getItem('orderCount'));

      //  The createListing step includes approval for nft transfer.
      //  We may have to remove Step #2 from the pop-up.
      updateStatus(1, 'success');

      localStorage.setItem('orderCount', (numOrders + 1).toString());
      localStorage.setItem(`order${numOrders + 1}`, JSON.stringify(newListing));

      updateStatus(2, 'success');
    } catch (error: any) {
      const msg = (error as TransactionError)?.reason;
      const errorText = `Transaction unsuccessful. ${msg}`;
      setStatusError(errorText);
      enqueueSnackbar(errorText, {
        variant: 'error',
      });
    }
  }, [
    connectWithMetaMask,
    enqueueSnackbar,
    setStatusError,
    toggleStatusDialog,
    updateStatus,
    marketplace,
    credAddress,
    quantity,
    contractType,
  ]);

  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  /* eslint-disable @typescript-eslint/no-unsafe-call */

  const list = useCallback(async () => {
    const contractAddress = contractAddressRef.current.value;
    if (!provider) return;
    if (!chainId) return;
    if (!isAddress(contractAddress)) {
      alert('Invalid contract address.');
      return;
    }

    if (!isValidTokenId(tokenIdRef.current.value)) {
      alert('Invalid token ID.');
      return;
    }

    if (!isValidPrice(priceRef.current.value, quantity)) {
      alert(
        `Invalid price. ${quantity === 0
          ? 'Must be 0 when removing listing.'
          : 'Must be positive.'
        }`,
      );
      return;
    }

    const eachPriceInWei = toWei(priceRef.current.value);

    toggleStatusDialog(true);

    let assetContract;

    const erc20_asset: SwappableAssetV4 = {
      tokenAddress: credAddress,
      amount: eachPriceInWei,
      type: 'ERC20',
    };

    try {
      const isMetaMaskConnected = await withMinimumExecutionTime(
        connectWithMetaMask(),
        500,
      );
      if (!isMetaMaskConnected) {
        throw new Error('Unable to connect to MetaMask.');
      }

      updateStatus(0, 'success');
      if (contractType === ContractType.ERC721) {
        assetContract = getERC721Contract(contractAddress);
        const owner: string =
          (await assetContract.methods
            .ownerOf(tokenIdRef.current.value)
            .call()) ?? '';
        if (accountAddress !== owner.toLowerCase()) {
          throw new Error('Asset not owned by address.');
        }

        const erc721_asset: SwappableNftV4 = {
          tokenAddress: contractAddress,
          tokenId: tokenIdRef.current.value,
          type: 'ERC721',
        };

        const nftSwapSdk = new NftSwapV4(
          provider,
          provider.getSigner(),
          chainId,
        );

        const approvalStatusForUserA = await nftSwapSdk.loadApprovalStatus(
          erc721_asset,
          accountAddress,
        );

        if (!approvalStatusForUserA.contractApproved) {
          const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
            erc721_asset,
            accountAddress,
          );
          const approvalTxReceipt = await approvalTx.wait();
          console.log(
            `Approved ${erc721_asset.tokenAddress} contract to swap with 0x v4 (txHash: ${approvalTxReceipt.transactionHash})`,
          );
        }

        updateStatus(1, 'success');

        const order = nftSwapSdk.buildNftAndErc20Order(
          erc721_asset,
          erc20_asset,
          'sell',
          accountAddress,
        );

        const signedOrder = await nftSwapSdk.signOrder(order);
        console.log('signed order', signedOrder);

        // const postedOrder = await nftSwapSdk.postOrder(
        //   signedOrder,
        //   CHAIN_ID.toString(),
        // );
        // console.log('posted order', postedOrder);

        const numOrders = Number(localStorage.getItem('orderCount'));
        localStorage.setItem('orderCount', (numOrders + 1).toString());

        localStorage.setItem(
          `order${numOrders + 1}`,
          JSON.stringify(signedOrder),
        );
        updateStatus(2, 'success');
      } else {
        assetContract = getERC1155Contract(contractAddress);
        const amountOwned = Number(
          await assetContract.methods
            .balanceOf(accountAddress, tokenIdRef.current.value)
            .call(),
        );
        if (amountOwned < quantity) {
          throw new Error('Insufficient quantity owned.');
        }

        const erc1155_asset: SwappableNftV4 = {
          tokenAddress: contractAddress,
          tokenId: tokenIdRef.current.value,
          type: 'ERC1155',
          amount: quantity.toString(),
        };

        const nftSwapSdk = new NftSwapV4(
          provider,
          provider.getSigner(),
          chainId,
        );
        if (!accountAddress) return;

        const approvalStatusForUserA = await nftSwapSdk.loadApprovalStatus(
          erc1155_asset,
          accountAddress,
        );

        if (!approvalStatusForUserA.contractApproved) {
          const approvalTx = await nftSwapSdk.approveTokenOrNftByAsset(
            erc1155_asset,
            accountAddress,
          );
          const approvalTxReceipt = await approvalTx.wait();
          console.log(
            `Approved ${erc1155_asset.tokenAddress} contract to swap with 0x v4 (txHash: ${approvalTxReceipt.transactionHash})`,
          );
        }

        updateStatus(1, 'success');

        const order = nftSwapSdk.buildNftAndErc20Order(
          erc1155_asset,
          erc20_asset,
          'sell',
          accountAddress,
        );

        const signedOrder = await nftSwapSdk.signOrder(order);
        console.log('signed order', signedOrder);

        const postedOrder = await nftSwapSdk.postOrder(signedOrder, chainId);
        console.log('posted order', postedOrder);

        updateStatus(2, 'success');
      }
    } catch (error: unknown) {
      console.error({ error });
      const errorText = `Transaction unsuccessful. ${(error as Error)?.message ?? 'Unknown Error'
        }`;
      setStatusError(errorText);
      enqueueSnackbar(errorText, {
        variant: 'error',
      });
    }
  }, [
    connectWithMetaMask,
    enqueueSnackbar,
    getERC721Contract,
    getERC1155Contract,
    setStatusError,
    toggleStatusDialog,
    updateStatus,
    quantity,
    contractType,
    provider,
    chainId,
    accountAddress,
    credAddress,
  ]);

  const fetchMyNfts = useCallback(async () => {
    if (!accountAddress) return;
    setLoading(true);
    try {
      const isMetaMaskConnected = await withMinimumExecutionTime(
        connectWithMetaMask(),
        500,
      );

      if (!isMetaMaskConnected) {
        throw new Error('Unable to connect to MetaMask');
      }

      const NFTs = await getNftListByOwner(accountAddress, []);
      setMyNfts(NFTs);
    } catch (error: unknown) {
      console.error({ error });
    }

    setLoading(false);
  }, [connectWithMetaMask, getNftListByOwner, accountAddress]);

  const handleSelectNft = (nft: NftItemPayload) => {
    setContractType(ContractType.ERC1155);
    contractAddressRef.current.value = nft.contractAddress;
    tokenIdRef.current.value = ethers.BigNumber.from(nft.tokenId).toString();
  };

  const nftToShow = useMemo(
    () => myNfts.slice(nftCounter, nftCounter + NUMBER_OF_NFT_TO_SHOW),
    [nftCounter, myNfts],
  );

  const next = useCallback(() => {
    setNftCounter((counter) =>
      Math.min(
        counter + NUMBER_OF_NFT_TO_SHOW,
        myNfts.length - NUMBER_OF_NFT_TO_SHOW,
      ),
    );
  }, [myNfts, setNftCounter]);

  const back = useCallback(() => {
    setNftCounter((counter) => Math.max(counter - NUMBER_OF_NFT_TO_SHOW, 0));
  }, [setNftCounter]);

  return (
    <Box mt={5}>
      <Typography variant="h1" color="darkGrey.main" align="center" mb={5}>
        List an NFT for sale in the Marketplace
      </Typography>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        {myNfts.length === 0 ? (
          <Button variant="contained" color="ocean" onClick={fetchMyNfts}>
            Load my NFTS
          </Button>
        ) : (
          <Typography>Your NFTS</Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '20px',
          margin: '20px',
        }}
      >
        {nftCounter > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              size="small"
              onClick={() => {
                back();
              }}
            >
              <ArrowBack />
            </IconButton>
          </Box>
        )}
        {loading ? (
          <BeatLoader color={theme.palette.ocean.main} />
        ) : (
          nftToShow?.map((nft) => (
            <NftCard
              key={nft.tokenId}
              sx={{ maxWidth: 150 }}
              onClick={() => {
                handleSelectNft(nft);
              }}
            >
              <CardMedia
                component="img"
                image={nft.metadata.image}
                alt={nft.metadata.name}
              />
              <Typography
                sx={{ paddingTop: 1 }}
                textAlign="center"
                variant="body2"
                color="ocean.dark"
              >
                {nft.metadata.name}
              </Typography>
            </NftCard>
          ))
        )}
        {nftCounter < myNfts.length - NUMBER_OF_NFT_TO_SHOW && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              size="small"
              onClick={() => {
                next();
              }}
            >
              <ArrowForward />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '50px',
          margin: '50px',
        }}
      >
        <TealFormControl sx={{ minWidth: 200 }}>
          <InputLabel id="contract-type-field">Contract Type</InputLabel>
          <Select
            labelId="contract-type-field"
            value={String(contractType)}
            label="Contract Type"
            onChange={handleChange}
          >
            <MenuItem value={ContractType.ERC721}>ERC-721</MenuItem>
            <MenuItem value={ContractType.ERC1155}>ERC-1155</MenuItem>
          </Select>
        </TealFormControl>
        <TealField
          label="Contract Address"
          variant="outlined"
          inputRef={contractAddressRef}
          InputLabelProps={{ shrink: true }}
        />
        <TealField
          InputLabelProps={{ shrink: true }}
          label="Token ID"
          variant="outlined"
          inputRef={tokenIdRef}
        />
        <TealField
          label="Quantity"
          variant="outlined"
          value={quantity}
          disabled={disableQuantity}
          type="number"
          onChange={(e) => {
            setQuantity(Number(e.target.value));
          }}
        />
        <TealField
          label="Price of each in CRED"
          variant="outlined"
          inputRef={priceRef}
          type="number"
        />
      </Box>
      <Box textAlign="center">
        <Button
          color="ocean"
          variant="contained"
          sx={{ p: '5px 60px', mb: '50px' }}
          onClick={listThirdweb}
        >
          Create or update listing
        </Button>
      </Box>
      <StatusDialog
        title="Listing Status"
        open={statusDialogOpen}
        errorText={errorText}
        statusItems={statusItems}
        onClose={closeStatusDialog}
      />
    </Box>
  );
};

export default ListNft;
