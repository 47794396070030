import { FC, useMemo } from 'react';
import { Typography, Box} from '@mui/material';
import utils from 'web3-utils';
import { SearchResultCred } from 'typings/search-result';
import { useContentContext } from 'providers/ContentProvider';

const { toBN, numberToHex } = utils;
interface ProfileLeftSideProps {
  supportedProjects: SearchResultCred[];
}

export const ProfileLeftSide: FC<ProfileLeftSideProps> = ({ supportedProjects }) => {
  const { sdgGoals, projects } = useContentContext()

  const supportedSdgIds = useMemo(() => {
    const projectsId = new Set();
    supportedProjects.forEach((project) => {
      projectsId.add(numberToHex(toBN(project.projectID)))
    });
    const sdgIds = new Set();
    projects.forEach((project) => {
      if (projectsId.has(project.cred?.projectId)) {
        const sdgArray = new Set(project.content?.sdg);
        sdgArray.forEach(sdgIds.add, sdgIds);
      }
    });

    return sdgIds;
  }, [projects, supportedProjects]);

  return (
    <Box flex={1} pt={2}>
      <Typography variant="s1" color="ocean.main">
        {' '}
        Sustainable Development Goals
      </Typography>
      <Box
        sx={{
          paddingX: '15px',
          paddingY: '20px',
          border: '5px solid #6FDED5',
          borderRadius: '20px',
        }}
      >
        <div className='grid grid-cols-3 gap-4'>
          {sdgGoals.map((goal) => (
            <div
              key={goal.id}
              style={{ opacity: supportedSdgIds.has(goal.id) ? 1 : 0.2 }}
            >
              <img
                src={goal.assets.icon}
                alt={`SDG goal ${String(goal.id)}: ${String(goal.assets.name)}`}
                height="80px"
              />
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};
