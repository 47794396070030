import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  FormControl,
  OutlinedInput,
  styled,
  InputLabel,
  Typography,
  Button,
} from '@mui/material';
import { localize } from 'utils/i18n';
import { useSnackbar } from 'notistack';
import utils from 'web3-utils';
import { CryptoBalanceCard } from 'components/CryptoBalanceCard';
import { SearchResultTable } from 'components/SearchResultTable';
import { Main } from 'components/layouts/main';
import { useEthereumContext } from 'providers/EthereumProvider';
import { SearchResultCred } from 'typings/search-result';
import { routes } from './routes';

const { isAddress, toBN, numberToHex, fromWei } = utils;
type BN = ReturnType<typeof toBN>;

const SearchInputSection = styled('section')`
  display: grid;
  place-items: center;
  width: 100%;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(4)} !important;
  }
`;

const TealFormControl = styled(FormControl)`
  & * {
    border-color: ${({ theme }) => theme.palette.darkGrey.main} !important;
    color: ${({ theme }) => theme.palette.darkGrey.main} !important;
  }
`;

const ResultSection = styled('section')`
  padding: ${({ theme }) => theme.spacing(4, 0)};

  display: grid;
  place-items: center;

  gap: ${({ theme }) => theme.spacing(4, 0)};
`;

export const CarbonCreditSearch: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    connectWithMetaMask,
    accountAddress,
    getAlchemyCredRegistryContract,
    getAlchemyCredContract,
    getWeb3,
    watchCredInMetaMask,
    isReady
  } = useEthereumContext();

  const [numCredTokensStr, setNumCredTokensStr] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<SearchResultCred[]>([]);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [searchAddress, setSearchAddress] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const toBaseTokenId = useCallback((id: string | number | BN) => {
    if (typeof id === 'string' || typeof id === 'number') {
      return toBN(id).and(toBN('0xffffffffffffffff'));
    }

    return id.and(toBN('0xffffffffffffffff'));
  }, []);

  const toSerialNumber = useCallback(
    (id: string, projectId: string, vintageId: string) =>
      [
        numberToHex(toBaseTokenId(projectId)),
        numberToHex(toBaseTokenId(vintageId)),
        numberToHex(toBaseTokenId(id)),
      ].join('/'),
    [toBaseTokenId],
  );

  const search = useCallback(
    async (address: string, updateSearchParams: boolean) => {
      const credContract = getAlchemyCredContract();
      console.log('credContract.address:', credContract.options.address);

      const chainId = await getWeb3().eth.getChainId();
      const registryContract = getAlchemyCredRegistryContract();
      const registryAddress = registryContract.options.address;
      console.log('registryAddress:', registryAddress);

      if (updateSearchParams) {
        setSearchParams({ address });
      }

      if (!isAddress(address)) {
        setSearchAddress(address ? `Invalid address: ${address}` : null);
        setNumCredTokensStr('---');
        setSearchResults([]);
        return;
      }

      setSearchAddress(address);
      console.log('searching address:', address);

      const numCredInWei: string = await credContract.methods
        .balanceOf(address)
        .call();
      const numCredTokens = fromWei(numCredInWei, 'ether');
      setNumCredTokensStr(localize(Number(numCredTokens), 0, 3));

      /* eslint-disable new-cap */
      const OFFSET_TYPE = toBN(await registryContract.methods.OFFSET().call());

      const numOffsets = Number(
        await registryContract.methods.numOffsets().call(),
      );
      console.log('numOffsets:', numOffsets);

      // Use a single balanceOfBatch request to query the balance of address for each of the offset IDs.
      // e.g. registryContract.balanceOfBatch([address, address, address], [OFFSET+1, OFFSET+2, OFFSET+3])

      const addresses: string[] = [];
      const ids: string[] = [];
      for (let i = 1; i <= numOffsets; ++i) {
        addresses.push(address);
        ids.push(OFFSET_TYPE.add(toBN(i)).toString());
      }

      const balances = (
        await registryContract.methods.balanceOfBatch(addresses, ids).call()
      ).map(Number);

      // select the offset IDs with non-zero balance
      const owned = ids.filter((_, i) => balances[i] > 0);
      console.log('owned OFFSETs:', owned);

      const searchResults: SearchResultCred[] = await Promise.all(
        owned.map(async (id) => {
          const offset = await registryContract.methods
            .getOffset(id)
            .call()
            .catch((err: unknown) => {
              console.log('Error in getOffset call for id:', id, err);
            });
          const openseaUrl = routes.openseaAssetUrl(
            registryAddress,
            id,
            chainId,
          );
          return {
            ...offset,
            tokenID: id,
            serialNumber: toSerialNumber(
              id,
              offset.projectID,
              offset.vintageID,
            ),
            openseaUrl,
          };
        }),
      );
      console.log('search results:', searchResults);

      setSearchResults(searchResults);
    },
    [
      getAlchemyCredRegistryContract,
      getAlchemyCredContract,
      getWeb3,
      setSearchParams,
      toSerialNumber,
    ],
  );
  const handleError = useCallback(
    (e: unknown) => {
      console.error(e);
      enqueueSnackbar(`Request failed. ${(e as Error)?.message}`, {
        variant: 'error',
      });
    },
    [enqueueSnackbar],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setWalletAddress(event.target.value);
    },
    [setWalletAddress],
  );

  const handleSearch = useCallback(
    async () => search(walletAddress, true).catch(handleError),
    [handleError, search, walletAddress],
  );

  const handleCheckAccount = useCallback(async () => {
    if (!(await connectWithMetaMask())) return;
    const address = accountAddress ?? ''
    setWalletAddress(address);
    return search(address, true).catch(handleError);
  }, [handleError, connectWithMetaMask, search, accountAddress]);

  useEffect(() => {
    const address = searchParams.get('address') ?? '';
    setWalletAddress(address);
  }, [search, searchParams]);

  useEffect(() => {
    if (!(walletAddress && isReady)) return
    console.log('walletAddress:', walletAddress);
    search(walletAddress, false);
  }, [search, walletAddress, isReady])


  return (
    <Main>
      <SearchInputSection>
        <Typography variant="h1">Carbon Offsets Search</Typography>
        <TealFormControl fullWidth color="teal" sx={{ maxWidth: '600px' }}>
          <InputLabel htmlFor="search-input-field">Wallet Address</InputLabel>
          <OutlinedInput
            id="search-input-field"
            label="Wallet Address"
            value={walletAddress}
            endAdornment={
              <Button color="teal" onClick={handleSearch}>
                Search
              </Button>
            }
            onChange={handleChange}
          />
          <Button color="teal" onClick={handleCheckAccount}>
            Check My Account
          </Button>
        </TealFormControl>
      </SearchInputSection>
      <ResultSection>
        {numCredTokensStr && searchAddress && (
          <CryptoBalanceCard
            tokenSymbol="CRED"
            amount={numCredTokensStr ?? 0}
            walletAddress={searchAddress}
            onMetaMaskClick={watchCredInMetaMask}
          />
        )}
        <SearchResultTable results={searchResults} />
      </ResultSection>
    </Main>
  );
};
