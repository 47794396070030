import { FC } from 'react';

import { Typography } from '@mui/material';


interface NftContentSectionProps {
  heading: string;
  content: string | undefined;
}

export const NftContentSection: FC<NftContentSectionProps> = ({
  heading,
  content,
}) => {
  if (content === undefined) {
    return null;
  }

  return (
    <section>
      <Typography variant="s1" color="ocean.dark">
        {heading}
      </Typography>
      <Typography sx={{ mt: 2 }} color="#41aea8" variant="body1">
        {content}
      </Typography>
    </section>
  );
};
