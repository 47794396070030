/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SignedERC1155OrderStructSerialized } from '@traderxyz/nft-swap-sdk';
import { Project } from 'typings/project';
import { convertProject } from 'utils/projects';
import { CURRUNCY_INFO, CURRUNCY_TYPE } from '../constants';

export interface GasFees {
  maxPriorityFee: number;
  maxFee: number;
}

export interface GasPrices {
  safeLow: GasFees;
  standard: GasFees;
  fast: GasFees;
  source: string;
}

export interface OrderPrices {
  tonneInUsd: string;
  tonneInCusd: string;
  tonneInCeuro: string;
}

export interface OrderExchangeRate {
  currency: string;
  amount: string;
  updatedAt: number;
}

export interface OrderReward {
  cred: string;
}

export interface OrderResult {
  signedOrder: SignedERC1155OrderStructSerialized;
  exchangeRate: OrderExchangeRate;
  prices: OrderPrices;
  reward: OrderReward;
}

const REACT_APP_CRED_API = process.env.REACT_APP_CRED_API ?? '';

const CRED_API_URL = `${REACT_APP_CRED_API}`;

export async function fetchGasPrices(chainId: number): Promise<GasPrices> {
  const response = await fetch(`${CRED_API_URL}/gasPrices?debug=true&chainId=${chainId}`);
  return (await response.json()) as GasPrices;
}

export async function getGasPrice(
  tier: 'safeLow' | 'standard' | 'fast',
): Promise<number[]> {
  let chainId = await window.ethereum.request({ method: 'eth_chainId' });
  chainId = parseInt(chainId, 16);
  const gasPrices = await fetchGasPrices(chainId);
  console.log('gasPrices:', gasPrices);
  const fee = gasPrices[tier].maxPriorityFee;
  const maxfee = gasPrices[tier].maxFee;
  return [Math.ceil(fee * 1e9), Math.ceil(maxfee * 1e9)];
}

export async function fetchOrderForKilos(
  carbId: string,
  numKilos: number,
  chainId: number,
  currency: CURRUNCY_TYPE
): Promise<OrderResult> {
  const response = await fetch(
    `${REACT_APP_CRED_API}/orderForKilos?debug=true&carbId=${carbId}&numKilos=${Math.round(
      numKilos,
    )}&chainId=${chainId}&currency=${CURRUNCY_INFO[currency].symbol}`,
  );
  return (await response.json()) as OrderResult;
}

export async function fetchProjects(chainId: number): Promise<Project[]> {
  const resp = await fetch(`${CRED_API_URL}/projects?debug=true&chainId=${chainId}`);
  const body = (await resp.json()) as { projects: any[] };
  return (body.projects ?? []).map(convertProject);
}

export async function fetchCarbSupplies(chainId: number, carbId: string): Promise<any> {
  const response = await fetch(`${REACT_APP_CRED_API}/supplies?chainId=${chainId}&carbId=${carbId}`)
  const body = (await response.json()) as { carbSupplyInKilos: number }
  return (body.carbSupplyInKilos ?? undefined);
}


