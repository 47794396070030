import { FC } from 'react';
import { BeatLoader } from 'react-spinners';
import { styled, Divider, Typography } from '@mui/material';
import { formatCRED, formatCurrency, localize } from 'utils/i18n';
import { CURRUNCY_INFO, CURRUNCY_TYPE } from '../../constants';
import { connect } from 'react-redux';
import { RootState } from 'store/type';

const OrderSummaryFrame = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${({ theme }) => theme.spacing(1, 0)};
`;

const Label = styled(Typography)``;
Label.defaultProps = {
  variant: 'monospace',
  color: 'ocean.main',
};

const Value = styled(Typography)``;
Value.defaultProps = {
  align: 'right',
  variant: 'monospace',
  fontWeight: 'bold',
  color: 'ocean.main',
};

interface OrderSummaryProps {
  subtotal: number;
  serviceFee: number;
  credReward: number;
  balance: number | null;
  sufficientBalance: boolean | null;
  selectedCurruncy: CURRUNCY_TYPE
}

const OrderSummary: FC<OrderSummaryProps> = ({
  subtotal,
  serviceFee,
  credReward,
  balance,
  sufficientBalance,
  selectedCurruncy
}) => {

  function balLoading(amount: string | number | null, decimals = 2) {
    return amount === null || Number.isNaN(amount) ? (
      <BeatLoader color="rgb(19, 132, 148)" />
    ) : (
      `$${localize(Number(amount), decimals, decimals)} ${CURRUNCY_INFO[selectedCurruncy].displaySymbol}`
    );
  }

  return (
    <OrderSummaryFrame>
      <Label>Subtotal</Label>
      <Value>{formatCurrency(subtotal, selectedCurruncy)}</Value>
      <Label>Service Fee</Label>
      <Value>{formatCurrency(serviceFee, selectedCurruncy)}</Value>

      <Divider />
      <Divider />

      <Label>Total</Label>
      <Value>{formatCurrency(subtotal + serviceFee, selectedCurruncy)}</Value>
      <Label>CRED Reward</Label>
      <Value>{formatCRED(credReward)}</Value>

      <Label mt={1}>Balance in wallet</Label>
      <Value mt={1} color={sufficientBalance === false ? 'error' : undefined}>
        {balLoading(balance)}
      </Value>
    </OrderSummaryFrame>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedCurruncy: state.app.selectedCurruncy,
  }
}

export default connect(mapStateToProps)(OrderSummary);
