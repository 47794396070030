import { FC } from 'react';
import { useToggle } from 'react-use';
import { Close, Menu } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  Toolbar,
  styled,
  IconButton,
  Fade,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import { InternalLink } from 'components/InternalLink';
import { ProjectArkLogoOnly } from 'components/icons/ProjectArkLogoOnly';
import { navItems } from './nav-items';

const StyledToolbar = styled(Toolbar)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const MobileNavButton = styled(IconButton)`
  width: fit-content;
`;

const ArkLogo = styled(ProjectArkLogoOnly)`
  justify-self: center;
`;

const StyledPaper = styled(Paper)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  padding: ${({ theme }) => theme.spacing(2, 0)};
  z-index: ${({ theme }) => theme.zIndex.drawer};
`;

const NavButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(1, 3)};
  text-transform: none;
  justify-content: start;
`;

type Props = {
  isCorrect: boolean;
}

export const MobileAppBar: FC<Props> = ({ isCorrect }) => {
  const [isMobileNavExpanded, toggleMobileNav] = useToggle(false);

  return (
    <MuiAppBar color="default" elevation={0} position="static">
      <StyledToolbar>
        <MobileNavButton onClick={toggleMobileNav}>
          {isMobileNavExpanded ? <Close /> : <Menu />}
        </MobileNavButton>
        <ArkLogo fontSize="large" />
        <Fade in={isMobileNavExpanded}>
          <StyledPaper square elevation={0}>
            {navItems.map(({ name, path }) => (
              <InternalLink key={path} to={path}>
                <NavButton fullWidth color="ocean" onClick={toggleMobileNav}>
                  <Typography>{name}</Typography>
                </NavButton>
              </InternalLink>
            ))}
          </StyledPaper>
        </Fade>
      </StyledToolbar>
      {isCorrect &&
        <div style={{ alignItems: 'center', background: 'rgb(242, 153, 74)', justifyContent: 'center', display: 'flex' }}>
          <Typography>Unfortunately your wallet network is not suppported on our website yet. Please switch to Polygon or Celo mainnet</Typography>
        </div>
      }
    </MuiAppBar>
  );
};
