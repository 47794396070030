import {
  EnvironmentChainMap,
  IAddChainParams,
  NetworkDeploymentType,
  SupportedMainnetChainId,
  SupportedTestnetChainId,
  SupportedMainnetAddChainParams,
  SupportedTestnetAddChainParams,
  AddChainParamsChainMap,
} from "utils/environment";

interface IUseSupportedFn {
  getNetworkType(chainId: number): NetworkDeploymentType | undefined;
  getSupportedChainIdMap(networkType: NetworkDeploymentType): EnvironmentChainMap;
  isChainIdSupported(chainId: number): boolean;
  getChainAddParamas(chainId: number): IAddChainParams;
}

export const useSupported = (): IUseSupportedFn => {
  const getNetworkType = (chainId: number): NetworkDeploymentType | undefined => {
    if (Object.values(SupportedMainnetChainId).includes(chainId)) return 'mainnet'
    if (Object.values(SupportedTestnetChainId).includes(chainId)) return 'testnet'
  }

  const getSupportedChainIdMap = (networkType: NetworkDeploymentType): EnvironmentChainMap => {
    return networkType === 'mainnet' ? SupportedMainnetChainId : SupportedTestnetChainId;
  }

  const getSupportedAddChainParamsMap = (networkType: NetworkDeploymentType): AddChainParamsChainMap => {
    return networkType === 'mainnet' ? SupportedMainnetAddChainParams : SupportedTestnetAddChainParams;
  }

  const isChainIdSupported = (chainId: number): boolean => {
    const netwrokType = getNetworkType(chainId);
    if (!netwrokType) return false;
    const supportedChainIdMap = getSupportedChainIdMap(netwrokType);
    return Object.values(supportedChainIdMap).includes(chainId);
  }

  const getChainAddParamas = (chainId: number): IAddChainParams => {
    const netwrokType = getNetworkType(chainId);
    if (!netwrokType) throw new Error('ChainId not supported');
    const supportedAddChainParamsMap = getSupportedAddChainParamsMap(netwrokType);
    for (const addChainParams of Object.values(supportedAddChainParamsMap)) {
      if (addChainParams.chainId === chainId) return addChainParams;
    }

    throw new Error('Unsupported ChainId')
  }

  return {
    getNetworkType,
    getSupportedChainIdMap,
    isChainIdSupported,
    getChainAddParamas,
  }
}
