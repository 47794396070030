import Access from "assets/how/access.png"
import Cred from "assets/how/cred.png"
import Impact from "assets/how/impact.png"
import neutrality from "assets/how/neutrality.png"
import Personalize from "assets/how/personalize.png"
import PickProject from "assets/how/pick-project.png"
import { FC } from "react"

const WhatYouGet: FC = () => {
  return (
    <div className="flex flex-col mt-[180px] mr-6 ml-6 border-4 border-[#FFAFF2] rounded-3xl p-12 gap-16 items-center">
      <h1 className="text-white text-3xl font-semibold w-full text-center">What you get from CRED</h1>
      <div className="flex flex-wrap gap-8 justify-between w-[776px]">
        <div className="flex items-center gap-5">
          <img src={neutrality} alt="carbon" width="70px" />
          <div className="w-[240px]">
            <p className="text-[#30E1D6] width-[243px]">Carbon neutrality from high quality carbon offset projects</p>
          </div>
        </div>

        <div className="flex items-center gap-5">
          <img src={Personalize} alt="carbon" width="70px" />
          <div className="w-[240px]">
            <p className="text-[#30E1D6] width-[243px]">Personalized carbon profile (Coming Soon)</p>
          </div>
        </div>

        <div className="flex items-center gap-5">
          <img src={Access} alt="carbon" width="70px" />
          <div className="w-[240px]">
            <p className="text-[#30E1D6] width-[243px]">Access to exclusive NFTs</p>
          </div>
        </div>

        <div className="flex items-center gap-5">
          <img src={Cred} alt="carbon" width="70px" />
          <div className="w-[240px]">
            <p className="text-[#30E1D6] width-[243px]">CRED staking rewards (Coming Soon)</p>
          </div>
        </div>

        <div className="flex items-center gap-5">
          <img src={Impact} alt="carbon" width="70px" />
          <div className="w-[240px]">
            <p className="text-[#30E1D6] width-[243px]">Special ImpactNFT community status / badges</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatYouGet
