import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

declare global {
  interface Window {
    _scq: any;
  }
}
export const Subscribe: FC = () => {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState(false);

  const submit = async () => {
    const emailTest =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailTest.test(email)) {
      setMsg('');
      const sendxURL = new URL('https://app.sendx.io/api/v1/js/identify');
      sendxURL.searchParams.append('teamId', '4xkdf7fgrB4N9yNHg1ZjJm');
      const contact = {
        email,
        customFields: { Contact: 'CRED Protocol Newsletter subscribers' },
        tags: ['Cred', 'CRED Protocol Newsletter subscribers'],
        encryptedTeamId: '4xkdf7fgrB4N9yNHg1ZjJm',
      };
      sendxURL.searchParams.append('contact', JSON.stringify(contact));
      fetch(sendxURL.href)
        .then(async () => {
          setMsg('Thank you for joining our mailing list');
          setSuccess(true);
          window.localStorage.setItem('_sendX', JSON.stringify(1));
        })
        .catch(() => {
          setMsg('An error occurred try again later');
        });
    } else {
      setMsg('Not valid email');
    }
  };

  useEffect(() => {
    setSuccess(Boolean(window.localStorage.getItem('_sendX')));
  }, []);

  return (
    <Box paddingY="30px">
      {!success && (
        <Box>
          <div className='pt-40 pb-20 space-y-6 text-white text-center text-2xl md:text-4xl'>
            <p>Want to hear more from us?</p>
            <p>Sign up to our newsletter.</p>
          </div>
          <div className='flex justify-around	gap-6 flex-wrap px-6'>
            <div className='flex w-full md:w-3/5 h-16 bg-white overflow-hidden rounded-tr-2xl rounded-br-2xl'>
              <input
                type="email"
                style={{
                  width: '100%',
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: '22px',
                  paddingLeft: '20px',
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <button className='
              text-white
              text-xl
              h-full
              font-bold
              rounded-none
              py-0
              px-10
              rounded-tr-lg
              rounded-br-lg
              border-transparent
              relative
              cursor-pointer
              transition-all
              bg-[#00DD9B]
              z-10
              hover:bg-[#41aea8]'
                onClick={submit as () => void}>Subscribe
              </button>
            </div>
          </div>
        </Box>
      )}
      <p className='pt-8 text-center font-overpass text-red-400 text-2xl'>{msg}</p>
    </Box>
  );
};
