import { FC, useMemo } from 'react';
import { styled, Link, Box, Typography } from '@mui/material';
import { localize } from 'utils/i18n';
import utils from 'web3-utils';
import logo from 'assets/logos/logo.png';
import { SdgGrid } from 'components/SdgGrid';
import { useResponsive } from 'hooks/useResponsive';
import { useContentContext } from 'providers/ContentProvider';
import { Project } from 'typings/project';
import { SearchResultCred } from 'typings/search-result';

const { toBN, numberToHex } = utils;

const OpenSeaImage = styled('img')`
  width: 25px;
  padding: 0;
  margin: 0;
`;

const StyledSdgGrid = styled(SdgGrid)`
  grid-template-columns: auto;
  grid-auto-flow: column;
  justify-content: left;

  & img {
    height: 50px;
    width: auto;
    object-fit: contain;
  }
`;

const StyledProjectCard = styled(Box)`
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;

  margin-bottom: 20px;
`;

interface ProjectCardProps {
  projectID: string;
  kilos: string;
  openseaUrl: string;
}

const ProjectCard: FC<ProjectCardProps> = ({
  projectID,
  kilos,
  openseaUrl,
}) => {
  const { isFulfilled: isDesktop } = useResponsive();
  const { projects, sdgGoals } = useContentContext();

  const project = useMemo(() => {
    return projects.find((project) => {
      const hexProjectId = numberToHex(toBN(projectID));
      return project.cred?.projectId === hexProjectId;
    })
  }, [projectID, projects]);

  const selectedSdgGoals = useMemo(() => {
    if (!project) return [];
    return sdgGoals.filter(sdgGoal => project.content.sdg.includes(sdgGoal.id))
  }, [project, sdgGoals]);

  function credReward(kilos: number, project: Project) {
    const rewardPerTonne = Number(project?.cred?.rewardPerTonne);
    const reward = rewardPerTonne ? (rewardPerTonne * kilos) / 1000 : 0;
    return localize(reward, 0, 3);
  }

  return (
    project === undefined ? null : (
      <StyledProjectCard>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="20px"
        >
          <Link
            href={project?.link}
            target="_blank"
            rel="noopener"
            fontSize="18px"
          >
            <Typography variant="s3">{project ? `${project.id}: ${project.title}` : '(Unknown project)'}</Typography>
          </Link>
          {openseaUrl !== '' &&
            <Link href={openseaUrl} target="_blank" rel="noopener" display="flex">
              <Typography marginRight="5px">On chain Receipt</Typography>
              <OpenSeaImage
                src="https://opensea.io/static/images/logos/opensea.svg"
                title="View on OpenSea"
              />
            </Link>
          }
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          {!isDesktop && <StyledSdgGrid sdgGoals={selectedSdgGoals} />}

          {isDesktop && (
            <Box>
              <Typography color="ocean.main" variant="s3" mb="10px">
                Supported SDGs
              </Typography>
              <div style={{ display: 'flex' }}>
                <StyledSdgGrid sdgGoals={selectedSdgGoals.length < 8 ? selectedSdgGoals : selectedSdgGoals.slice(0, 8)} />
                {selectedSdgGoals.length > 8 && <Typography color="ocean.main" variant="s3" mt="12px" ml="10px">
                  and {selectedSdgGoals.length - 8} others
                </Typography>}
              </div>
            </Box>
          )}
          <Box>
            <Typography align="right">
              {localize(Number(kilos) / 1000, 1, 3)} Tonnes
            </Typography>
            <Box display="flex">
              <img src={logo} alt="cred logo" width="20px" />
              <Typography
                align="right"
                marginLeft="10px"
                color="ocean.main"
                variant="s3"
              >
                {credReward(Number(kilos), project)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </StyledProjectCard >
    )
  );
};

interface SupportedProjectsProps {
  results: SearchResultCred[];
}

export const SupportedProjects: FC<SupportedProjectsProps> = ({ results }) => {
  const filteredProjects = useMemo<SearchResultCred[]>(() => {
    const _projects = results
      .reduce((map, current) => {
        const grouped = map.get(current.projectID);
        // eslint-disable-next-line no-negated-condition
        if (!grouped) {
          map.set(current.projectID, { ...current });
        } else {
          map.set(current.projectID, {
            ...grouped,
            kilos: (Number(grouped.kilos) + Number(current.kilos)).toString(),
          });
        }

        return map;
      }, new Map<string, SearchResultCred>())
      .values();
    const _list = Array.from(_projects)
    return _list
  }, [results])

  if (results.length === 0) {
    return null;
  }

  return (
    <Box>
      {filteredProjects.map((project) => (
        <ProjectCard key={project.projectID} {...project} />
      ))}
    </Box>
  );
};
