import { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import { FaceOutlined } from '@mui/icons-material';
import {
  Typography, Select, MenuItem, SelectChangeEvent, Grid
} from '@mui/material';
import { InternalLink } from 'components/InternalLink';
import { StyledButton } from 'components/StyledButton';
import { useResponsive } from 'hooks/useResponsive';
import { useTruncate } from 'hooks/useTruncate';
import { routes } from 'pages/routes';
import { MobileAppBar } from './Mobile';
import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import logo from 'assets/logos/logo.png';
import { useEthereumContext } from 'providers/EthereumProvider';
import { RootState } from '../../store/type';
import { AppActionTypes } from '../../store/App/type';
import { CHAIN_TYPE, CURRUNCY_INFO, CURRUNCY_TYPE } from '../../constants';
import { connect } from 'react-redux';
import { navItems } from './nav-items';
import { useLocation } from 'react-router';

interface AppBarProps {
  chainId?: SupportedChainIdsV4
  accountAddress?: string
  isConnected: boolean
  connectWallet: () => void
  selectedCurruncy: CURRUNCY_TYPE,
  updateSelectedCurrency: (value: CURRUNCY_TYPE) => void
}

const AppBar: FC<AppBarProps> = ({ isConnected, chainId, accountAddress, connectWallet, selectedCurruncy, updateSelectedCurrency }) => {
  const { pathname } = useLocation();
  const { chains, switchChain } = useEthereumContext()
  const { isUnfulfilled: isMobile } = useResponsive((theme) => theme.breakpoints.up('md'));
  if (window.ethereum) {
    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });
  }

  const { truncateWalletAddress } = useTruncate()
  const truncatedAddress = useMemo(() => truncateWalletAddress(accountAddress), [accountAddress, truncateWalletAddress]);

  const [toSwitch, setToSwitch] = useState<boolean>(false);

  const handleChainChange = async ({ target: { value } }: SelectChangeEvent<SupportedChainIdsV4>) => {
    console.log("value", value, CURRUNCY_INFO[selectedCurruncy].chain)
    if (Number(value) !== CURRUNCY_INFO[selectedCurruncy].chain) {
      if (Number(value) === CHAIN_TYPE.CELO_MAINNET) {
        updateSelectedCurrency(CURRUNCY_TYPE.CELO_MAINNET_USDC)
      } else if (Number(value) === CHAIN_TYPE.POLYGON_MAINNET) {
        updateSelectedCurrency(CURRUNCY_TYPE.POLYGON_MAINNET_USDC)
      }
    }

    await switchChain(value as SupportedChainIdsV4)
  };

  useEffect(() => {
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    if (networkType === 'mainnet') {
      if (!(chainId === 137 || chainId === 42220)) {
        setToSwitch(true);
      }
    }
  }, [setToSwitch, chainId]);

  if (isMobile) {
    return <MobileAppBar isCorrect={toSwitch} />;
  }

  return (
    <nav className="bg-white border-gray-200">
      <div className="flex items-center justify-between mx-auto p-4">
        <InternalLink to={routes.home} className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-10" alt="cred logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:ocean.dark">CRED</span>
        </InternalLink>
        <div className="flex w-full justify-end md:w-[86%]" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 items-center md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            {navItems.map(({ name, path }) => (
              <li key={name}>
                <InternalLink to={path}>
                  <Typography
                    color={pathname === path ? 'teal.main' : '#3E3E3E'}
                    variant="s3"
                  >
                    {name}
                  </Typography>
                </InternalLink>
              </li>
            ))}
            <Select
              sx={{
                border: 0,
                borderBlock: 0,
                borderColor: 'transparent',
                m: 1,
                minWidth: 100
              }}
              size="small"
              value={chainId}
              onChange={handleChainChange}
            >
              {chains.map((chain) => (
                <MenuItem
                  key={chain.value}
                  value={chain.value}>
                  <div style={{
                    width: '170px'
                  }}>
                    <Grid container spacing={0.5}>
                      <Grid item marginTop={0.5} xs={2}>
                        <img src={chain.logo} alt={chain.label} />
                      </Grid>
                      <Grid item marginTop={0.5} xs={3}>
                        {chain.label}
                      </Grid>
                    </Grid>
                  </div>
                </MenuItem>
              ))}
            </Select>
            {isConnected
              ? <Typography marginLeft="10px">{truncatedAddress}</Typography>
              : (
                <StyledButton sx={{ margin: 0 }} onClick={connectWallet}>
                  Connect to Wallet
                </StyledButton>
              )}
            <InternalLink
              to={routes.profile('supported-projects')}
              marginLeft="10px"
            >
              <FaceOutlined sx={{ color: '#3E3E3E', width: '30px !important' }} />
            </InternalLink>
          </ul>
        </div>
      </div>
      {
        toSwitch &&
        <div style={{ alignItems: 'center', background: 'rgb(242, 153, 74)', justifyContent: 'center', display: 'flex' }}>
          <Typography>Unfortunately your wallet network is not suppported on our website yet. Please switch to Polygon or Celo mainnet</Typography>
        </div>
      }
    </nav >
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedCurruncy: state.app.selectedCurruncy,
  }
}

function mapDispatchToProps(dispatch: Dispatch<{ type: AppActionTypes, value: string }>) {
  return {
    updateSelectedCurrency: (value: CURRUNCY_TYPE) => {
      dispatch({ type: AppActionTypes.UPDATE_SELECTED_CURRUNCY, value })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
