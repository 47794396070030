import { FC, useMemo } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {
  CheckCircle,
  Close,
  DoneAllRounded,
  Error as ErrorIcon,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  styled,
  useTheme,
  IconButton,
} from '@mui/material';
import { ContractReceipt } from 'ethers';
import { TransactionReceipt } from 'web3-core';
import { routes } from 'pages/routes';
import { InternalLink } from './InternalLink';

const StyledDialogTitle = styled(DialogTitle)`
display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledDialogContent = styled(DialogContent)`
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(1)} !important;
  }
`;

const SpinnerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: ${({ theme }) => theme.spacing(0, 4, 1)};
  text-align: center;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(1)} !important;
  }
`;

const ChecklistItem = styled('div')`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
`;

const PostTransactionButtons = styled(DialogActions)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${({ theme }) => theme.spacing(0, 1.5)};

  padding: ${({ theme }) => theme.spacing(1, 3, 2)};
`;

const ChecklistItemIcon = styled(CheckCircle)`
  transition: ${({ theme }) => theme.transitions.create('color')};
`;

export type StatusItem = {
  name: string;
  status: 'pending' | 'success' | 'error';
};

interface StatusDialogProps extends Pick<DialogProps, 'open' | 'onClose'> {
  title: string;
  showInProfileButton?: boolean;
  errorText: string | null;
  statusItems: StatusItem[];
  contractAddress?: string;
  tokenId?: string;
  transactionReceipt?: TransactionReceipt | ContractReceipt | null; // interim mix until we move to ethers entirely
}

export const StatusDialog: FC<StatusDialogProps> = ({
  title,
  showInProfileButton,
  open,
  onClose,
  errorText,
  statusItems,
  transactionReceipt,
  ..._props
}) => {

  const theme = useTheme();

  const isComplete = useMemo(
    () => statusItems.every((item) => item.status === 'success'),
    [statusItems],
  );

  const isError = useMemo(
    () => statusItems.some((item) => item.status === 'error'),
    [statusItems],
  );

  const isInProgress = !isComplete && !isError;

  const transactionSuccessUrl = useMemo(() => {
    const searchParams = (() => {
      if (!transactionReceipt) {
        return '';
      }

      const params = new URLSearchParams();
      const omitKeys = ['logs', 'logsBloom', 'events'];

      Object.entries(transactionReceipt).forEach(([key, value]) => {
        if (!omitKeys.includes(key)) {
          params.append(key, value);
        }
      });

      params.sort();
      return params.toString();
    })();

    return [routes.transactionSuccessful, searchParams].join('?');
  }, [transactionReceipt]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} {..._props}>
      <StyledDialogTitle>
        <Typography variant="inherit">{title}</Typography>

        {!isInProgress && (
          <IconButton onClick={onClose as () => void}>
            <Close />
          </IconButton>
        )}
      </StyledDialogTitle>

      <SpinnerContainer>
        {isInProgress && <BeatLoader color={theme.palette.ocean.main} />}
        {isComplete && <DoneAllRounded color="success" />}
        {isError && <ErrorIcon color="error" />}
        {errorText && (
          <Typography color="error" variant="caption">
            {errorText}
          </Typography>
        )}
      </SpinnerContainer>

      <StyledDialogContent>
        {statusItems.map(({ name, status }) => (
          <ChecklistItem key={name}>
            <Typography color="text.secondary" variant="s3">
              {name}
            </Typography>
            {status === 'pending' && <ChecklistItemIcon color="disabled" />}
            {status === 'success' && <ChecklistItemIcon color="success" />}
            {status === 'error' && <ErrorIcon color="error" />}
          </ChecklistItem>
        ))}
      </StyledDialogContent>

      <PostTransactionButtons>
        {showInProfileButton && (
          <InternalLink to={routes.profile('my-nfts')}>
            <Button
              fullWidth
              color="ocean"
              disabled={!transactionReceipt}
              variant="contained"
              href={routes.profile('my-nfts')}
              target="_blank"
              rel="noopener noreferrer"
            >
              View in Profile
            </Button>
          </InternalLink>
        )}
        {typeof transactionReceipt !== 'undefined' && (
          <InternalLink color="inherit" to={transactionSuccessUrl}>
            <Button fullWidth color="ocean" variant="contained">
              View Transaction
            </Button>
          </InternalLink>
        )}
      </PostTransactionButtons>
    </Dialog>
  );
};
