import { Typography } from "@mui/material";
import { FC } from "react";


interface ProjectContentSectionProps {
  heading: string;
  content: string | undefined;
}
export const ProjectContentSection: FC<ProjectContentSectionProps> = ({
  heading,
  content,
}) => {
  if (content === undefined) {
    return null;
  }

  return (
    <section>
      <Typography variant="s1" color="ocean.main" fontWeight="bold">
        {heading}
      </Typography>
      <Typography sx={{ mt: 2 }} color="neutralGray.dark" variant="body1">
        {content}
      </Typography>
    </section>
  );
};
