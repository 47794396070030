import { FC, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  styled,
  Typography,
} from '@mui/material';
import logo from 'assets/logos/logo.png';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
`;

const StyledCardMedia = styled(CardMedia)`
  aspect-ratio: 100 / 110;
  height: 75%;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1 1 auto;
  flex-direction: column;
  height: auto;
`;

interface nft {
  nftImage: string;
  price: { cred: number };
  content: { title: string; description: string };
}

interface NftCardProps extends Pick<nft, 'nftImage' | 'price' | 'content'> {
  className?: string;
  delay?: number;
}

export const NftCard: FC<NftCardProps> = ({
  className,
  delay,
  nftImage,
  price,
  content,
}) => {
  const [bgShadow, setbg] = useState('');
  const [fetchingImage, setFetchingImage] = useState(
    Boolean(nftImage && delay),
  );

  useEffect(() => {
    if (nftImage && delay) {
      setTimeout(setFetchingImage, delay, false);
    }
  }, [delay, fetchingImage, nftImage, setFetchingImage]);

  return (
    <div
      style={{ boxShadow: `${bgShadow}` }}
      onMouseEnter={() => {
        setbg('0 4px 8px 0 rgba(0, 0, 0, 0.5)');
      }}
      onMouseLeave={() => {
        setbg('');
      }}
    >
      <StyledCard className={className}>
        <StyledCardMedia image={nftImage} />
        <StyledCardContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="s3">{content.title}</Typography>
            {price.cred ? (
              <Typography variant="s3">
                <img
                  style={{
                    width: 22,
                    height: 22,
                    verticalAlign: 'middle',
                  }}
                  alt="cred logo"
                  src={logo}
                />{' '}
                {price.cred ? `${price.cred} ` : 'N/A'}
              </Typography>
            ) : (
              <Typography variant="s3" color="#aaa5a5">
                Sold Out
              </Typography>
            )}
          </div>
        </StyledCardContent>
      </StyledCard>
    </div>
  );
};
