import { FC } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import logo from 'assets/logos/logo.png';
import BN from 'bn.js';
import { routes } from 'pages/routes';
import { Listing } from 'typings/listings';
import { fromWei } from 'web3-utils';
import { InternalLink } from './InternalLink';

interface ListItemProps {
  MINIMUM_QUANTITY: number;
  isPurchasing: boolean;
  listing: Listing;
  purchaseNfts: (listing: Listing) => Promise<void>;
  quantity: number;
  setQuantity: (newQuantity: number) => void;
}

function formatEther(wei: string | BN) {
  return fromWei(wei);
}

export const ListItem: FC<ListItemProps> = ({
  MINIMUM_QUANTITY,
  isPurchasing,
  purchaseNfts,
  listing,
  quantity,
  setQuantity
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
  };
  return (
    <Stack direction="row" spacing={1} style={{ display: 'flex', backgroundColor: 'white' }}>
      <Typography
        color="darkGrey.main"
        variant="monospace"
        style={{ flex: 2, textAlign: 'center' }}
      >
        <img
          style={{
            width: 22,
            height: 22,
            verticalAlign: 'middle',
          }}
          alt="cred logo"
          src={logo}
        />{' '}
        {formatEther(listing.buyoutPrice.toString())}
      </Typography>
      <Typography
        color="darkGrey.main"
        variant="monospace"
        style={{ flex: 2, textAlign: 'center' }}
      >
        <InternalLink to={`${routes.search}?address=${listing.sellerAddress}`}>
          {listing.sellerAddress.substring(0, 6)}...
        </InternalLink>
      </Typography>
      <div style={{ flex: 2, textAlign: 'center' }}>
        <TextField
          fullWidth
          // disabled={
          //   Boolean(listing) // quantity is set by the sell order
          // }
          color="ocean"
          type="number"
          value={quantity}
          onChange={handleInputChange}
          InputProps={{
            inputProps: {
              min: MINIMUM_QUANTITY,
            },
          }}
        />
      </div>
      <div style={{ flex: 2, textAlign: 'center' }}>
        <Button
          disabled={
            parseInt(listing.quantity.toString()) < MINIMUM_QUANTITY ||
            !listing.assetContractAddress ||
            isPurchasing
          }
          variant="contained"
          color="ocean"
          onClick={async () => {
            console.log('Purchase this NFT!');
            purchaseNfts(listing);
          }}
        >
          Buy Now
        </Button>
        <p>{Number(listing.quantity)} left!</p>
      </div>
    </Stack>
  );
};
