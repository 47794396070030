import { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { toBN, fromWei } from 'web3-utils';
import { useQuery } from 'react-query';

import useCredNft from 'hooks/nft';

import { routes } from 'pages/routes';
import { useEthereumContext } from 'providers/EthereumProvider';
import { ProfileLeftSide } from 'components/Profile/ProfileLeftSide';
import { ProfileRightSide } from 'components/Profile/ProfileRightSide';
import { StyledButton } from 'components/StyledButton';
import { BigProfileCard } from 'components/Profile/BigProfileCard';
import type { SearchResultCred } from 'typings/search-result';
import { Stack } from '@mui/system';
import { useContentContext } from 'providers/ContentProvider';
import { BeatLoader } from 'react-spinners';


export const Profile: FC = () => {
  const { slug } = useParams<'slug'>();
  const { enqueueSnackbar } = useSnackbar();
  const { nftCollections } = useContentContext();
  const { getNftListByOwner } = useCredNft()
  const {
    isReady,
    accountAddress,
    connectWithMetaMask,
    isConnected,
    getAlchemyCredContract,
    getAlchemyCredRegistryContract,
    chainId
  } = useEthereumContext();

  const handleError = useCallback((e: unknown) => {
    console.error(e);
    enqueueSnackbar(`Request failed. ${(e as Error)?.message}`, { variant: 'error' });
  }, [enqueueSnackbar]);

  const getTokenAmount = async () => {
    const numCredInWei: string = (await getAlchemyCredContract().methods
      .balanceOf(accountAddress)
      .call()) as string;
    return fromWei(numCredInWei, 'ether');
  }

  const queryOwnedOffsets = async (_chainId: number, _ownerAddress: string) => {
    const registryContract = getAlchemyCredRegistryContract();
    /* eslint-disable new-cap */
    const OFFSET_TYPE = toBN(await registryContract.methods.OFFSET().call());

    const numOffsets = Number(
      await registryContract.methods.numOffsets().call(),
    );
    const addresses: string[] = [];
    const ids: string[] = [];
    for (let i = 1; i <= numOffsets; ++i) {
      addresses.push(_ownerAddress);
      ids.push(OFFSET_TYPE.add(toBN(i)).toString());
    }

    const balances = (
      await registryContract.methods.balanceOfBatch(addresses, ids).call()
    ).map(Number);
    const owned = ids.filter((_, i) => balances[i] > 0);

    const getOffset = async (tokenId: string) => {
      const offset = await registryContract.methods
        .getOffset(tokenId)
        .call()

      const searchResult: SearchResultCred = {
        ...offset,
        tokenID: tokenId,
        openseaUrl: routes.openseaAssetUrl(
          registryContract.options.address,
          tokenId,
          _chainId,
        ),
      };
      return searchResult;
    }

    const searchResults = await Promise.all(owned.map(getOffset));
    return searchResults;
  }

  const tokenAmount = useQuery(
    ['token-amount', accountAddress], getTokenAmount, {
    enabled: isReady && !!accountAddress,
    refetchOnWindowFocus: false,
    cacheTime: 300000,
    onError: handleError,
  })

  const userNFTs = useQuery(
    ['user-nfts', accountAddress, ], () => getNftListByOwner(accountAddress!, nftCollections.map((col) => col.contractAddress.toLowerCase())), {
    enabled: isReady && !!accountAddress && nftCollections.length > 0,
    refetchOnWindowFocus: false,
    cacheTime: 300000,
    onError: handleError,
  })

  const ownedOffsets = useQuery(
    ['owned-offsets', chainId, accountAddress], () => queryOwnedOffsets(chainId, accountAddress!), {
    enabled: isReady && !!accountAddress && !!chainId,
    refetchOnWindowFocus: false,
    cacheTime: 300000,
    onError: handleError,
  })

  return (
    <>
      <Helmet>
        <title>CRED | Profile</title>
      </Helmet>
      {accountAddress ?
        isConnected && tokenAmount.data && ownedOffsets.data ? (
        <Container>
          <Stack direction='column' p={3} spacing={5}>
            <BigProfileCard tokenAmount={tokenAmount.data} />
            <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2}>
              <ProfileLeftSide supportedProjects={ownedOffsets.data} />
              {userNFTs.isError && <div>Failed to load NFTs</div>}
              <ProfileRightSide
                loading={userNFTs.isLoading || ownedOffsets.isLoading}
                nfts={userNFTs.data!}
                supportedProjects={ownedOffsets.data}
                tab={slug === 'my-nfts' ? 1 : 0}
              />
            </Stack>
          </Stack>
        </Container>
      ) : (
        <Box sx={{
          width: '100%', height: '200px',
          justifyContent: 'center', alignItems: 'center', display: 'flex'
        }}>
        <BeatLoader />
        </Box>
      ) : (
      <Box sx={{
        width: '100%', height: '200px', backgroundColor: '#86EDE7',
        justifyContent: 'center', alignItems: 'center', display: 'flex'
      }}>
        <StyledButton onClick={connectWithMetaMask}>Connect to Wallet</StyledButton>
      </Box>
      )}
    </>
  );
};
