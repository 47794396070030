import { FC } from 'react';
import { styled, Button, ButtonProps } from '@mui/material';

const StyledButtonStyle = styled(Button)`
  color: white;
  padding: ${(props) => (props.size ? '10px 30px' : '10px 40px;')};
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  border: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all ease-out 0.8s;
  background: #00DD9B;
  z-index: 10;
  &:before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: -2%;
    bottom: -20%;
    border-radius: 50%;
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #00DD9B;
    transfor-morigin: left;
    transition: width 0.25s ease;
    z-index: -1;
  }
  &:hover {
    background: ${(props) =>
      props.color === 'secondary' ? '#85ede6' : '#00DD9B'};
  }
`;

interface StyledButtonProps extends ButtonProps {}

export const StyledButton: FC<StyledButtonProps> = ({ children, ...props }) => {
  return <StyledButtonStyle {...props}>{children}</StyledButtonStyle>;
};
