import { useMemo } from "react";

import { ICredNftFacade } from "./types";
import { useCredApi } from "./useCredApi";


export const useCredNft = (): ICredNftFacade => {
  // const { chainId } = useEthereumContext()
  const credApiInterface = useCredApi()
  // const alchemyInterface = useAlchemy()

  // Since we are reading the same type of data from Celo and Polygon,
  // we can use the same interface for both.
  return useMemo<ICredNftFacade>(() => credApiInterface, [credApiInterface])
}
