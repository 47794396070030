export enum ContractType {
  ERC721,
  ERC1155,
}


export interface Nft {
  contractAddress?: string;
  id: string;
}

export interface NFTMetadata {
  name: string;
  description: string;
  external_url?: string;
  image?: string;
  image_url?: string;
}

export interface NFTInfo {
  id: string;
  contractType: ContractType;
  contractAddress: string;
  title: string;
  description: string;
  image: string;
  metadata: NFTMetadata;
  animationUrl: string;
}

export interface NftCollection {
  slug: string;
  title: string;
  description: string;
  images: [string, ...string[]]; // At least one image
  chainId: number;
  contractType: ContractType;
  contractAddress: string;
  nfts: NFTInfo[];
  metadata: NFTMetadata
}

export interface NFTCollections {
  collections: NftCollection[],
  totalData: number
}

