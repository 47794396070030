import { useCallback, useMemo } from "react";

import { NftCollection } from 'typings/nfts';
import { Project } from "typings/project";

import { useEthereumContext } from "providers/EthereumProvider";
import { CredApiResponsePayload, ICredNftFacade, NftItemPayload, TatumNftsByContractPayload } from "./types";
import { convertProject } from "utils/projects";
import { MinimalNftItemPayload } from "typings/nft";


export const useCredApi = (): ICredNftFacade => {
  const { chainId } = useEthereumContext()
  const baseUrl = useMemo(() => process.env.REACT_APP_CRED_API, [])

  const fetchApiJson = useCallback(async <T = any>(routePath: string, options: RequestInit = {}): Promise<T> => {
    const baseOptions: RequestInit = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
    const url = `${baseUrl}/${routePath}`

    const response = await fetch(url, { ...baseOptions, ...options })

    return (await response.json()) as T
  }, [baseUrl])

  return useMemo<ICredNftFacade>(() => {
    return {
      // Present in both
      getProjects: async (): Promise<Project[]> => {
        const url = `projects?debug=true&chainId=${chainId}`
        const response = await fetchApiJson<{ projects: Project[] }>(url);
        return response.projects?.map(convertProject)
      },
      getProject: async (projectId): Promise<Project> => {
        const url = `project/${projectId}?chainId=${chainId}`
        const response = await fetchApiJson<{ project: Project }>(url);
        return convertProject(response.project)
      },
      getNftCollections: async (pageNo: number, limit: number) => {
        const routePath = `marketplace/collections/${chainId}?pageNo=${pageNo}&limit=${limit}`
        const response = await fetchApiJson<{ collections: NftCollection[], totalData: number }>(routePath);
        return response
      },
      getNftCollection: async (slug) => {
        const routePath = `marketplace/collection/${chainId}/${slug}`
        const response = await fetchApiJson<{ collection: NftCollection }>(routePath);
        return response.collection
      },
      getNftListByContract: async (contract: string) => {
        const routePath = `nft/list/${chainId}/contract/${contract}?pageSize=10`
        const response = await fetchApiJson<CredApiResponsePayload<TatumNftsByContractPayload>>(routePath)

        const nftList: NftItemPayload[] = []
        for (const tokenPayload of response.data) {
          const nftItem: NftItemPayload = {
            ...tokenPayload.metadata,
            contractAddress: contract
          }
          nftList.push(nftItem)
        }

        return nftList
      },
      getNftListByOwner: async (owner, contracts) => {
        const qs = new URLSearchParams({ contracts: contracts.join(',') }).toString();
        const routePath = `nft/list/${chainId}/owner/${owner}?${qs}`
        const response = await fetchApiJson<{ data: NftItemPayload[] }>(routePath)
        return response.data
      },
      getNftMetadata: async (contract, token) => {
        const routePath = `nft/metadata/${chainId}/${contract}/${token}`
        const response = await fetchApiJson<MinimalNftItemPayload>(routePath);
        return response
      },
      getBatchNftsMetadata: async (contract, tokenIds) => {
        const qs = new URLSearchParams({ tokenIds: tokenIds.join(',') }).toString();
        const routePath = `nft/batch/metadata/${chainId}/${contract}?${qs}`
        const response = await fetchApiJson<{ metadataList: MinimalNftItemPayload[] }>(routePath);
        return response.metadataList
      }
    }
  }, [chainId, fetchApiJson])
}
