import { FC } from 'react';
import imgUtilityBG from 'assets/home/utility-cred/utility-bg.png';
import imgUtilityOne from 'assets/home/utility-cred/utility-1.png';
import imgUtilityTwo from 'assets/home/utility-cred/utility-2.png';
import imgUtilityThree from 'assets/home/utility-cred/utility-3.png';
import imgUtilityFour from 'assets/home/utility-cred/utility-4.png';
import imgUtilityFive from 'assets/home/utility-cred/utility-5.png';

export const UtilityCred: FC = () => {
    return (
        <div className='z-0 relative'>

            <div className='absolute my-16 top-10 w-full z-10'>
                <p className='text-white text-center font-bold text-5xl'>The utility of <span className='text-[#4FF037]'>CRED</span> </p>
            </div>

            <img className='object-cover h-[2530px] md:h-[1750px] w-full' src={imgUtilityBG} alt="UtilityCred" />
            <div className='flex justify-center'>
                <div className="grid grid-cols-1 md:grid-cols-2 my-12 gap-12 absolute top-44 min-w-[55rem] ">

                    <div className='flex justify-center'>
                        <div className='sm:scale-100 sm:ease-in-out sm:duration-300 sm:hover:scale-105'
                            style={{
                                clipPath: 'polygon(10% 0, 100% 0, 100% 90%, 88% 100%, 0 100%, 0 11%)'
                            }}
                        >
                            <div className='w-96 h-full'>
                                <img src={imgUtilityOne} alt="Utility1" />
                                <div className='py-5 pl-6 pr-3 bg-white space-y-6 font-overpass'>
                                    <p className='text-2xl font-bold text-[#00C6B9]'>Climate Positive Currency</p>
                                    <p className=" text-black">CRED acts as the first climate-positive currency for the metaverse, promoting eco-friendly transactions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <div className='sm:scale-100 sm:ease-in-out sm:duration-300 sm:hover:scale-105'
                            style={{
                                clipPath: 'polygon(10% 0, 100% 0, 100% 90%, 88% 100%, 0 100%, 0 11%)'
                            }}
                        >
                            <div className='w-96 h-full'>
                                <img src={imgUtilityTwo} alt="Utility1" />
                                <div className='py-5 pl-6 pr-3 bg-white space-y-6 font-overpass'>
                                    <p className='text-2xl font-bold text-[#00C6B9]'>Climate Positive Currency</p>
                                    <p className=" text-black">CRED acts as the first climate-positive currency for the metaverse, promoting eco-friendly transactions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <div className='sm:scale-100 sm:ease-in-out sm:duration-300 sm:hover:scale-105'
                            style={{
                                clipPath: 'polygon(10% 0, 100% 0, 100% 90%, 88% 100%, 0 100%, 0 11%)'
                            }}
                        >
                            <div className='w-96 h-full'>
                                <img src={imgUtilityThree} alt="Utility1" />
                                <div className='py-5 pl-6 pr-3 bg-white space-y-6 font-overpass'>
                                    <p className='text-2xl font-bold text-[#00C6B9]'>Climate Positive Currency</p>
                                    <p className=" text-black">CRED acts as the first climate-positive currency for the metaverse, promoting eco-friendly transactions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <div className='sm:scale-100 sm:ease-in-out sm:duration-300 sm:hover:scale-105'
                            style={{
                                clipPath: 'polygon(10% 0, 100% 0, 100% 90%, 88% 100%, 0 100%, 0 11%)'
                            }}
                        >
                            <div className='w-96 h-full'>
                                <img src={imgUtilityFour} alt="Utility1" />
                                <div className='py-5 pl-6 pr-3 bg-white space-y-6 font-overpass'>
                                    <p className='text-2xl font-bold text-[#00C6B9]'>Climate Positive Currency</p>
                                    <p className=" text-black">CRED acts as the first climate-positive currency for the metaverse, promoting eco-friendly transactions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-1 md:col-span-2 flex justify-center'>
                        <div className='sm:scale-100 sm:ease-in-out sm:duration-300 sm:hover:scale-105'
                            style={{
                                clipPath: 'polygon(10% 0px, 100% 0px, 100% 88%, 92% 100%, 0px 100%, 0px 11%)'
                            }}
                        >
                            <div className='w-96 md:w-auto h-full'>
                                <img className='md:h-[17.5rem] w-full' src={imgUtilityFive} alt="Utility1" />
                                <div className='py-5 pl-6 pr-3 bg-white space-y-6 font-overpass'>
                                    <p className='text-2xl font-bold text-[#00C6B9]'>Exclusive Holder Benefits</p>
                                    <p className=" text-black">Owning NFTs from CRED’s marketplace offers various privileges, enhancing the value proposition for users. <br /> Limited access to events and exclusive perks could be granted to holders of CRED NFTs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pt-8 col-span-1 md:col-span-2 flex justify-center'>
                        <button className='bg-[#00DD9B] w-2/5 h-[60px] rounded-full text-white text-xl'>Earn CRED now</button>
                    </div>
                </div>
            </div>

        </div>
    );
};
