import { styled } from '@mui/material';

export const Main = styled('main')`
  position: relative;
  width: 100%;
  height: 100%;

  max-width: ${({ theme }) => theme.breakpoints.values.lg}px;
  padding: ${({ theme }) => theme.spacing(2, 3)} !important;
  margin: auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(6)} !important;
  }
`;
