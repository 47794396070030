import { FC, ReactNode } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';

interface ThemeProvidersProps {
  children: ReactNode;
}

export const ThemeProviders: FC<ThemeProvidersProps> = ({ children }) => {
  const fontFamily = [
    'Overpass',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');
  const { palette } = createTheme();
  const colors = {
    ocean: palette.augmentColor({
      color: { dark: '#148493', main: '#41AEA8' },
    }),
    teal: palette.augmentColor({ color: { main: '#4FF038' } }),
    mint: palette.augmentColor({ color: { main: '#A5FDC7' } }),
    error: palette.augmentColor({ color: { main: '#ED8484' } }),
    peach: palette.augmentColor({ color: { main: '#FFE3D7' } }),
    purpleDark: palette.augmentColor({ color: { main: '#14002C' } }),
    salmon: palette.augmentColor({ color: { main: '#FFB381' } }),

    darkGrey: palette.augmentColor({ color: { main: '#138494' } }),
    neutralGray: palette.augmentColor({ color: { main: '#888888' } }),
    lightGrey: palette.augmentColor({ color: { main: '#CCCCCC' } }),
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 940,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily,
      h1: {
        fontWeight: 'bold',
        fontSize: '35px',
        lineHeight: 1.2,
        color: colors.ocean.main,
      },
      h2: {
        fontWeight: 'normal',
        fontSize: '30px',
        lineHeight: 1.2,
      },
      h3: {
        fontWeight: 'bold',
        fontSize: '25px',
        lineHeight: 1.2,
      },
      s1: {
        fontFamily,
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: 1.2,
      },
      s2: {
        fontFamily,
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: 1.2,
      },
      s3: {
        fontFamily,
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: 1.2,
      },
      monospace: {
        fontFamily: '"Fira Mono", monospace',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: 1.2,
      },
    },
    palette: colors,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            borderRadius: '40px',
          },
          // outlined: {
          //   border: '4px solid currentColor !important',
          // },
        },
      },
      MuiTypography: {
        styleOverrides: {
          button: {
            fontWeight: 'bold',
          },
        },
        defaultProps: {
          variantMapping: {
            s1: 'p',
            s2: 'p',
            s3: 'p',
            monospace: 'p',
          },
        },
      },
    },
  });

  const responsiveTheme = responsiveFontSizes(theme, {
    variants: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'caption',
      'button',
      'overline',
      's1',
      's2',
      's3',
      'monospace',
    ] as unknown as Variant[],
  });

  return (
    <MuiThemeProvider theme={responsiveTheme}>{children}</MuiThemeProvider>
  );
};
