import { ComponentProps, FC } from 'react';
import { Box, Link, Typography, styled } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';

const UnorderedList = styled('ul')`
  list-style-type: initial;
`;

const MarkdownListItem: FC = (props) => {
  return (
    <Box
      component="li"
      sx={{ mt: 1, typography: 'body1', color: 'text.secondary' }}
      {...props}
    />
  );
};

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
        component: 'h1',
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h6', component: 'h2' },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'subtitle1' },
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true, color: 'text.secondary' },
    },
    a: {
      component: Link,
      props: {
        color: 'ocean.main',
        underline: 'none',
        target: '__blank',
        rel: 'noopener noreferrer',
      },
    },
    ul: {
      component: UnorderedList,
    },
    li: {
      component: MarkdownListItem,
    },
  },
};

type MarkdownProps = ComponentProps<typeof ReactMarkdown>;

export const Markdown = (props: MarkdownProps) => {
  return <ReactMarkdown options={options} {...props} />;
};
