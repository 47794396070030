import { Project } from "typings/project";

export function convertProject(p: any): Project {
  function str(a: any) {
    return a ? String(a) : '';
  }
  return {
    id: str(p.registryID),
    title: str(p.project_name),
    images: [str(p.project_image_url)],
    link: str(p.registry),
    pricePerTonne: ((ppt) => ({
      usdc: Number(ppt?.usdc),
      cusd: Number(ppt?.cusd),
      ceur: Number(ppt?.ceur),
    }))(p.cred?.pricePerTonne),
    content: {
      howItWorks: str(p.about),
      whyWeChoose: str(p.impact_summary),
      myImpact: str(p.project_impact),
      impactVerification: str(p.verification_party),
      sdg: p.impact_bullets_type as number[],
      project_location: str(p.project_location),
      partnershipIcons: [str(p.partnership_icons)],
      registryUrl: str(p.registry),
      organisation: str(p.verification_organisation),
    },
    cred: Object.keys(p.cred).length === 0
      ? undefined :
      {
        projectId: str(p.cred.project?.id),
        vintageId: str(p.cred.vintages[0]?.id),
        availableTonnes: Number(p.cred.vintages[0]?.availableTonnes),
        rewardPerTonne: str(p.cred.project?.rewardPerTonne),
      },
  };
}
