import {
  ChangeEvent,
  useState,
  useMemo,
  useCallback,
  useEffect,
  Dispatch,
} from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import CeloLogo from 'assets/logos/celo-logo.png';
import PolygonLogo from 'assets/logos/polygon-logo.png';
import {
  Button,
  TextField,
  Typography,
  Box,
  Avatar,
  MenuItem,
  FormControl,
  Select,
  Stack,
} from '@mui/material';
import { localize } from 'utils/i18n';
import logo from 'assets/logos/logo.png';
import { SdgGrid } from 'components/SdgGrid';
import { Main } from 'components/layouts/main';
import { useQueryParams } from 'hooks/useQueryParams';
import { useContentContext } from 'providers/ContentProvider';
import { Project } from 'typings/project';
import { INITIAL_OFFSET_TONNES, MINIMUM_OFFSET_TONNES } from 'utils/constants';
import { ContentFrame, Sections, Image, SdgLabelGroup, PurchaseFrame } from 'components/Styled';
import { ProjectContentSection } from 'components/ProjectContentSection';
import { CHAIN_TYPE, CURRUNCY, CURRUNCY_INFO, CURRUNCY_TYPE } from '../constants';
import { useEthereumContext } from '../providers/EthereumProvider';
import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import { RootState } from '../store/type';
import { connect } from "react-redux";
import { AppActionTypes } from '../store/App/type';
import { BeatLoader } from 'react-spinners';

type ProjectDetailPropsType = {
  selectedCurruncy: CURRUNCY_TYPE,
  updateSelectedCurrency: (value: CURRUNCY_TYPE) => void
}

const ProjectDetail = ({ selectedCurruncy, updateSelectedCurrency }: ProjectDetailPropsType) => {
  const { sdgGoals, getProject } = useContentContext();
  const { switchChain } = useEthereumContext()
  const { projectId: paramProjectId } = useParams<'projectId'>();
  const [projectId, setprojectId] = useState<string>();
  const { quantity: initialQuantity } = useQueryParams<{ quantity: string }>();
  const [project, setProject] = useState<Project | null | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [totalSupplyInTonne, setTotalSupplyInTonne] = useState<number | undefined>();
  const [isListed, setIsListed] = useState<boolean>(true);
  const [quantity, setQuantity] = useState(
    String(Number.parseFloat(initialQuantity) || INITIAL_OFFSET_TONNES),
  );
  const [showQuantity, setShowQuantity] = useState(
    String(Number.parseFloat(initialQuantity) || INITIAL_OFFSET_TONNES),
  );
  const [price, setPrice] = useState<string>();

  const navigate = useNavigate();


  useEffect(() => {
    if (paramProjectId !== undefined && paramProjectId !== projectId)
      setprojectId(paramProjectId)
  }, [paramProjectId])

  const selectedSdgGoals = useMemo(() => {
    if (!project) return [];
    return sdgGoals.filter(sdgGoal => project.content.sdg.includes(sdgGoal.id))
  }, [project, sdgGoals]);

  const registryIcon =
    project?.content.organisation === 'Verra'
      ? 'https://carbonbase-images.s3.amazonaws.com/static/projects/verra.png'
      : 'https://carbonbase-images.s3.amazonaws.com/static/projects/gs_globalgoals.png';

  const calculate = useCallback(
    (q: number) => {
      const numKilos = q ? Math.round(q * 1000) : 0;
      if (project?.pricePerTonne) {
        const perTonne =
          project.pricePerTonne[CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY];
        return (perTonne * numKilos) / 1000;
      }

      return NaN;
    },
    [selectedCurruncy, project],
  );

  const updatePrice = useCallback(
    (quantity: string) => {
      const price = calculate(parseFloat(quantity));
      setPrice(price.toFixed(2));
    },
    [calculate, setPrice],
  );

  useEffect(() => {
    updatePrice(quantity);
  }, [project, updatePrice]); // intentionally excludes quantity to avoid circular update when editing price

  const credReward = useMemo(() => {
    const rewardPerTonne = Number(project?.cred?.rewardPerTonne);
    const numKilos = parseFloat(quantity) * 1000;
    return (rewardPerTonne * numKilos) / 1000;
  }, [project, quantity]);

  const handlePriceToQuantity = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPrice(e.target.value);
      const p = Number.parseFloat(e.target.value);

      if (Number.isNaN(p)) {
        setQuantity('0');
      } else if (project?.pricePerTonne) {
        const q =
          p / project.pricePerTonne[CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY]
        setShowQuantity(String(Math.round(q * 1000) / 1000));
        setQuantity(String((q * 1000) / 1000));
      }
    },
    [selectedCurruncy, project, setPrice, setQuantity],
  );

  const handleQuantityToPrice = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (/[0-9]*/.test(e.target.value)) {
        setShowQuantity(e.target.value);
        setQuantity(e.target.value);
        updatePrice(e.target.value);
      } else {
        return false;
      }
    },
    [setQuantity, updatePrice],
  );

  const onCurruncyChange = async ({ target: { value } }: { target: { value: any } }) => {
    updateSelectedCurrency(value as CURRUNCY_TYPE)
    await switchChain((CURRUNCY_INFO[value as CURRUNCY_TYPE].chain) as unknown as SupportedChainIdsV4)
  }

  useEffect(() => {
    const loadProject = async () => {
      if (!projectId) return;
      const project = await getProject(projectId);
      setProject(project);
      if (!project?.cred) {
        setIsListed(false);
      }

      setError(project === null ? 'Unknown project' : undefined);
    }

    loadProject().catch(console.error);
  }, [getProject, projectId]);

  const getProjectCarbSupply = () => {
    if (!project?.cred) {
      return null;
    }

    const carbSupplyInTonnes = project.cred?.availableTonnes
    setTotalSupplyInTonne(carbSupplyInTonnes);

    return carbSupplyInTonnes;
  }

  useEffect(() => {
    getProjectCarbSupply()
  }, [project])

  if (!project || error) {
    return (
      <Main>
        <Helmet>
          <title>Project Ark | loading</title>
        </Helmet>
        <Typography variant="h2">{error}</Typography>
      </Main>
    );
  }

  return (
    <Main>
      <Helmet>
        <title>CRED | {project.title}</title>
      </Helmet>
      <ContentFrame>
        <Sections>
          <Typography variant="h1">
            {project.title} ({project.content.project_location})
          </Typography>
          <Image sx={{ mt: 3 }} src={project.images[0]} alt={project.title} />
          <SdgLabelGroup>
            <Typography color="ocean.main" variant="s1" fontWeight="bold">
              Sustainable Development Goals
            </Typography>
            <SdgGrid sdgGoals={selectedSdgGoals} />
          </SdgLabelGroup>
          <ProjectContentSection
            heading="How does it work?"
            content={project.content.howItWorks}
          />
          <ProjectContentSection
            heading="Why did we choose this project?"
            content={project.content.whyWeChoose}
          />
          <ProjectContentSection
            heading="What is my impact?"
            content={project.content.myImpact}
          />
          <ProjectContentSection
            heading="How do we verify the impact?"
            content={project.content.impactVerification}
          />
          <div style={{ marginTop: '5%' }}>
            <Button href={project.content.registryUrl}>
              View project source
            </Button>
          </div>
          <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
            <Image
              style={{ width: '50%', alignSelf: 'center' }}
              src={registryIcon}
            />
            {project.content.partnershipIcons.map((partner) => {
              return (
                <Image
                  key={partner}
                  style={{
                    width: '50%',
                    alignSelf: 'center',
                    maxHeight: '200px',
                  }}
                  src={partner}
                />
              );
            })}
          </div>
        </Sections>
        <PurchaseFrame>
          <Typography
            color="darkGrey.main"
            variant="monospace"
            sx={{ mb: 2 }}
          >
            {CURRUNCY_INFO[selectedCurruncy].displaySymbol}{' '}
            {(project.pricePerTonne[CURRUNCY_INFO[selectedCurruncy].symbol as CURRUNCY]).toFixed(2)
            }{' '}
            / tonne
          </Typography>
          <FormControl fullWidth sx={{ flexDirection: 'row' }}>
            <TextField
              sx={{
                mb: 2,
                background: '#fff',
                borderRadius: '6px 0px 0px 6px',
                margin: 0,
                width: '65%',
              }}
              color="ocean"
              placeholder="Quantity in Tonnes"
              type="number"
              value={showQuantity}
              InputProps={{
                inputProps: {
                  min: MINIMUM_OFFSET_TONNES,
                },
                style: {
                  width: '100%',
                  borderRadius: '6px 0px 0px 6px',
                  borderRight: 'none',
                },
              }}
              onChange={handleQuantityToPrice}
            />
            <Box
              sx={{
                borderRadius: '0 6px 6px 0px',
                background: '#3D8291',
                color: '#fff',
                width: '35%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography>
                {Number(quantity) === 1 ? 'Tonne' : 'Tonnes'}
              </Typography>
            </Box>
          </FormControl>
          <Box sx={{ textAlign: 'center' }} my="10px">
            <ArrowUpward />
            <ArrowDownward />
          </Box>{' '}
          <FormControl fullWidth sx={{ display: 'inline-block' }}>
            <TextField
              sx={{
                mb: 2,
                background: '#fff',
                borderRadius: '6px 0px 0px 6px',
                width: '65%',
              }}
              color="ocean"
              type="number"
              value={price}
              InputProps={{
                inputProps: {
                  min: MINIMUM_OFFSET_TONNES,
                },
                style: {
                  borderRadius: '6px 0px 0px 6px',
                  borderRight: 'none',
                },
              }}
              onChange={handlePriceToQuantity}
            />
            <Select
              value={selectedCurruncy}
              sx={{
                borderRadius: '0 6px 6px 0px',
                background: '#3D8291',
                color: '#fff',
                width: '35%',
              }}
              onChange={onCurruncyChange}
            >
              {
                Object.values(CURRUNCY_TYPE).map(curruncy_type => {
                  return (
                    <MenuItem key={curruncy_type} value={curruncy_type}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <img width={23.5} src={CURRUNCY_INFO[curruncy_type as CURRUNCY_TYPE].chain === CHAIN_TYPE.POLYGON_MAINNET
                          ? PolygonLogo : CeloLogo}
                          alt={CURRUNCY_INFO[curruncy_type as CURRUNCY_TYPE].chain === CHAIN_TYPE.POLYGON_MAINNET
                            ? 'Polygon' : 'Celo'}
                          /> &nbsp;
                        {CURRUNCY_INFO[curruncy_type as CURRUNCY_TYPE].displaySymbol}
                      </div>
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          <Stack direction="row" spacing={1}>
            <div>
              <Typography
                color="darkGrey.main"
                variant="monospace"
                display="flex"
                alignItems="center"
              >
                Rewarded{'  '}
              </Typography>
            </div>
            <div>
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
                alt="cred logo"
                src={logo}
              />{' '}
            </div>
            <div>
              <Typography
                color="darkGrey.main"
                variant="monospace"
                display="flex"
                alignItems="center"
              >
                {' '}
                {Number.isNaN(credReward) ? '---' : localize(credReward, 0, 2)}
              </Typography>
            </div>
          </Stack>

          {totalSupplyInTonne ?
            <Button
              fullWidth
              variant="contained"
              color="ocean"
              sx={{ mt: 2 }}
              disabled={
                !project.pricePerTonne?.usdc ||
                Number(quantity) < MINIMUM_OFFSET_TONNES ||
                Number(quantity) > totalSupplyInTonne
              }
              onClick={() => {
                navigate(`/project-purchase?projectId=${project.id}&quantity=${quantity}`)
              }}
            >
              Buy Now
            </Button>
            :
            isListed &&
            <div style={{ textAlign: 'center' }}>
              <BeatLoader color="rgb(19, 132, 148)" />
            </div>
          }

          {!isListed && (
            <Typography variant="caption" color="darkGrey.main">
              This project is not listed on blockchain at the moment.
            </Typography>
          )}

          {Number(quantity) < MINIMUM_OFFSET_TONNES && (
            <Typography variant="caption" color="darkGrey.main">
              Minimum offset tonnes you can purchase is 0.1. Please input 0.1
              tonnes or more.
            </Typography>
          )}
          {totalSupplyInTonne ? (Number(quantity) > totalSupplyInTonne && (
            <Typography variant="caption" color="darkGrey.main">
              Sorry, there are only {totalSupplyInTonne} tonnes of inventory available for this project.
            </Typography>
          )) : <br />}
        </PurchaseFrame>
      </ContentFrame>
    </Main>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedCurruncy: state.app.selectedCurruncy,
  }
}

function mapDispatchToProps(dispatch: Dispatch<{ type: AppActionTypes, value: string | number }>) {
  return {
    updateSelectedCurrency: (value: CURRUNCY_TYPE) => {
      dispatch({ type: AppActionTypes.UPDATE_SELECTED_CURRUNCY, value })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);

