import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CarbonCreditSearch } from './CarbonCreditSearch';
import { Docs } from './Docs';
import { Home } from './Home';
import { HowItWorks } from './HowItWorks';
import { ListNft } from './ListNft';
import { Marketplace } from './Marketplace';
import { NftCollection } from './NftCollection';
import { NftDetail } from './NftDetail';
import { Profile } from './Profile';
import ProjectDetail from './ProjectDetail';
import { ProjectList } from './ProjectList';
import ProjectPurchase from './ProjectPurchase';
import { TransactionSuccess } from './TransactionSuccess';
import { routes } from './routes';

export const Router: FC = () => {
  return (
    <Routes>
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.howItWorks} element={<HowItWorks />} />
      <Route path={routes.search} element={<CarbonCreditSearch />} />

      <Route path={routes.docs} element={<Docs />} />
      <Route path={routes.docsPage()} element={<Docs />} />

      <Route path={routes.projects} element={<ProjectList />} />
      <Route path={routes.projectDetail()} element={<ProjectDetail />} />

      <Route
        path={routes.transactionSuccessful}
        element={<TransactionSuccess />}
      />
      <Route path={routes.marketplace} element={<Marketplace />} />
      <Route path={routes.nftCollection()} element={<NftCollection />} />

      <Route path={routes.nftDetail()} element={<NftDetail />} />

      <Route path={routes.listNft} element={<ListNft />} />
      <Route path={routes.profile()} element={<Profile />} />

      <Route path="*" element={<Navigate replace to={routes.home} />} />
      <Route path={routes.projectPurchase} element={<ProjectPurchase />} />
    </Routes>
  );
};
