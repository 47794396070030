import { routes } from 'pages/routes';

interface NavItemProps {
  name: string;
  path: string;
}

export const navItems: NavItemProps[] = [
  {
    name: 'How it Works',
    path: routes.howItWorks,
  },
  {
    name: 'Our Projects',
    path: routes.projects,
  },
  {
    name: 'Search Offsets',
    path: routes.search,
  },
  {
    name: 'NFT Marketplace',
    path: routes.marketplace,
  },
];
