/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { useToggle } from 'react-use';
import { Link, styled, Typography } from '@mui/material';
import { TransactionError } from '@thirdweb-dev/sdk';
import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import { InternalLink } from 'components/InternalLink';
import { ListItem } from 'components/Listings';
import { StatusDialog, StatusItem } from 'components/StatusDialog';
import { Main } from 'components/layouts/main';
import { ContractReceipt } from 'ethers';
import useCredNft from 'hooks/nft';
import { useQueryParams } from 'hooks/useQueryParams';
import { useSnackbar } from 'notistack';
import { useEthereumContext } from 'providers/EthereumProvider';
import { useOrderContext } from 'providers/OrderProvider';
import { useTListingContext } from 'providers/TListingProvider';
import { Listing } from 'typings/listings';
import { MinimalNftItemPayload } from 'typings/nft';
import { NftCollection } from 'typings/nfts';
import { useImmer } from 'use-immer';
import { PURCHASE_NFT_STATUS_ITEMS } from 'utils/constants';
import { withMinimumExecutionTime } from 'utils/withMinimumExecutionTime';
import { routes } from './routes';

const Image = styled('img')`
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  object-fit: contain;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    width: 80%;
    height: auto;
    max-height: 500px;
    background-color: ${({ theme }) => theme.palette.grey[50]};

    object-fit: fill;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    overflow: hidden;
    margin: ${({ theme }) => theme.spacing(3)};
  }
`;

const ContentFrame = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(3)};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing(2, 0)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing(0, 4)};
  }
`;

const Sections = styled('div')`
  & > *:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

const PurchaseFrame = styled('div')`
  position: sticky;
  top: ${({ theme }) => theme.spacing(4)};
  height: fit-content;

  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(2)};
`;

const MINIMUM_QUANTITY = 1;

interface ContentSectionProps {
  heading: string;
  children?: ReactNode | undefined;
}

const ContentSection: FC<ContentSectionProps> = ({ heading, children }) => {
  return (
    <section>
      <Typography variant="s1" color="ocean.main" component="div">
        {heading}
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        color="neutralGray.dark"
        variant="body1"
        component="div"
      >
        {children}
      </Typography>
    </section>
  );
};

// function formatEther(wei: string | BN) {
//   return fromWei(wei);
// }

export const NftDetail: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { slug } = useParams<'slug'>();
  const { id } = useParams<'id'>();
  const [statusDialogOpen, toggleStatusDialog] = useToggle(false);
  const { contract: contractAddress } =
    useQueryParams<Partial<{ contract: string }>>();
  const [statusItems, updateStatusItems] = useImmer<StatusItem[]>(
    PURCHASE_NFT_STATUS_ITEMS,
  );

  const { connectWithMetaMask, getWeb3, chainId, provider } =
    useEthereumContext();

  const { marketplace, getAllActiveListings } = useTListingContext();

  const { getNftCollection, getNftMetadata } = useCredNft();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPurchasing, setPurchasing] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [nftReceipt, setNftReceipt] = useState<ContractReceipt | null>(null);

  const [nftCollection, setNftCollection] = useState<NftCollection>();

  //  NEW
  const [nftListings, setNftListings] = useState<Listing[]>();
  const [quantity, setQuantity] = useState<number>(MINIMUM_QUANTITY);

  const [nft, setNft] = useState<MinimalNftItemPayload>();
  const [accountAddress, setAccountAddress] = useState<string>();

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);
  };

  useEffect(() => {
    const loadAccountAddress = async () => {
      if (!(chainId && provider)) return;
      const accounts = await getWeb3().eth.getAccounts();
      setAccountAddress(accounts[0]);
    };

    void loadAccountAddress();
  }, [getWeb3, provider, chainId]);

  useEffect(() => {
    const loadNftCollection = async () => {
      if (!slug) return;
      const nftCol = await getNftCollection(slug);
      setNftCollection(nftCol);
    };

    void loadNftCollection();
  }, [getNftCollection, slug]);

  useEffect(() => {
    const loadOrderCollection = async () => {
      if (slug === undefined || !nftCollection?.contractAddress || !id) return;
      const _nftListings = await getAllActiveListings(
        nftCollection.contractAddress,
        id,
      );
      console.log(_nftListings);
      setNftListings(() => _nftListings);
    };

    void loadOrderCollection();
  }, [getAllActiveListings, slug, id, nftCollection]);

  useEffect(() => {
    async function fetchNftAndOrders() {
      if (nftListings && id && nftCollection) {
        const nft = (nftCollection.nfts ?? []).find(
          (nft) => nft.id.toString() === id,
        );
        if (nft) {
          if (
            nft.metadata.name &&
            nft.metadata.description &&
            nft.metadata.image
          ) {
            console.log('We already have the metadata');
            setNft({
              id: nft.id,
              metadata: {
                name: nft.metadata.name,
                image: nft.metadata.image,
                description: nft.description,
                attributes: [],
              },
            });
          } else {
            console.log('We have to refetch the metadata');
            const address =
              (contractAddress?.length ? contractAddress : undefined) ??
              nft.contractAddress ??
              nftCollection.contractAddress;
            if (address) {
              const nftMetadata = await getNftMetadata(address, id);
              setNft(nftMetadata);
            }
          }
          setIsLoading(false);
          return;
          // if (address) {
          //   console.log('Setting NFT');
          //   try {
          //     const nftMetadata = await getNftMetadata(address, id);
          //     setNft(nftMetadata);
          //   } catch (err) {
          //     console.error(err);
          //     console.log(nft);
          //     setNft({
          //       id: nft.id,
          //       metadata: {
          //         name: nft.metadata.name ?? `Token ${nft.id}`,
          //         image: nft.image ?? nft.metadata.image,
          //         description: nft.description ?? nft.metadata.description,
          //         attributes: [],
          //       },
          //     });
          //   }

          //   const _orders = await getAllOrders(address, id);

          //   await Promise.all(
          //     _orders.map(async (order: PostOrderResponsePayload) => {
          //       const orderInfo =
          //         await nftSwap.exchangeProxy.getERC1155OrderInfo(
          //           order.order as ERC1155OrderStructSerialized,
          //         );

          //       const makerStatus =
          //         await nftSwap.checkOrderCanBeFilledMakerSide(order.order);

          //       if (Number(orderInfo.remainingAmount) === 0) {
          //         _orders.splice(_orders.indexOf(order), 1);
          //       }

          //       if (!makerStatus.hasBalance) {
          //         _orders.splice(_orders.indexOf(order), 1);
          //       }
          //     }),
          //   );

          //   const ordersBySeller: OrderBySeller[] = [];
          //   for (const order of _orders) {
          //     // set order in array
          //     let existingSellerIndex = -1;
          //     const isSellerExist = ordersBySeller.find(
          //       (existingOrder, index) => {
          //         existingSellerIndex = index;
          //         return existingOrder.seller === order.order.maker;
          //       },
          //     );

          //     // eslint-disable-next-line
          //     if (isSellerExist === undefined) {
          //       ordersBySeller.push({
          //         seller: order.order.maker,
          //         orders: [order],
          //       });
          //     } else {
          //       ordersBySeller[existingSellerIndex]?.orders.push(order);
          //     }
          //   }
          //   setIsLoading(false);
          //   return;
          // }
        }
      }

      setNft(undefined);
    }

    if (!nftListings) return;
    void fetchNftAndOrders()
      .catch(console.log)
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    contractAddress,
    id,
    setNft,
    nftListings,
    getAllActiveListings,
    nftCollection,
    getNftMetadata,
  ]);

  const updateStatus = useCallback(
    (index: number, status: StatusItem['status']) => {
      if (index > 2) {
        return;
      }

      updateStatusItems((items) => {
        items[index]!.status = status;
      });
    },
    [updateStatusItems],
  );

  const resetStatusItems = useCallback(() => {
    updateStatusItems((statusItems) =>
      statusItems.map((item) => ({ ...item, status: 'pending' })),
    );
  }, [updateStatusItems]);

  const setStatusError = useCallback(
    (errorText: string) => {
      setErrorText(errorText);
      updateStatusItems((items) => {
        const pendingIndex = items.findIndex(
          (item) => item.status === 'pending',
        );

        if (pendingIndex !== -1) {
          items[pendingIndex]!.status = 'error';
        }
      });
    },
    [updateStatusItems],
  );

  const closeStatusDialog = useCallback(() => {
    setErrorText(null);
    toggleStatusDialog(false);
    resetStatusItems();
  }, [toggleStatusDialog, resetStatusItems]);

  //  THIS IS THE PURCHASE FLOW TO BE CHANGED
  // const purchaseNfts = useCallback(
  //   async (order: PostOrderResponsePayload) => {
  //     const numNftsToPurchase = order.nftTokenAmount;

  //     if (
  //       !orderCollection ||
  //       !orderCollection.contractAddress ||
  //       !nft ||
  //       Number(numNftsToPurchase) < MINIMUM_QUANTITY ||
  //       !order ||
  //       !order.erc20TokenAmount
  //     ) {
  //       console.log('nonononono');
  //       return;
  //     }

  //     setPurchasing(true);
  //     toggleStatusDialog(true);

  //     try {
  //       const isMetaMaskConnected = await withMinimumExecutionTime(
  //         connectWithMetaMask(),
  //         500,
  //       );

  //       if (!isMetaMaskConnected) {
  //         throw new Error('Unable to connect to MetaMask.');
  //       }

  //       updateStatus(0, 'success');

  //       console.log('account address:', accountAddress);
  //       if (!accountAddress) {
  //         return;
  //       }

  //       const credContract = getAlchemyCredContract();
  //       console.log('credContract.address:', credContract.options.address);

  //       const priceInWei = toBN(order.erc20TokenAmount);
  //       // const totalPriceInCredWei = priceInWei.mul(toBN(numNftsToPurchase));
  //       // console.log('totalPriceInCredWei:', formatEther(totalPriceInCredWei));

  //       const makerStatus = await nftSwap!.checkOrderCanBeFilledMakerSide(
  //         order.order,
  //       );
  //       console.log({ makerStatus });

  //       const takerStatus = await nftSwap!.checkOrderCanBeFilledTakerSide(
  //         order.order,
  //         accountAddress,
  //       );
  //       console.log({ takerStatus });

  //       if (!makerStatus.isApproved) {
  //         throw new Error('Seller has not approved NFT transfer.');
  //       }

  //       const credBalance = toBN(takerStatus.balance);
  //       console.log('CRED balance:', formatEther(credBalance));

  //       if (credBalance.lt(priceInWei)) {
  //         throw new Error(
  //           'Insufficient CRED balance: ' + formatEther(credBalance),
  //         );
  //       }

  //       const orderInfo = await nftSwap!.exchangeProxy.getERC1155OrderInfo(
  //         order.order as ERC1155OrderStructSerialized,
  //       );
  //       console.log({ orderInfo });

  //       const remainingAmount = Number(orderInfo.remainingAmount);
  //       console.log('remainingAmount:', remainingAmount);

  //       if (remainingAmount < Number(numNftsToPurchase)) {
  //         throw new Error(
  //           `Order has insufficient amount remaining: ${remainingAmount}`,
  //         );
  //       }

  //       const allowance = await credContract.methods
  //         .allowance(accountAddress, nftSwap!.exchangeProxyContractAddress)
  //         .call();
  //       console.log(
  //         'CRED already approved to transfer via 0x:',
  //         formatEther(allowance),
  //       );

  //       if (takerStatus.isApproved || toBN(allowance).gte(priceInWei)) {
  //         console.log('Transfer of CRED is already approved.');
  //       } else {
  //         // NftSwapV4.approveTokenOrNftByAsset ignores the asset amount and instead uses a hard-wired amount of 2^118 wei,
  //         // so we'll send the approval tx ourselves.

  //         // const approvalTx = await nftSwap.approveTokenOrNftByAsset(
  //         //   nftSwap.getTakerAsset(order.order),
  //         //   accountAddress,
  //         // );
  //         // await approvalTx.wait();

  //         const gasPrice = await getGasPrice('fast');
  //         console.log('gasPrice:', gasPrice);

  //         const maxPriorityFeePerGas = gasPrice[0];
  //         const maxFeePerGas = gasPrice[1];

  //         let approvalTx: any;

  //         if (chainId === 42220) {
  //           approvalTx = await credContract.methods
  //             .approve(
  //               nftSwap!.exchangeProxyContractAddress,
  //               order.erc20TokenAmount,
  //             )
  //             .send({
  //               from: accountAddress,
  //             });
  //         } else {
  //           approvalTx = await credContract.methods
  //             .approve(
  //               nftSwap!.exchangeProxyContractAddress,
  //               order.erc20TokenAmount,
  //             )
  //             .send({
  //               from: accountAddress,
  //               maxPriorityFeePerGas,
  //               maxFeePerGas,
  //             });
  //         }

  //         console.log('Approval tx receipt:', approvalTx);
  //       }

  //       updateStatus(1, 'success');

  //       const gasPrice = await getGasPrice('fast');
  //       console.log('gasPrice:', gasPrice);

  //       const maxPriorityFeePerGas = gasPrice[0];
  //       const maxFeePerGas = gasPrice[1];

  //       let buyTx: ContractTransaction;

  //       if (chainId === 42220) {
  //         buyTx = await nftSwap!.fillSignedOrder(order.order, undefined);
  //       } else {
  //         buyTx = await nftSwap!.fillSignedOrder(order.order, undefined, {
  //           maxPriorityFeePerGas,
  //           maxFeePerGas,
  //         });
  //       }

  //       console.log({ buyTx });

  //       const buyReceipt = await buyTx.wait();
  //       console.log({ buyReceipt });
  //       setNftReceipt(buyReceipt);

  //       updateStatus(2, 'success');
  //       // setNftReceipt(buyReceipt);
  //     } catch (error: unknown) {
  //       console.error({ error });
  //       const msg = String(
  //         (error as any)?.data?.message ??
  //           (error as Error)?.message ??
  //           'Unknown Error',
  //       );
  //       const errorText = `Transaction unsuccessful. ${msg}`;
  //       setStatusError(errorText);
  //       enqueueSnackbar(errorText, {
  //         variant: 'error',
  //       });
  //     } finally {
  //       setPurchasing(false);
  //     }
  //   },
  //   [
  //     orderCollection,
  //     connectWithMetaMask,
  //     enqueueSnackbar,
  //     accountAddress,
  //     getAlchemyCredContract,
  //     nft,
  //     setStatusError,
  //     toggleStatusDialog,
  //     updateStatus,
  //     nftSwap,
  //     chainId,
  //   ],
  // );

  const purchaseTNfts = useCallback(
    async (listing: Listing) => {
      if (
        !nft ||
        parseInt(listing.quantity.toString()) < MINIMUM_QUANTITY ||
        !listing ||
        !listing.buyoutPrice ||
        !marketplace
      ) {
        console.log('nonononono');
        return;
      }
      setPurchasing(true);
      toggleStatusDialog(true);

      try {
        const isMetaMaskConnected = await withMinimumExecutionTime(
          connectWithMetaMask(),
          500,
        );

        if (!isMetaMaskConnected) {
          throw new Error('Unable to connect to MetaMask.');
        }

        updateStatus(0, 'success');

        if (!accountAddress) {
          return;
        }
        const marketplacex = await marketplace
        const result = await marketplacex?.buyoutListing(
          listing.id,
          quantity,
          accountAddress,
        );
        setNftReceipt(result.receipt);
        updateStatus(1, 'success');
      } catch (error: any) {
        const msg = (error as TransactionError)?.reason;
        const errorText = `Transaction unsuccessful. ${msg}`;
        setStatusError(errorText);
        enqueueSnackbar(errorText, {
          variant: 'error',
        });
      } finally {
        setPurchasing(false);
      }
    },
    [
      accountAddress,
      nft,
      marketplace,
      connectWithMetaMask,
      enqueueSnackbar,
      setStatusError,
      toggleStatusDialog,
      updateStatus,
    ],
  );

  const error = useMemo(() => {
    if (!isLoading && !nftCollection) {
      return `Unknown collection: ${slug!}.`;
    }

    if (!nftCollection?.contractAddress) {
      return `Missing contract address for collection: ${slug!}.`;
    }
  }, [nftCollection, slug, isLoading]);

  const openseaUrl = useMemo(
    () =>
      nftCollection?.contractAddress && nft?.id
        ? routes.openseaAssetUrl(
            nftCollection.contractAddress,
            nft.id,
            nftCollection.chainId,
          )
        : '',
    [nftCollection, nft],
  );

  return (
    <Main>
      <Helmet>
        <title>
          NFT Marketplace |{' '}
          {String(nft?.metadata.name ? nft.metadata.name : `NFT #${nft?.id}`) ??
            ''}
        </title>
      </Helmet>
      <Typography variant="h1" align="center" mb={3}>
        NFT Detail
      </Typography>
      {!nftListings && (
        <div style={{ textAlign: 'center' }}>
          <BeatLoader color="rgb(19, 132, 148)" />
        </div>
      )}
      {!isLoading && error && error && (
        <Typography color="error" variant="monospace">
          Error: {error}
        </Typography>
      )}

      {nftCollection && nft && (
        <>
          <section>
            <InternalLink
              color="ocean.main"
              to={routes.nftCollection(nftCollection.slug)}
              underline="none"
            >
              <Typography variant="body1">{nftCollection.title}</Typography>
            </InternalLink>
            <Typography
              variant="h2"
              color="ocean.dark"
              style={{ marginTop: '1.5vh' }}
            >
              {String(nft.metadata.name)}
            </Typography>
          </section>
          <ContentFrame>
            <Image
              sx={{ mt: 3 }}
              src={nft.metadata.image}
              alt={nft.metadata.description}
            />
            <ContentSection heading="Listings">
              {isLoading ? (
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                  <BeatLoader color="rgb(19, 132, 148)" />
                </div>
              ) : nftListings?.length === 0 ? (
                <PurchaseFrame>
                  <p>Out of stock</p>
                </PurchaseFrame>
              ) : (
                <PurchaseFrame>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      color="darkGrey.main"
                      variant="monospace"
                      style={{ flex: 2, textAlign: 'center' }}
                      component="div"
                    >
                      Price
                    </Typography>
                    <Typography
                      color="darkGrey.main"
                      variant="monospace"
                      style={{ flex: 2, textAlign: 'center' }}
                      component="div"
                    >
                      Seller
                    </Typography>
                    <Typography
                      color="darkGrey.main"
                      variant="monospace"
                      style={{ flex: 2, textAlign: 'center' }}
                      component="div"
                    >
                      Quantity
                    </Typography>
                    <Typography
                      color="darkGrey.main"
                      variant="monospace"
                      style={{ flex: 2, textAlign: 'center' }}
                      component="div"
                    />
                  </div>
                  {nftListings?.map((listing, index) => {
                    return (
                      <div key="">
                        <ListItem
                          MINIMUM_QUANTITY={MINIMUM_QUANTITY}
                          isPurchasing={isPurchasing}
                          purchaseNfts={purchaseTNfts}
                          listing={listing}
                          quantity={quantity}
                          setQuantity={handleQuantityChange}
                        />
                        {nftListings.length === 0 && (
                          <Typography mt={1}>
                            No listings found. This item is not for sale.
                          </Typography>
                        )}
                        <StatusDialog
                          showInProfileButton
                          title="Purchase Status"
                          open={statusDialogOpen}
                          errorText={errorText}
                          statusItems={statusItems}
                          contractAddress={nftCollection.contractAddress}
                          tokenId={nft.id}
                          transactionReceipt={nftReceipt}
                          onClose={closeStatusDialog}
                        />
                        {nftListings.length - 1 !== index && <hr />}
                      </div>
                    );
                  })}
                </PurchaseFrame>
              )}
            </ContentSection>
          </ContentFrame>
          <Sections>
            <ContentSection heading="Description">
              {nft.metadata.description?.length
                ? nft.metadata.description
                : nftCollection.description}
            </ContentSection>
            {chainId !== SupportedChainIdsV4.Celo && (
              <ContentSection heading="External">
                <Link
                  color="ocean.main"
                  href={openseaUrl}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                >
                  View on OpenSea
                </Link>
              </ContentSection>
            )}
          </Sections>
        </>
      )}
    </Main>
  );
};
