import { FC } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

interface InternalLinkProps extends MuiLinkProps {
  to: RouterLinkProps['to'];
}

export const InternalLink: FC<InternalLinkProps> = ({ children, ...props }) => {
  return (
    <MuiLink component={RouterLink} underline="none" {...props}>
      {children}
    </MuiLink>
  );
};
