import { FC } from 'react';
import { styled } from '@mui/material';
import { SustainableDevelopmentGoal } from 'typings/sdg';


const SdgFrame = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: ${({ theme }) => theme.spacing(2, 2)};
  justify-content: center;

  & img {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`;

interface SdgGridProps {
  className?: string;
  sdgGoals: SustainableDevelopmentGoal[];
}

export const SdgGrid: FC<SdgGridProps> = ({ className, sdgGoals }) => {

  return (
    <SdgFrame className={className}>
      {sdgGoals.map(({ id, assets }) => {
        return <img key={id} src={assets.icon} alt={`SDG-${id}-icon`} />;
      })}
    </SdgFrame>
  );
};
