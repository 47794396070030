import { FC } from 'react';
import logo from 'assets/logos/logo.png';
import discord from 'assets/logos/discord.png';
import twitter from 'assets/logos/twitter.png';
import telegran from 'assets/logos/telegram.png';

export const Footer: FC = () => {
  return (
    <div className='py-10 px-10 md:px-20 bg-[#001E2B]'>
      <div className='flex items-center space-x-6'>
        <img src={logo} alt="cred logo" />
        <p className='text-2xl text-[#34E1D6]'>CRED</p>
      </div>
      <div className='flex flex-col pt-6 md:items-center md:justify-between md:flex-row'>
        <div className='flex items-baseline flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-6 font-medium lg:text-xl text-[#148493]'>
          <p>Status</p>
          <p>Privacy</p>
          <p>Terms</p>
          <p>Cookie Preferences</p>
          <p>About us</p>
        </div>
        <div className='flex items-center py-4 space-x-6 md:py-0'>
          <img src={discord} alt="discord logo" />
          <img src={twitter} alt="twitter logo" />
          <img src={telegran} alt="telegran logo" />
        </div>
      </div>
       <p className='pt-2 font-medium text-[#148493]'>©2024 CRED, a ProjectArk initiative. All rights reserved. Various trademarks held by their respective owners.</p>
    </div>
  );
};
