import { FC } from 'react';
import { InternalLink } from 'components/InternalLink';
import { StyledButton } from 'components/StyledButton';
import { routes } from 'pages/routes';
import PickProject from 'assets/how/pick-project.png'
import Count from './Count';
import rightUnion from 'assets/how/rightUnion.png'

interface CardTailwindProps {
  count: number;
  text: string;
  buttonText?: string;
  routeText ?: string;
}
const CardTailwind = ({count, text, buttonText, routeText}: CardTailwindProps) => {
  let button;
  let route;
  let arrow;
  if (count==3){
    arrow = 
      <div className='flex items-center justify-center rotate-90 absolute top-40'>
        <img src={rightUnion} alt="rightArrow" />
      </div>
  }
  
  if (buttonText){
    if (count==1){
      route=routes.projects
    }
    else if (count==5){
      route=routes.marketplace
    }
    button=
    <a href={route}>
      <div className="w-[160px] h-[40px]">
          <button className="text-white text-xs font-semibold w-full h-full bg-[#00DD9B] rounded-[20px]  transition-all ease-out duration-700 hover:bg-[#85ede6]">
              {buttonText}
          </button>
      </div>
     </a>
  } 
    return (
        <div className="flex">
            <Count value={count} />
            <div className="bg-white border border-gray-200 rounded-lg shadow max-w-72 m-h-[400px]">
              <img src={PickProject} alt="pick" className="rounded-t-lg" />
              <div className="p-5 flex flex-col  items-center justify-between gap-[10px] relative">
                <p  className="text-center text-[#3D818F]">
                  {text}
                </p> 
                {arrow}
                <div>{button}</div>
              </div>
            </div>
          </div>
    );
    };

export default CardTailwind;