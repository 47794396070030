import { FC, useState } from 'react';
import imgHTWBG from 'assets/home/how-it-work/htw-bg.png';
import imgHTWOne from 'assets/home/how-it-work/htw-1.png';
import imgHTWTwo from 'assets/home/how-it-work/htw-2.png';
import imgHTWThree from 'assets/home/how-it-work/htw-3.png';
import imgHTWFour from 'assets/home/how-it-work/htw-4.png';
import imgHTWFive from 'assets/home/how-it-work/htw-5.png';
import { InternalLink } from 'components/InternalLink';
import { routes } from '../../pages/routes';

const DotsHoverText = [
  'When you purchase carbon credits from any of our carbon offset project, a tokenized credit called CARB token is created, representing the number of carbon credits on blockchain.',
  'CARB tokens are burnt instantaneously to ensure that those carbon credits you bought will not be traded or counted twice. This way, we are able to keep the carbon offset market transparent and reliable. ',
  'In the meantime, CRED tokens (Carbon REmoval Dollar) are generated corresponding to how many tonnes of carbon emission you bought and how much money you spent. CRED tokens can be seen as reward points for appreciating your contribution to our environment.',
  'CRED tokens can be used to buy NFTs in our NFT marketplace, where has diversified collections from renowned artists around the globe. Those NFTs will grant you different benefits if you become their holders.',
  'After purchasing NFTs using CRED, CRED tokens are transferred to the NFTs sellers. More CRED tokens will be generated when the next cycle begins',
];

export const CircleComponent: FC = () => {
  const [text, setText] = useState('ECOSYSTEM OF CRED');
  const [selectedIndex, setHover] = useState(-1);

  return (
    <div className='relative z-0'>
      <img className='w-full h-[850px]' src={imgHTWBG} alt="HWT" />
      <div className='absolute w-full top-10'>
        <p className='text-4xl text-center text-white'>How it Works</p>

        <div className='relative z-0'>
          <div className='flex justify-center pt-12'>
            <div className='w-[30rem] h-[30rem] rounded-full border-8	border-[#76F9C2] shadow-[0_0_20px_#76F9C2]'>
              <div className='flex items-center justify-center h-full'>
                <div className='w-[26rem] h-[26rem] rounded-full border-4	border-[#00FF66]'
                  style={{ boxShadow: 'inset 0px 0px 60px -12px #00FF66' }}
                >
                  <p className='flex items-center justify-center h-full px-12 text-white'>
                    {text}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='absolute cursor-pointer top-10 right-[280px] z-10 flex justify-center items-center gap-10'
            onMouseEnter={() => {
              setText(DotsHoverText[0]!);
              setHover(0)
            }}
            onMouseLeave={() => {
              setHover(-1)
            }}
          >
            <p className='flex items-center justify-center text-2xl font-bold text-white rounded-full h-14 w-14 '
              style={{
                backgroundColor: selectedIndex === 0 ? '#37F097' : '#00361F', border: selectedIndex === 0 ? '4px solid #76F9C2' : 'none'
              }}
            >1</p>
            <img className='h-32' src={imgHTWOne} alt="imgHTWOne" />
          </div>

          <div className='absolute cursor-pointer top-[225px] right-[215px] z-10 flex justify-center items-center gap-10'
            onMouseEnter={() => {
              setText(DotsHoverText[1]!);
              setHover(1)
            }}
            onMouseLeave={() => {
              setHover(-1)
            }}
          >
            <p className='flex items-center justify-center text-2xl font-bold text-white rounded-full h-14 w-14'
              style={{
                backgroundColor: selectedIndex === 1 ? '#37F097' : '#00361F', border: selectedIndex === 1 ? '4px solid #76F9C2' : 'none'
              }}
            >2</p>
            <img className='h-32' src={imgHTWTwo} alt="imgHTWTwo" />
          </div>

          <div className='absolute cursor-pointer top-[420px] right-[290px] z-10 flex justify-center items-center gap-10'
            onMouseEnter={() => {
              setText(DotsHoverText[2]!);
              setHover(2)
            }}
            onMouseLeave={() => {
              setHover(-1)
            }}
          >
            <p className='flex items-center justify-center text-2xl font-bold text-white rounded-full h-14 w-14'
              style={{
                backgroundColor: selectedIndex === 2 ? '#37F097' : '#00361F', border: selectedIndex === 2 ? '4px solid #76F9C2' : 'none'
              }}
            >3</p>
            <img className='h-32' src={imgHTWThree} alt="imgHTWThree" />
          </div>

          <div className='absolute cursor-pointer top-[100px] left-[235px] z-10 flex justify-center items-center gap-10'
            onMouseEnter={() => {
              setText(DotsHoverText[3]!);
              setHover(3)
            }}
            onMouseLeave={() => {
              setHover(-1)
            }}
          >
            <img className='h-32' src={imgHTWFive} alt="imgHTWFive" />
            <p className='flex items-center justify-center text-2xl font-bold text-white rounded-full h-14 w-14'
              style={{
                backgroundColor: selectedIndex === 3 ? '#37F097' : '#00361F', border: selectedIndex === 3 ? '4px solid #76F9C2' : 'none'
              }}
            >5</p>
          </div>

          <div className='absolute cursor-pointer top-[360px] left-[235px] z-10 flex justify-center items-center gap-10'
            onMouseEnter={() => {
              setText(DotsHoverText[4]!);
              setHover(4)
            }}
            onMouseLeave={() => {
              setHover(-1)
            }}
          >
            <img className='h-32' src={imgHTWFour} alt="imgHTWFour" />
            <p className='flex items-center justify-center text-2xl font-bold text-white rounded-full h-14 w-14'
              style={{
                backgroundColor: selectedIndex === 4 ? '#37F097' : '#00361F', border: selectedIndex === 4 ? '4px solid #76F9C2' : 'none'
              }}
            >4</p>
          </div>
        </div>

        <InternalLink className='flex justify-center w-full pt-24' to={routes.howItWorks}>
          <button className='bg-[#00DD9B] w-2/6 h-[60px] rounded-full text-white text-xl'>HOW IT WORKS</button>
        </InternalLink>
      </div>
    </div>
  );
};
