import { CHAIN_TYPE } from '../constants';

const NATIVE_TOKEN_ADDRESS_AS_ERC20 =
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';

export const NETWORKS = {
  '137': {
    CRED_ADDRESS: '0x1d4FC48cd9bf9d0d299e4D81cc8f406c70Bd6425',
    REGISTRY_ADDRESS: '0xA2E8906Da837Fb794c04c4c8eadFC0607865c523',
  },
  '80001': {
    CRED_ADDRESS: '0x37c3268dea30b95089b626dee7b5afc6de0f917a',
    REGISTRY_ADDRESS: '0x13f0b421530f8955FA584c01eFd5439DF072F246',
  },
  '42220': {
    CRED_ADDRESS: '0xb824b106FC861860Fc2f2E9eE83f7616074f2Dca',
    REGISTRY_ADDRESS: '0x3B1027A17E126897223dA0E31012D7c2B18Ec8Fe',
  },
};

export const MARKETPLACE_CONTRACTS = {
  [CHAIN_TYPE.POLYGON_MAINNET]: '0xB53fDeC3A96Ea292C1732b8891967ec786e6495a',
  [CHAIN_TYPE.POLYGON_MUMBAI]: '0xAF5493992df8811F8aA43fe501D5d2c421A32446',
  [CHAIN_TYPE.CELO_MAINNET]: '0x94B6dd84eAd0382e1A50A268d6b0AD4e300A67f7',
};

export const LIFI_config = {
  [CHAIN_TYPE.POLYGON_MAINNET]: {
    usdcAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  [CHAIN_TYPE.POLYGON_MUMBAI]: {
    usdcAddress: '0x6d4dd09982853f08d9966ac3ca4eb5885f16f2b2',
  },
  [CHAIN_TYPE.CELO_MAINNET]: {
    usdcAddress: '0xef4229c8c3250C675F21BCefa42f58EfbfF6002a',
    cusdAddress: '0x765de816845861e75a25fca122bb6898b8b1282a',
    ceurAddress: '0xd8763cba276a3738e6de85b4b3bf5fded6d6ca73',
  },
};

export const CURRENCY_TOKENS = {
  matic: {
    // Token addresses are those used by Aave: https://app.aave.com/
    // Price feed addresses are from https://docs.chain.link/docs/matic-addresses.
    USDC: {
      symbol: 'USDC',
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      decimals: 6,
      priceFeed: {
        address: '0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7',
        decimals: 8,
        maxAgeInSecs: 5 * 60, // 5 minutes
      },
    },
    ETH: {
      symbol: 'WETH',
      address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      decimals: 18,
      priceFeed: {
        address: '0xF9680D99D6C9589e2a93a78A04A279e509205945',
        decimals: 8,
        maxAgeInSecs: 5 * 60, // 5 minutes
      },
    },
    MATIC: {
      symbol: 'MATIC',
      address: NATIVE_TOKEN_ADDRESS_AS_ERC20,
      decimals: 18,
      priceFeed: {
        address: '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0',
        decimals: 8,
        maxAgeInSecs: 5 * 60, // 5 minutes
      },
    },
  },
  mumbai: {
    // Token addresses are those supplied by Aave's faucet: https://app.aave.com/faucet/
    // Price feed addresses are from https://docs.chain.link/docs/matic-addresses.
    USDC: {
      symbol: 'USDC',
      address: '0x2058A9D7613eEE744279e3856Ef0eAda5FCbaA7e',
      decimals: 6,
      priceFeed: {
        address: '0x572dDec9087154dC5dfBB1546Bb62713147e0Ab0',
        decimals: 8,
        maxAgeInSecs: 48 * 60 * 60, // 48 hours
      },
    },
    ETH: {
      symbol: 'WETH',
      address: '0x3C68CE8504087f89c640D02d133646d98e64ddd9',
      decimals: 18,
      priceFeed: {
        address: '0x0715A7794a1dc8e42615F059dD6e406A6594651A',
        decimals: 8,
        maxAgeInSecs: 10 * 60, // 10 minutes
      },
    },
    MATIC: {
      symbol: 'MATIC',
      address: NATIVE_TOKEN_ADDRESS_AS_ERC20,
      decimals: 18,
      priceFeed: {
        address: '0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada',
        decimals: 8,
        maxAgeInSecs: 2 * 60 * 60, // 2 hours
      },
    },
  },
  celo: {
    cUSD: {
      symbol: 'cUSD',
      address: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
      decimals: 18,
    },
    CELO: {
      symbol: 'CELO',
      address: '0x471EcE3750Da237f93B8E339c536989b8978a438',
      deciimals: 18,
    },
  },
};
