import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { InternalLink } from 'components/InternalLink';
import { routes } from './routes';
import { useContentContext } from 'providers/ContentProvider';
import imgHomeFooterBG from 'assets/home/home-footer-bg.png';
import imgHomeBG from 'assets/home/home-bg.png';
import imgCredLogo from 'assets/home/cred-logo.png';
import { CircleComponent } from 'components/Home/CircleComponent';
import { Subscribe } from 'components/Home/Subscribe';
import { TeamMember } from 'components/Home/TeamMember';
import { Advisor } from 'components/Home/Advisor';
import { Backers } from 'components/Home/Backers';
import { TargetAudienceCollection } from 'components/Home/TargetAudienceCollection';
import { UtilityCred } from 'components/Home/Utility';

export const Home: FC = () => {
  const { nftCollections } = useContentContext();
  return (
    <div>
      <Helmet>
        <title>CRED - Home</title>
      </Helmet>

      <div className='relative z-0'>
        <img className='w-full h-[600px] object-cover' src={imgHomeBG} alt="HomeBG" />
        <div className='absolute w-full top-10'>
          <div className='flex items-center justify-around'>
            <div
              className='p-0.5 rounded-3xl shadow-xl'
              style={{
                background: 'linear-gradient(-45deg,transparent 90px,#C2FFE9 0)bottom left',
              }}
            >
              <div className='rounded-3xl	relative w-[30rem] h-[30rem] lg:w-[38rem] lg:h-full'
                style={{ background: 'linear-gradient(-45deg,transparent 90px,#00361F 0)bottom left' }}
              >
                <div className='h-full py-3 pl-6 pr-3'>
                  <div className='flex flex-col justify-between h-full'>
                    <div className='space-y-6 font-overpass'>
                      <p className='text-4xl font-bold leading-normal text-white'>Introducing <span className='text-[#4FF037]'>CRED</span>  -
                        a clean,<span className='text-[#4FF037]'>green</span> currency for the metaverse.</p>
                      <p className=" text-[#30E1D6] leading-normal">CRED is the first “climate positive base currency” for the metaverse, allowing Web3 builders to create impact and support growth for a carbon neutral transition.</p>
                    </div>
                    <div className='py-6'>
                      <InternalLink to={routes.howItWorks}>
                        <button className='bg-[#00B2A7] w-3/5	h-[60px] rounded-full text-white'>START EARNING CREDs NOW
                        </button>
                      </InternalLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={imgCredLogo} alt="CredLogo" />
          </div>
        </div>
      </div>

      <CircleComponent />
      <UtilityCred />
      <TargetAudienceCollection />
      <div className='relative z-0'>
        <img className='w-full h-[630px] md:h-[750px] lg:h-[550px] obejct-cover' src={imgHomeFooterBG} alt="footer" />
        <div className='absolute top-0 z-10 w-full'>
          {/* <TeamMember />
          <Advisor />
          <Backers /> */}
          <Subscribe />
        </div>
      </div>
    </div>
  );
};
