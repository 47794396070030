import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { Typography } from '@mui/material';
import { InternalLink } from 'components/InternalLink';
import { NftCard } from 'components/NftCard';
import { NftContentSection } from 'components/NftContentSection';
import { ProjectListFrame } from 'components/ProjectListFrame';
import { Main } from 'components/layouts/main';
import { ethers } from 'ethers';
import useCredNft from 'hooks/nft';
import { useEthereumContext } from 'providers/EthereumProvider';
import { useTListingContext } from 'providers/TListingProvider';
import { Listing } from 'typings/listings';
import { NftCollection as NftCollectionType } from 'typings/nfts';
import { routes } from './routes';

export const NftCollection: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { isReady } = useEthereumContext();

  const { getAllListings } = useTListingContext();

  const { getNftCollection } = useCredNft();

  const [nftCollection, setNftCollection] = useState<NftCollectionType>();
  const [collectionListings, setCollectionListings] = useState<Listing[]>();

  useEffect(() => {
    const loadNftCollection = async () => {
      if (!(slug && isReady)) return;
      const innerNftCollection = await getNftCollection(slug);
      setNftCollection(() => innerNftCollection);
    };

    void loadNftCollection();
  }, [slug, getNftCollection, isReady]);

  useEffect(() => {
    if (!nftCollection) return;
    setIsLoading(true);
    const loadOrderCollection = async () => {
      const listings = await getAllListings(nftCollection.contractAddress);
      if (listings) setCollectionListings(() => listings);
      setIsLoading(false);
    };

    void loadOrderCollection();
  }, [nftCollection, getAllListings, slug]);

  // useEffect(() => {
  //   const loadNftItems = async () => {
  //     if (!orderCollection) return
  //     setIsLoading(true)
  //     const _nftsWithMetadata = await getBatchNftsMetadata(orderCollection.contractAddress, orderCollection.nfts.map(nft => nft.id))
  //     setNftItems(() => _nftsWithMetadata)
  //     setIsLoading(false)
  //   }

  //   void loadNftItems()
  // }, [orderCollection, getBatchNftsMetadata])

  return (
    <Main>
      <Helmet>
        <title>CRED | NFT Marketplace Collection</title>
      </Helmet>
      <Typography variant="h1" align="center" mb={3}>
        NFT Marketplace Collection
      </Typography>

      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <BeatLoader color="rgb(19, 132, 148)" />
        </div>
      ) : (
        <>
          <NftContentSection
            heading={nftCollection!.title}
            content={nftCollection!.description}
          />
          <ProjectListFrame>
            {nftCollection!.nfts?.map((nft, index) => {
              const listing = collectionListings?.find(
                (listing) => listing.asset.id.toString() === nft.id,
              );
              return (
                <div key={nft?.id}>
                  <div
                    style={{
                      boxShadow: '3px 4px 5px 3px rgba(0, 0, 0, 0.2)',
                      maxWidth: 400,
                    }}
                  >
                    <InternalLink
                      to={routes.nftDetail(
                        slug,
                        nft.id,
                        listing?.assetContractAddress,
                      )}
                    >
                      <div>
                        <NftCard
                          nftImage={nft.metadata.image!}
                          delay={index * 50}
                          price={{
                            cred:
                              listing &&
                              parseInt(listing?.quantity.toString()) > 0
                                ? Number(
                                    ethers.utils.formatEther(
                                      listing.buyoutPrice,
                                    ),
                                  )
                                : NaN,
                          }}
                          content={{
                            title: nft.metadata.name,
                            description: nft.metadata.description,
                          }}
                        />
                      </div>
                    </InternalLink>
                  </div>
                </div>
              );
            })}
            {collectionListings && collectionListings.length === 0 && (
              <Typography variant="s2">
                This collection has no NFTs listed in CRED.
              </Typography>
            )}
          </ProjectListFrame>
        </>
      )}
    </Main>
  );
};
