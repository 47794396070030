import { createContext, FC, useEffect, useMemo, useState } from 'react';

import { useSafeContext } from 'hooks/useSafeContext';
import { SustainableDevelopmentGoal } from 'typings/sdg';

import { UN_SDGS } from 'utils/constants';

import { Project } from 'typings/project';
import useCredNft from 'hooks/nft';
import { useEthereumContext } from './EthereumProvider';
import { NftCollection } from 'typings/nfts';

interface ContentContextValue {
  sdgGoals: SustainableDevelopmentGoal[];
  projects: Project[];
  getProject: (projectId: string) => Promise<Project | undefined>;
  nftCollections: NftCollection[];
  pageNo: number;
  totalData: number;
  changePageNo: (pageNo: number) => void;
  limit: number;
  changeLimit: (limit: number) => void;
}

const ContentContext = createContext<ContentContextValue | undefined>(
  undefined,
);
ContentContext.displayName = 'ContentContext';

export const useContentContext = () => useSafeContext(ContentContext);

export const ContentProvider: FC<any> = ({ children }) => {
  const sdgGoals = useMemo<SustainableDevelopmentGoal[]>(() => UN_SDGS, []);
  const [projects, setProjects] = useState<Project[]>([]);
  const [nftCollections, setNftCollections] = useState<NftCollection[]>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [limit, setLimit] = useState<number>(8);
  const [totalData, setTotalData] = useState<number>(0);

  const { getProjects, getProject, getNftCollections } = useCredNft()
  const { isReady } = useEthereumContext()

  const changePageNo = (page: number) => {
    setPageNo(page);
  }

  const changeLimit = (limit: number) => {
    setLimit(limit);
  }

  useEffect(() => {
    async function fetchProjects() {
      if (!isReady) return
      const projects = await getProjects()
      setProjects(
        projects?.sort((a, b) =>
          Boolean(a.cred) === Boolean(b.cred)
            ? a.title.localeCompare(b.title)
            : (b.cred ? 1 : 0) - (a.cred ? 1 : 0),
        ),
      );
    }

    void fetchProjects();
  }, [getProjects, isReady])

  useEffect(() => {
    const compare = (a: NftCollection, b: NftCollection) => {
      return a.slug < b.slug ? 1 : -1;
    };

    async function fetchNftCollections() {
      if (!isReady) return
      const response = await getNftCollections(pageNo, limit)

      if (response?.collections) {
        setTotalData(response?.totalData)
        setNftCollections(response?.collections.sort(compare));
      }
    }

    void fetchNftCollections();
  }, [isReady, getNftCollections, pageNo, limit])


  const contextValue = useMemo<ContentContextValue>(() => {
    return {
      sdgGoals,
      projects,
      getProject,
      nftCollections,
      pageNo,
      changePageNo,
      limit,
      changeLimit,
      totalData
    }
  }, [sdgGoals, projects, getProject, nftCollections, pageNo, changePageNo,totalData]);

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
};

