import { FC } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  styled,
  Typography,
} from '@mui/material';
import { Project } from 'typings/project';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

const StyledCardMedia = styled(CardMedia)`
  aspect-ratio: 184 / 97;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
`;

interface ProjectCardProps
  extends Pick<
    Project,
    'id' | 'title' | 'content' | 'images' | 'pricePerTonne' | 'cred'
  > {
  className?: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({
  className,
  content,
  title,
  images,
  pricePerTonne,
  cred
}) => {
  return (
    <StyledCard className={className}>
      <StyledCardMedia image={images[0]} />
      <StyledCardContent>
        <Typography sx={{ mb: 1 }} variant="monospace" color="darkGrey.main">
          {content.project_location}
        </Typography>
        <Typography variant="s1" color="darkGrey.dark">
          {title}
        </Typography>
        <div>
          <Typography sx={{ mt: 2 }} variant="monospace" color="darkGrey.main">
            USD&nbsp;$
            {pricePerTonne?.usdc ? cred?.rewardPerTonne : '---'}{' '}
            per&nbsp;tonne
          </Typography>
        </div>
      </StyledCardContent>
    </StyledCard>
  );
};
