import { FC, useMemo } from 'react';
import { Dialog } from '@mui/material';
import { LiFiWidget, WidgetConfig } from '@lifi/widget';
import { config } from 'utils/lifi';

interface LiFiWidgetPopoutProps {
  open: boolean;
  onClose: () => void;
}

export const LiFiWidgetPopout: FC<LiFiWidgetPopoutProps> = ({
  open,
  onClose,
}) => {
  const widgetConfig: WidgetConfig = useMemo(
    () => ({
      integrator: 'Carbonbase',
      fromChain: config.chain,
      toChain: config.chain,
      toToken: config.usdCAddress,
      containerStyle: {
        display: 'flex',
        maxHeight: '580px',
      },
      disableAppearance: true,
      theme: {
        palette: {
          primary: { main: '#41AEA8' },
        },
      },
    }),
    [],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <LiFiWidget integrator='Carbonbase' config={widgetConfig} />
    </Dialog>
  );
};
