import { FC, useMemo } from 'react';
import { Face } from '@mui/icons-material';
import { Typography, Button, Box, Stack } from '@mui/material';

import logo from 'assets/logos/logo.png';
import imgMetaMask from 'assets/metamask.png';

import { localize } from 'utils/i18n';
import { useEthereumContext } from 'providers/EthereumProvider';
import { InternalLink } from 'components/InternalLink';
import { routes } from 'pages/routes';


interface Props {
  tokenAmount?: string
}
export const BigProfileCard: FC<Props> = ({ tokenAmount }) => {
  const tokenAmountStr = useMemo(() => tokenAmount ? localize(Number(tokenAmount), 0, 3) : ' ', [tokenAmount])
  const { accountAddress } = useEthereumContext();

  return <Stack direction={{ xs: 'column', sm: 'row' }}>
    <Stack flex={1} alignItems='center' justifyContent='center' display='flex'>
      <Box
        sx={{
          width: '160px',
          height: '160px',
          borderRadius: ' 50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

        }}
      >
        <Face sx={{ color: '#128494', fontSize: '120px' }} />
      </Box>
      <Typography
        color="#148493"
        sx={{
          textAlign: 'center',
          fontSize: '30px',
          fontWeight: 'bold',
          marginTop: '20px',
        }}
      >
        SDG Guru
      </Typography>
    </Stack>
    <Box flex={4} maxWidth={660}>
      <Box
        width={{ lg: '80%', sm: '100%' }}
        sx={{
          height: 'auto',
          background: 'linear-gradient(rgb(103, 190, 185), #41AEA8)',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          borderRadius: '20px',
          paddingX: '10px',
          paddingTop: '40px',
          paddingBottom: '20px',
          display: 'flex',
          position: 'relative',
        }}
        flexDirection={{ xs: 'column', lg: 'row' }}
      >
        <Box
          flexGrow={1}
          paddingX="20px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Box>
              <Typography
                color="#fff"
                sx={{
                  fontSize: '18px',
                }}
              >
                BALANCE
              </Typography>
            </Box>
            <Box
              sx={{
                height: '70px',
                width: '70px',
                padding: '15px',
                backgroundColor: '#86EDE7',
                borderRadius: '50%',
                position: 'absolute',
                right: '0',
              }}
            >
              <img
                src={imgMetaMask}
                alt="meta mask"
                width="40px"
                height="40px"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              marginBottom: '20px',
              alignItems: 'flex-start',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={logo} alt="cred logo" width="50px" />
              <Typography
                display="inline"
                variant="s2"
                sx={{
                  color: '#fff',
                  marginLeft: '10px',
                }}
              >
                {tokenAmountStr}{' '}
              </Typography>
            </Box>
            <Typography color="#fff" variant="s2">
              {accountAddress}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <InternalLink to={routes.marketplace} marginRight="8px">
              <Button
                sx={{
                  borderRadius: '10px',
                  paddingY: '10px',
                  color: '#148493',
                  backgroundColor: '#86EDE7',
                }}
              >
                Spend CRED
              </Button>
            </InternalLink>
            <InternalLink to={routes.projects}>
              <Button
                sx={{
                  borderRadius: '10px',
                  marginLeft: '5px',
                  paddingY: '10px',
                  color: '#148493',
                  backgroundColor: '#86EDE7',
                }}
              >
                Earn CRED
              </Button>
            </InternalLink>
          </Box>
        </Box>
      </Box>
    </Box>
  </Stack>
}
