import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import AppBar from 'components/AppBar';
import { Footer } from 'components/Footer';
import { useEthereumContext } from 'providers/EthereumProvider';

import { Router } from 'pages/Router';


export const AppContent: FC = () => {
  const { chainId, isConnected, accountAddress, connectWithMetaMask } = useEthereumContext()

  useEffect(() => {
    void connectWithMetaMask()
  }, [connectWithMetaMask])

  return (
    <BrowserRouter>
      <AppBar
        chainId={chainId}
        isConnected={isConnected}
        accountAddress={accountAddress}
        connectWallet={connectWithMetaMask}
      />
      <SnackbarProvider>
        <Router />
      </SnackbarProvider>
      <Footer />
    </BrowserRouter>
  );
};
