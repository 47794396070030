import { FC } from 'react';
import {
  alpha,
  Box,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEthereumContext } from 'providers/EthereumProvider';
import { MetaMaskLogo } from './icons/MetaMaskLogo';

const CryptoBalanceCardFrame = styled('div')`
  max-width: 100%;
  overflow: hidden;

  padding: ${({ theme }) => theme.spacing(2, 3, 3)};
  background: ${({ theme }) =>
    `linear-gradient(${theme.palette.ocean.light!}, ${theme.palette.ocean.main
    })`};

  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 8px;

  .meta-mask-button {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.2)};
  }
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(3)} !important;
  }
`;

interface CryptoBalanceCardProps {
  amount: string | number;
  tokenSymbol: string;
  walletAddress: string;
  onMetaMaskClick: () => void;
}

export const CryptoBalanceCard: FC<CryptoBalanceCardProps> = ({
  amount,
  tokenSymbol,
  walletAddress,
  onMetaMaskClick,
}) => {
  const { isEthereumSupported } = useEthereumContext();

  return (
    <CryptoBalanceCardFrame>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="s3">Balance</Typography>
        {isEthereumSupported && (
          <Tooltip arrow title={`Register ${tokenSymbol} on MetaMask`}>
            <IconButton className="meta-mask-button" onClick={onMetaMaskClick}>
              <MetaMaskLogo />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <div>
        <Typography display="inline" variant="h2">
          {amount}{' '}
        </Typography>
        <Typography display="inline" variant="h3">
          {tokenSymbol}
        </Typography>
      </div>
      <Typography noWrap variant="monospace">
        {walletAddress}
      </Typography>
    </CryptoBalanceCardFrame>
  );
};
