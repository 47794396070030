import { CURRUNCY_TYPE } from "../../constants";
import { AppActionTypes, AppState } from "./type";

const initialState: AppState = {
    /**
    * @type {CURRUNCY_TYPE} 
    * */
    selectedCurruncy: CURRUNCY_TYPE.POLYGON_MAINNET_USDC,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action: { type: AppActionTypes, value: string }) => {
    switch (action.type) {
        case AppActionTypes.UPDATE_SELECTED_CURRUNCY:
            return {
                ...state,
                selectedCurruncy: action.value as CURRUNCY_TYPE
            }
        default:
            return state
    }
}

export default reducer;