import count from 'assets/how/count.png';

interface CountProps {
  value: number;
}

const Count = ({ value }: CountProps) => {
  return (
    <div className="bg- relative">
      <img className="h-auto max-w-8" src={count} alt='bgLogo' />
      <div className="absolute top-2.5 left-3">
        <h2 className="text-white font-bold text-2xl"> {value} </h2>
      </div>
    </div>
  );
};

export default Count;
