import { FC, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import { InternalLink } from 'components/InternalLink';
import { useContentContext } from 'providers/ContentProvider';
import imgOurProjectHeaderBG from 'assets/our-project-header-bg.png';
import imgOurProjectHeaderPlaceholder from 'assets/our-project-header-placeholder.png';
import imgOurProjectHeaderTextPlaceholder from 'assets/our-project-header-text-placeholder.png';

import { routes } from './routes';
import { Helmet } from 'react-helmet';

export const ProjectList: FC = () => {
  const { projects } = useContentContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <Helmet>
        <title>CRED | Our Projects</title>
      </Helmet>
      <div className='relative z-0'>
        <img className='w-full h-[350px] sm:h-[500px] object-cover' src={imgOurProjectHeaderBG} alt='OurProjectHeaderBG' />
        <div className='absolute top-0 z-10'>
          <img className='h-[350px] sm:h-[500px]' src={imgOurProjectHeaderPlaceholder} alt="OurProjectHeaderPlaceholder" />
          <div className='absolute z-20 top-4'>
            <img className='h-[350px] sm:h-[500px]' src={imgOurProjectHeaderTextPlaceholder} alt="OurProjectHeaderTextPlaceholder" />
          </div>
        </div>
        <div className='absolute z-30 px-16 space-y-2 font-bold text-white top-20 sm:top-40'>
          <p className='text-4xl shadow-2xl md:text-6xl lg:text-7xl font-overpass'>Our <br /> Projects</p>
          <p className='py-4 text-2xl sm:py-8 md:text-3xl xl:text-4xl font-overpass'>Support our projects to <br /> fight
            <span className='text-[#23FFF1]'> climate change</span> </p>
        </div>
      </div>
      {projects?.length > 0
        ?
        <div className='flex justify-center'>
          <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 my-12 min-w-[60rem] 2xl:min-w-[90rem]">
            {projects?.map((project) => (
              <div key={project.id} className='flex justify-center py-8'>
                <InternalLink key={project.id} to={routes.projectDetail(project.id)}>
                  <div className='
                          w-96 
                          bg-white 
                          h-[310px] 
                          rounded-2xl 
                          hover:border 
                          hover:border-2 
                          hover:border-[#CDFFF0]
                          hover:shadow-xl
                          sm:scale-100
                          sm:ease-in-out
                          sm:duration-300
                          sm:hover:scale-105
                          hover:shadow-xl
                          '>
                    <img className='object-cover w-full h-52 rounded-t-2xl' src={project.images[0]} alt="Nft" />
                    <div className='pt-2 space-x-3 space-y-1'>
                      <p className='pl-3 font-medium text-black font-overpass'>{project.content.project_location}</p>
                      <p className='font-medium text-black font-overpass'>{project.title}</p>
                      <p className='text-[#00C6B9] font-bold font-overpass'>USD&nbsp;$
                        {project.pricePerTonne?.usdc ? project.pricePerTonne?.usdc : '---'}{' '}
                        per&nbsp;tonne</p>
                    </div>
                  </div>
                </InternalLink>
              </div>
            ))}
          </div>
        </div>
        :
        <div className='py-10' style={{ textAlign: 'center' }}>
          <BeatLoader color="rgb(19, 132, 148)" />
        </div>
      }
    </div>
  );
};
