import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { CheckRounded } from '@mui/icons-material';
import { Avatar, styled, Typography, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import { TransactionReceipt } from 'web3-core';
import { InternalLink } from 'components/InternalLink';
import { Main } from 'components/layouts/main';
import { useQueryParams } from 'hooks/useQueryParams';
import { routes } from './routes';

const CenteredMain = styled(Main)`
  display: grid;
  justify-items: center;
  align-content: center;

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)} !important;
  }
`;

const ReceiptView = styled('section')`
  max-width: ${({ theme }) => theme.breakpoints.values.sm}px;

  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

const ReceiptItem = styled('div')`
  display: grid;
  grid-template-columns: 200px minmax(0, 1fr);
  gap: ${({ theme }) => theme.spacing(0, 2)};
`;

type Receipt = Omit<TransactionReceipt, 'logs' | 'logsBloom' | 'events'>;

export const TransactionSuccess: FC = () => {
  const partialTransactionReceipt = useQueryParams<Partial<Receipt>>();

  return (
    <CenteredMain>
      <Helmet>
        <title>CRED | Transaction Success</title>
      </Helmet>
      <Avatar sx={{ bgcolor: green[500] }} variant="rounded">
        <CheckRounded />
      </Avatar>
      <Typography variant="h1">Transaction Successful</Typography>
      <ReceiptView>
        {Object.entries(partialTransactionReceipt).map(([key, value]) => (
          <ReceiptItem key={key}>
            <Typography noWrap variant="body1">
              {key}
            </Typography>
            <Typography noWrap align="right" variant="body1">
              {value}
            </Typography>
          </ReceiptItem>
        ))}
      </ReceiptView>
      <InternalLink to={`${routes.profile('supported-projects')}`}>
        <Button
          sx={{
            maxWidth: (theme) => theme.breakpoints.values.sm,
            mt: 2,
          }}
          color="ocean"
          variant="contained"
        >
          Check Your Projects
        </Button>
      </InternalLink>
    </CenteredMain>
  );
};
