import { styled } from '@mui/material';


export const ProjectListFrame = styled('section')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  padding-top: 30px;
  gap: ${({ theme }) => theme.spacing(1, 1)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${({ theme }) => theme.spacing(3, 3)};
  }
`;
