export const withMinimumExecutionTime = async <R>(
  promise: Promise<R>,
  minimumExecutionTime: number,
): Promise<R> => {
  const timeoutPromise = new Promise<undefined>((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, minimumExecutionTime);
  });

  const value = await Promise.all([promise, timeoutPromise]);

  return value[0];
};
