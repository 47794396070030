import { createContext, FC, useMemo, PropsWithChildren } from 'react';
import {
  DirectListing,
  MarketplaceFilter,
  ThirdwebSDK
} from '@thirdweb-dev/sdk';
import { useSafeContext } from 'hooks/useSafeContext';
import { Listing } from 'typings/listings';
import { switchMarketplaceAddress } from 'utils/environment';
import { NETWORKS } from '../config/coreData';
import { useEthereumContext } from './EthereumProvider';

interface TListingContextValue {
  marketplace: any;
  getAllListings: (contract: string, tokenId?: string) => Promise<Listing[]>;
  getAllActiveListings: (
    contract: string,
    tokenId?: string,
  ) => Promise<Listing[]>;
}
const TListingContext = createContext<TListingContextValue | null | undefined>(
  null,
);
TListingContext.displayName = 'TListingContext';

export const useTListingContext = () => useSafeContext(TListingContext);

export const TListingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { provider, chainId, credAddress } = useEthereumContext();

  const marketplace = useMemo(() => {
    if (!(chainId && provider)) return;
    let signer = window.ethereum ? provider.getSigner() : undefined;
    if (!signer) {
      signer = provider.getSigner(NETWORKS[42220].REGISTRY_ADDRESS);
    }
    const chainIdNumber = parseInt(chainId.toString());
    const options = {clientId: "a23ca39b6a24615c9f76dcb0e9c1c0e3"}
    const sdk = ThirdwebSDK.fromSigner(signer, chainId, options);
    return sdk.getContract(
      switchMarketplaceAddress(chainIdNumber),
      'marketplace',
    );
  }, [provider, chainId]);

  const orderValue = useMemo<TListingContextValue>(() => {
    const getAllActiveListings = async (
      contract: string,
      tokenId?: string,
    ): Promise<Listing[]> => {
      const filters: MarketplaceFilter = {
        tokenContract: contract,
        tokenId,
      };
      try {
        const marketplacex = await marketplace;
        const listings = await marketplacex?.getActiveListings(filters);
        return listings as DirectListing[];
      } catch (error) {
        return [];
      }
    };

    const getAllListings = async (
      contract: string,
      tokenId?: string,
    ): Promise<Listing[]> => {
      const filters: MarketplaceFilter = {
        tokenContract: contract,
        tokenId,
      };
      const marketplacex = await marketplace;
      const listings = await marketplacex?.getAllListings(filters);

      return listings as DirectListing[];
    };

    return {
      marketplace,
      getAllListings,
      getAllActiveListings,
    };
  }, [marketplace, credAddress, chainId]);

  return (
    <TListingContext.Provider value={orderValue}>
      {children}
    </TListingContext.Provider>
  );
};
