import { FC } from 'react';
import { InternalLink } from 'components/InternalLink';
import { routes } from 'pages/routes';
import imgHomeWhatBG from 'assets/home/target-collection/bg-what.png';
import imgHomeHeadText from 'assets/home/target-collection/header-what.png';

function comingSoon() {
  alert('Coming real soon!');
}

export const TargetAudienceCollection: FC = () => {
  return (
    <div className='z-0 relative'>
      <img className='w-full h-[1850px] lg:h-[1300px] xl:h-[750px] object-cover' src={imgHomeWhatBG} alt="BGWHAT" />
      <div className='absolute top-6 z-10'>
        <img className='pt-5' src={imgHomeHeadText} alt="Head" />
        <div className='flex justify-center'>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 my-12 absolute top-20 gap-12">

            <div className='flex justify-center'>
              <div className='
              w-96
              h-[500px]
              hover:p-1
              rounded-xl
              bg-[#CCCCCC]
              sm:scale-100
              sm:ease-in-out
              sm:duration-300
              sm:hover:scale-105
              '
                style={{
                  clipPath: 'polygon(0 0,100% 0,100% 85%,calc(100% - 20%) 100%,0 100%)',
                }}
              >
                <div className='h-full rounded-xl bg-[#00361F] hover:shadow-[inset_0px_0px_35px_-12px_#00DD9B]'
                  style={{
                    clipPath: 'polygon(0 0,100% 0,100% 85%,calc(100% - 20%) 100%,0 100%)'
                  }}
                >
                  <div className='p-7 h-full'>
                    <div className='flex justify-between flex-col h-full'>
                      <div className='space-y-6 font-overpass'>
                        <p className='text-2xl font-bold text-[#4FF037]'>For Web3 Builders</p>
                        <p className="text-xl text-[#4FF037]">Your carbon offset smart contract API is here!</p>
                        <p className="text-white">Embed the CRED Protocol into dApps, games, & NFT projects.</p>
                        <p className="text-white">Easily create projects that protect the climate by granting NFTs into the CRED NFT Vault.</p>
                      </div>
                      <InternalLink to={routes.projects}>
                        <button className='bg-[#00DD9B] w-4/5	h-[60px] rounded-full text-white'>EMBED CRED PROTOCOL NOW</button>
                      </InternalLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex justify-center'>
              <div className='
              w-96
              h-[500px]
              hover:p-1
              rounded-xl
              bg-[#CCCCCC]
              sm:scale-100
              sm:ease-in-out
              sm:duration-300
              sm:hover:scale-105 
              '
                style={{
                  clipPath: 'polygon(0 0,100% 0,100% 85%,calc(100% - 20%) 100%,0 100%)'
                }}
              >
                <div className='h-full rounded-xl bg-[#00361F] hover:shadow-[inset_0px_0px_35px_-12px_#00DD9B]'
                  style={{
                    clipPath: 'polygon(0 0,100% 0,100% 85%,calc(100% - 20%) 100%,0 100%)'
                  }}
                >
                  <div className='p-7 h-full'>
                    <div className='flex justify-between flex-col h-full'>
                      <div className='space-y-6 font-overpass'>
                        <div>
                          <p className='text-2xl font-bold text-[#4FF037]'>For Carbon Project Creators</p>
                          <span className='text-xl font-bold text-[#4FF037]'>Buy Carbon Credits</span>
                        </div>
                        <p className="text-white">For Web3 companies and crypto natives who want to neutralize your carbon footprint!</p>
                        <p className="text-white">Choose a project and how many tons of carbon you want to neutralize, then pay with:</p>
                        <p className="text-white">Crypto: accepting WETH on Polygon — stablecoin coming soon!</p>
                        <p className="text-white">Fiat: via Stripe — coming soon!</p>
                      </div>
                      <button className='bg-[#00DD9B] w-4/5	h-[60px] rounded-full text-white' onClick={comingSoon}>BUY CARBON CREDITS NOW</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex justify-center'>
              <div className='
              w-96  
              h-[500px] 
              hover:p-1 
              rounded-xl
              bg-[#CCCCCC]
              sm:scale-100
              sm:ease-in-out
              sm:duration-300
              sm:hover:scale-105 
              '
                style={{
                  clipPath: 'polygon(0 0,100% 0,100% 85%,calc(100% - 20%) 100%,0 100%)'
                }}
              >
                <div className='h-full rounded-xl bg-[#00361F] hover:shadow-[inset_0px_0px_35px_-12px_#00DD9B]'
                  style={{
                    clipPath: 'polygon(0 0,100% 0,100% 85%,calc(100% - 20%) 100%,0 100%)'
                  }}
                >
                  <div className='p-7 h-full'>
                    <div className='flex justify-between flex-col h-full'>
                      <div className='space-y-6 font-overpass'>
                        <p className='text-2xl font-bold text-[#4FF037]'>For Carbon Project Creators</p>
                        <p className="text-xl text-[#4FF037]">Register Credits to join the CRED community</p>
                        <p className="text-white">Register your project info.</p>
                        <p className="text-white">Transfer credits to our account as escrow, or settle offline based on inventory reserve contract.</p>
                      </div>
                      <button className='bg-[#00DD9B] w-4/5	h-[60px] rounded-full text-white' onClick={comingSoon}>REGISTER TO CREDIT NOW</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};
