/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createContext, FC, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { Contract, ContractOptions } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';


import { useSafeContext } from 'hooks/useSafeContext';
import { NETWORKS } from '../config/coreData';
import { SupportedChainIdsV4 } from '@traderxyz/nft-swap-sdk';
import { getContract } from 'utils/contracts';
import { ContractFn } from 'typings/contracts';
import { CHAIN_LABELS, CHAIN_LOGOS, CRED_ABI, CRED_REGISTRY_ABI, IERC1155, IERC20, IERC721 } from 'utils/constants';
import { useSupported } from 'hooks/useSupported';
import { NetworkDeploymentType } from 'utils/environment';


// const ALCHEMY_URL = String(process.env.REACT_APP_ALCHEMY_WSS_URL);
const SUPPORTED_CHAIN_IDS = [
  SupportedChainIdsV4.Polygon,
  SupportedChainIdsV4.PolygonMumbai,
  SupportedChainIdsV4.Celo,
]
const SUPPORTED_NETWORK_NAME = [
  'Polygon mainnet',
  'Mumbai testnet',
  'Celo mainnet',
];

console.log(
  `Running on network:
  ${String(SUPPORTED_NETWORK_NAME)},
  network ID: ${String(SUPPORTED_CHAIN_IDS)}`
);

interface EthereumContextValue {
  isReady: boolean;
  isConnected: boolean;
  isEthereumSupported: boolean;
  provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider;
  network?: ethers.providers.Network;
  accountAddress?: string,
  chainId: SupportedChainIdsV4;
  credAddress: string;
  credRegistryAddress: string;
  chains: Array<{ label: string | undefined; value: number, logo: string | undefined }>,
  // setWeb3: () => void;
  getWeb3: () => Web3;
  getAlchemyCredContract: () => Contract;
  // setAlchemyCredContract: () => void;
  resetNetwork: () => Promise<void>;
  // getMetaMaskCredContract: () => Contract;
  getAlchemyCredRegistryContract: () => Contract;
  // setAlchemyCredRegistryContract: () => void;
  // getMetaMaskCredRegistryContract: () => Contract;
  getERC721Contract: (address: string, options?: ContractOptions) => Contract;
  getERC1155Contract: (address: string, options?: ContractOptions) => Contract;
  getERC20Contract: (address: string, options?: ContractOptions) => Contract;
  connectWithMetaMask: () => Promise<boolean>;
  watchCredInMetaMask: () => Promise<void>;
  switchChain: (chainId: SupportedChainIdsV4) => Promise<void>;
}

const EthereumContext = createContext<EthereumContextValue | undefined>(
  undefined,
);
EthereumContext.displayName = 'EthereumContext';

export const useEthereumContext = () => useSafeContext(EthereumContext);


export const EthereumProvider: FC<any> = ({ children }) => {
  const providerRef = useRef(window.ethereum);
  const web3Ref = useRef(window.ethereum ? new Web3(window.ethereum) : new Web3(process.env.REACT_APP_ALCHEMY_WSS_URL));
  const provider = useMemo(() => window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : new ethers.providers.JsonRpcProvider("https://polygon-mainnet.g.alchemy.com/v2/hSCC5smHZujBsT3i9DTzYamdCO1YI3Xe"), []);
  const [networkType] = useState<NetworkDeploymentType>(process.env.REACT_APP_NETWORK_TYPE as NetworkDeploymentType);
  const { isChainIdSupported, getChainAddParamas, getSupportedChainIdMap } = useSupported()
  const [chains] = useState(() => {
    const chainMap = getSupportedChainIdMap(networkType)
    const chainIds = Object.values(chainMap).map((key) => key)
    return chainIds.map((chainId) => ({
      value: chainId,
      label: CHAIN_LABELS[chainId],
      logo: CHAIN_LOGOS[chainId]
    }))
  })

  const [isReady, setIsReady] = useState(false);
  const [network, setNetwork] = useState<ethers.providers.Network>()
  const [chainId, setChainId] = useState<number>(SupportedChainIdsV4.Polygon);
  const [accountAddress, setAccountAddress] = useState<string | undefined>(undefined)

  const [credAddress, setCredAddress] = useState(NETWORKS[137].CRED_ADDRESS);
  const credContractRef = useRef(getContract(web3Ref.current, CRED_ABI, NETWORKS[137].CRED_ADDRESS))

  const [credRegistryAddress, setCredRegistryAddress] = useState(NETWORKS[137].REGISTRY_ADDRESS);
  const credRegistryContractRef = useRef(getContract(web3Ref.current, CRED_REGISTRY_ABI, NETWORKS[137].REGISTRY_ADDRESS))

  const getERC20Contract = useCallback<ContractFn>((address, options = {}) => {
    return getContract(web3Ref.current, IERC20.abi as AbiItem[], address, options)
  }, [])

  const getERC721Contract = useCallback<ContractFn>((address, options = {}) => {
    return getContract(web3Ref.current, IERC721.abi as AbiItem[], address, options)
  }, [])

  const getERC1155Contract = useCallback<ContractFn>((address, options = {}) => {
    return getContract(web3Ref.current, IERC1155.abi as AbiItem[], address, options)
  }, [])

  const getAccountAddress = useCallback(async () => {
    const currentProvider = providerRef.current;
    const accounts: string[] = await currentProvider.request({
      method: 'eth_accounts',
    });
    return accounts[0];
  }, []);

  const connectWithMetaMask = useCallback(async () => {
    if (!providerRef.current) {
      alert('No web3 wallet such as MetaMask is available.');
      return false;
    }

    if (chainId && !SUPPORTED_CHAIN_IDS.includes(chainId)) {
      alert(
        `The selected ethereum network is not supported. Please switch to ${String(
          SUPPORTED_NETWORK_NAME,
        )}.`,
      );
      return false;
    }

    await providerRef.current.request({
      method: 'eth_requestAccounts',
    });

    const currentAccountAddress = await getAccountAddress();
    setAccountAddress(currentAccountAddress)

    return providerRef.current.isConnected();
  }, [getAccountAddress, chainId]);


  const isConnected = useMemo(() => Boolean(accountAddress), [accountAddress]);

  const watchCredInMetaMask = useCallback(async () => {
    const currentProvider = providerRef.current;
    if (!currentProvider) {
      return;
    }

    const watchCREDParams = {
      type: 'ERC20', // Initially only supports ERC20, but eventually more!
      options: {
        address: credAddress, // The address that the token is at.
        symbol: 'CRED', // A ticker symbol or shorthand, up to 5 chars.
        decimals: 18, // The number of decimals in the token
      },
    }

    await currentProvider.request({
      method: 'wallet_watchAsset',
      params: watchCREDParams,
    });
  }, [credAddress]);

  const updateContracts = useCallback(() => {
    if (chainId && SUPPORTED_CHAIN_IDS.includes(chainId)) {
      const chainIdText: unknown = (`${chainId}`);
      const networkPayload = NETWORKS[chainIdText as keyof typeof NETWORKS]
      setCredAddress(() => networkPayload.CRED_ADDRESS);
      setCredRegistryAddress(() => networkPayload.REGISTRY_ADDRESS);
    }
  }, [chainId])

  const switchChain = useCallback(async (_chainId: SupportedChainIdsV4) => {
    const params = getChainAddParamas(_chainId)
    console.log(params)
    await providerRef.current.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: Web3.utils.toHex(_chainId) }]
    });
  }, [getChainAddParamas])

  const checkForValidChain = useCallback(async (_chainId: number) => {
    const supportedChainIdMap = getSupportedChainIdMap(networkType)
    const [defaultChainId] = Object.values(supportedChainIdMap)
    if (!defaultChainId) return
    try {
      await switchChain(defaultChainId)
    } catch (err: unknown) {
      if ((err as any)?.code === 4902) {
        await await providerRef.current.request({
          method: 'wallet_addEthereumChain',
          params: [getChainAddParamas(_chainId)]
        });
        await checkForValidChain(defaultChainId)
      }
    }
  }, [getSupportedChainIdMap, switchChain, getChainAddParamas, networkType])

  const resetNetwork = useCallback(async () => {
    updateContracts()
    web3Ref.current = window.ethereum ? new Web3(window.ethereum) : new Web3(process.env.REACT_APP_ALCHEMY_WSS_URL);
  }, [updateContracts]);

  useEffect(() => {
    if (credAddress && credRegistryAddress) {
      credContractRef.current = getContract(web3Ref.current, CRED_ABI, credAddress)
      credRegistryContractRef.current = getContract(web3Ref.current, CRED_REGISTRY_ABI, credRegistryAddress)
    }
  }, [credAddress, credRegistryAddress])

  useEffect(() => {
    resetNetwork().catch(console.error);
  }, [resetNetwork]);

  useEffect(() => {
    const updateNetwork = async () => {
      if (!provider) return;
      const providerNetwork = await provider.getNetwork();
      setNetwork(() => providerNetwork)
    }

    updateNetwork().catch(console.error)
  }, [provider])

  useEffect(() => {
    const updateChainId = async (_chainId: number) => {
      const chainIdIsSupported = isChainIdSupported(_chainId)
      if (chainIdIsSupported) setChainId(() => _chainId)
      else await checkForValidChain(_chainId)
    }

    if (!network) return;
    void updateChainId(network.chainId);
  }, [network, isChainIdSupported, checkForValidChain])

  useEffect(() => {
    const checkDesiredChainId = async () => {
      const clientChainId = await web3Ref.current.eth.getChainId();
      const isDesiredChaindId = clientChainId === chainId;
      if (isDesiredChaindId) setIsReady(true)
    }

    void checkDesiredChainId()
  }, [chainId])

  const contextValue = useMemo<EthereumContextValue>(
    () => ({
      isReady,
      isEthereumSupported: Boolean(providerRef.current),
      getAlchemyCredContract: () => credContractRef.current,
      getAlchemyCredRegistryContract: () => credRegistryContractRef.current,
      resetNetwork,
      getERC20Contract,
      getERC721Contract,
      getERC1155Contract,
      isConnected,
      connectWithMetaMask,
      watchCredInMetaMask,
      provider,
      network,
      chainId,
      accountAddress,
      credAddress,
      credRegistryAddress,
      getWeb3: () => web3Ref.current,
      switchChain,
      chains
    }),
    [
      connectWithMetaMask,
      getERC20Contract,
      getERC721Contract,
      getERC1155Contract,
      watchCredInMetaMask,
      isConnected,
      resetNetwork,
      chainId,
      provider,
      network,
      accountAddress,
      credAddress,
      credRegistryAddress,
      isReady,
      switchChain,
      chains
    ],
  );

  return (
    <EthereumContext.Provider value={contextValue}>
      {children}
    </EthereumContext.Provider>
  );
};
