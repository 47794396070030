import { FC } from 'react';
import Access from 'assets/how/access.png';
import Cred from 'assets/how/cred.png';
import Impact from 'assets/how/impact.png';
import Personalize from 'assets/how/personalize.png';
import { routes } from 'pages/routes';
import Count from './Count';
import PickProject from 'assets/how/pick-project.png'
import rightUnion from 'assets/how/rightUnion.png'
import neutrality from 'assets/how/neutrality.png'
import arrowDown from 'assets/how/arrowDown.png'
import DottedLines from './DottedLines_tailwind'
import CardTailwind from './CardTailwind' 
import WhatYouGet from './WhatYouGet';


const HIWComponenttailwind: FC = () => {
  return (
    <div>
   
      <div className="mt-10">
        <div className="flex justify-between min-w-[880px] m-6 mb-48">
          <CardTailwind count={1} text={'Pick a proeject you\'d like to supprt'} buttonText={'Pick project'} ></CardTailwind>
          <div className='flex items-center justify-center '>
            <img src={rightUnion} alt="rightArrow" />
          </div>
          <CardTailwind count={2} text={'Connect a wallet'} buttonText={'Connect Wallet'}></CardTailwind>
          <div className='flex items-center justify-center'>
            <img src={rightUnion} alt="rightArrow" />
          </div>
          <CardTailwind count={3} text={'Purchase carbon credit'} ></CardTailwind>

        </div>
        
        {/* <div className="flex justify-end mr-10">
          <div className='flex items-center justify-center rotate-90'>
            <img src={rightUnion} alt="rightArrow" />
          </div>
        </div> */}

        <div className="flex justify-between min-w-[880px] m-6">
          <div className='flex items-center justify-center basis-[434px] relative'>
            <img className='absolute top-1/3' src={arrowDown} alt="downArrow" />
          </div>
          <CardTailwind count={5} text={' Redeem CRED for exclusive NFT projects'} buttonText={'Browse nfts'}></CardTailwind>
          <div className='flex items-center justify-center rotate-180'>
            <img src={rightUnion} alt="rightArrow" />
          </div>
          <CardTailwind count={4} text={'Receive CRED as a reward for every dollar spent on carbon credits'}></CardTailwind>
        </div>
        <WhatYouGet/>
        
          <div className='flex justify-center gap-[20px] w-full py-[20px]'>
            <a href="//app.carbonbase.co/en/calculator" target="_blank">
              <button className='text-white text-lg font-semibold py-[10px] px-[40px] bg-[#00DD9B] rounded-[20px]  transition-all ease-out duration-700 hover:bg-[#85ede6]'>
                CALCULATE CARBON FOOTPRINT
              </button>
            </a>
            <a href={routes.projects}>
              <button className='text-white text-lg font-semibold py-[10px] px-[40px] bg-[#C206A4] rounded-[20px]  transition-all ease-out duration-700 hover:bg-[#85ede6]'>
                EARN CREDIT NOW
              </button>
            </a>
          </div>
      </div>

    </div>
  );
};

export default HIWComponenttailwind;
